import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
	selector: 'ft-dynamic-form',
	templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit, OnChanges {
	@Input()
	config: FieldConfig[] = [];

	@Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();

	form: FormGroup;

	constructor(private fb: FormBuilder) {}

	get controls() {
		return this.config.filter(({ type }) => type !== 'BUTTON');
	}

	get changes() {
		return this.form.valueChanges;
	}

	get valid() {
		return this.form.valid;
	}

	get value() {
		return this.form.value;
	}

	ngOnInit() {
		this.form = this.createGroup();
		if (this.form)
			this.changes.subscribe(next => {
				this.submitEvent.emit(this.value);
			});
	}

	ngOnChanges() {
		if (this.form) {
			const controls = Object.keys(this.form.controls);
			const configControls = this.controls.map(item => item.name);

			controls
				.filter(control => !configControls.includes(control))
				.forEach(control => this.form.removeControl(control));

			configControls
				.filter(control => !controls.includes(control))
				.forEach(name => {
					const config = this.config.find(
						control => control.name === name
					);
					this.form.addControl(name, this.createControl(config));
				});
		}
	}

	createGroup() {
		const group = this.fb.group({});
		this.controls.forEach(control =>
			group.addControl(control.name, this.createControl(control))
		);
		return group;
	}

	createControl(config: FieldConfig) {
		const { disabled, validation, value } = config;
		return this.fb.control({ disabled, value }, validation);
	}

	handleSubmit(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		this.submitEvent.emit(this.value);
	}
}
