import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../shared.service';

@Component({
	selector: 'ft-select-dialog',
	template: `
		<mat-toolbar
			class="fx-layout-row fx-content-space-between fx-items-center fx-gap-4">
			<mat-icon fontIcon="mdi-menu" fontSet="mdi"></mat-icon>
			<span>{{ 'SELECT' | translate }}</span>
			<span class="fx-grow-1"></span>
			<button mat-icon-button>
				<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
			</button>
		</mat-toolbar>
		<div matDialogContent>
			<h4>{{ 'SELECT_ITEM_TO_KEEP' | translate }}</h4>
			<mat-divider></mat-divider>
			@if (data.items) {
				<div [ngStyle]="{ border: '2px solid #f3f3f3' }">
					@if (data.entity === 'patient') {
						<div class="ft-row ft-header">
							<div class="ft-cell">{{ 'ID' | translate }}</div>
							<div class="ft-cell">{{ 'TITLE' | translate }}</div>
							<div class="ft-cell">
								{{ 'FULL_NAME' | translate }}
							</div>
							<div class="ft-cell">{{ 'DOB' | translate }}</div>
							<div class="ft-cell">
								{{ 'GENDER' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'CREATED_DATE' | translate }}
							</div>
						</div>
						@for (item of data.items; track item) {
							<div
								class="ft-row"
								(click)="selectedItem = item"
								[class.selected]="selectedItem === item">
								<div class="ft-cell">
									{{ item['externalPatientID'] }}
								</div>
								<div class="ft-cell">{{ item['titleId'] }}</div>
								<div class="ft-cell">
									{{ item['lastName'] }}
									{{ item['firstName'] }}
								</div>
								<div class="ft-cell">
									{{ item['dateOfBirth'] }}
								</div>
								<div class="ft-cell">
									{{ genders[item['genderId']] }}
								</div>
								<div class="ft-cell">
									{{
										item['createdDate'] | date: 'dd/MM/yyyy'
									}}
								</div>
							</div>
						}
					}

					@if (data.entity === 'physician') {
						<div class="ft-row ft-header">
							<div class="ft-cell">{{ 'ID' | translate }}</div>
							<div class="ft-cell">
								{{ 'FULL_NAME' | translate }}
							</div>
							<div class="ft-cell">{{ 'EMAIL' | translate }}</div>
							<div class="ft-cell">{{ 'PHONE' | translate }}</div>
							<div class="ft-cell">
								{{ 'CODE_PRO' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'ADDRESS' | translate }}
							</div>
						</div>
						@for (item of data.items; track item) {
							<div
								class="ft-row"
								(click)="selectedItem = item"
								[class.selected]="selectedItem === item">
								<div class="ft-cell">{{ item['id'] }}</div>
								<div class="ft-cell">
									{{ item['fullName'] }}
								</div>
								<div class="ft-cell">{{ item['email'] }}</div>
								<div class="ft-cell">{{ item['phone'] }}</div>
								<div class="ft-cell">
									{{ item['codeAnam'] }}
								</div>
								<div class="ft-cell">{{ item['address'] }}</div>
							</div>
						}
					}
				</div>
			}
		</div>
		<div matDialogActions align="end">
			<button mat-raised-button color="warn" [matDialogClose]="null">
				{{ 'CLOSE' | translate }}
			</button>
			<button
				mat-raised-button
				color="primary"
				(click)="confirmAndClose()">
				{{ 'CONFIRM' | translate }}
			</button>
		</div>
	`,
	styles: [
		`
			h4 {
				margin: 0 0 12px 0;
			}

			.ft-row {
				display: grid;
				grid-template-columns: 2fr 2fr repeat(4, 100px);
				max-height: 32px;
				height: 32px;
				line-height: 32px;
				box-sizing: border-box;
				overflow: hidden;
				color: #333333;
				font-size: 12px;
				padding: 0 14px;
				border-bottom: 1px solid #f5f5f5;
				transition: all 0.3s ease-in-out;
			}

			.ft-row:not(.ft-header):hover {
				cursor: pointer;
				background: rgba(10, 102, 111, 0.55);
			}

			.ft-row.selected {
				background: #0a666f;
				color: white;
			}

			.ft-cell {
				outline: none;
			}

			.ft-header {
				color: #444444;
				font-size: 12px;
				border-bottom: 2px solid darkcyan;
			}
		`,
	],
})
export class SelectDialogComponent implements OnInit {
	public selectedItem!: any;
	public data = inject(MAT_DIALOG_DATA);
	public genders: any = {};
	private _dialogRef: MatDialogRef<any> = inject(
		MatDialogRef<SelectDialogComponent>
	);
	private _service: SharedService = inject(SharedService);

	confirmAndClose() {
		this._dialogRef.close(this.selectedItem);
	}

	ngOnInit() {
		if (this.data.entity === 'patient')
			this._service.getGenders().subscribe(data => {
				data.forEach((it: any) => (this.genders[it.id] = it.value));
			});
	}
}
