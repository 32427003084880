import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import {
	AppointmentDetailsDTO,
	AppointmentDTO,
	Availability,
	AVSC,
	CalendarEvent,
	EventDetail,
	ExamDetailDTO,
	FireOMI,
	PatientDTO,
	PatientExamDTO,
	PatientFullDTO,
	Payment,
	PaymentDTO,
	PaymentItemDTO,
	PaymentOrder,
	ThermalPrintModel,
	VisitDTO,
	WorkflowItem,
} from '../model';
import { SortDirection } from '@angular/material/sort';
import {
	APPOINTMENT_URL,
	DOCUMENT_URL,
	EXAMS_URL,
	PATIENT_URL,
	PRINTER_URL,
	REPORTING_URL,
	SCHEDULER_URL,
	VISIT_URL,
	WORKFLOW_URL,
} from '../urls';
import { tap } from 'rxjs/operators';
import { printBlob } from '../utils';

@Injectable()
export class SchedulerService {
	private broadcastSource = new Subject<any>();
	broadcast = this.broadcastSource.asObservable();
	scanIdCard: Subject<boolean> = new Subject<boolean>();
	beidCardData: Subject<any> = new Subject<any>();

	private _http = inject(HttpClient);

	createNewExam(examDetailDTO: ExamDetailDTO): Observable<any> {
		return this._http.post(`${EXAMS_URL}/create`, examDetailDTO);
	}

	updateExam(examDetailDTO: ExamDetailDTO): Observable<any> {
		return this._http.put(`${EXAMS_URL}/update`, examDetailDTO);
	}

	getPaymentDetails(paymentID: any): Observable<PaymentDTO> {
		return this._http.get<PaymentDTO>(`${EXAMS_URL}/payment-details`, {
			params: { paymentID },
		});
	}

	exitEditing(taskId: number): Observable<any> {
		return this._http.get(`${SCHEDULER_URL}/editing-exit/${taskId}`);
	}

	startEditing(taskId: number): Observable<any> {
		return this._http.get(`${SCHEDULER_URL}/editing-start/${taskId}`);
	}

	getScheduledAppointments(
		res: string,
		calendar: string,
		start: string,
		end: string
	): Observable<CalendarEvent[]> {
		const params = { res, calendar, start, end };
		return this._http.get<CalendarEvent[]>(
			`${SCHEDULER_URL}/calendar-events`,
			{ params: params }
		);
	}

	getExternalAppointmentDTOs(
		pageSize: number,
		pageIndex: number,
		sort: any,
		direction: any,
		key: any
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${sort},${direction}`,
			key,
		};
		return this._http.get(`${APPOINTMENT_URL}/external`, {
			params: params,
		});
	}

	scheduleSPS = (sps: any): Observable<any> =>
		this._http.post(`${SCHEDULER_URL}/scheduleSPS`, sps);

	getPatientExams = (patientId: number): Observable<PatientExamDTO[]> =>
		this._http.get<PatientExamDTO[]>(
			`${PATIENT_URL}/patient-exams/${patientId}`
		);

	updateAppointmentStatus = (id: number, status: string): Observable<any> =>
		this._http.get(`${SCHEDULER_URL}/updateAppointmentStatus/${id}`, {
			params: { status },
		});

	updateAppointmentDTOStatus = (
		id: number,
		status: string
	): Observable<any> =>
		this._http.get(`${APPOINTMENT_URL}/update-status/${id}`, {
			params: { status },
		});

	updateAppointment = (calEvent: any): Observable<any> =>
		this._http.post(`${SCHEDULER_URL}/updateAppointment`, calEvent);

	deleteEvent = (spsId: number): Observable<any> =>
		this._http.get(`${SCHEDULER_URL}/deleteCalEvent/${spsId}`);

	markPatientAsArrived = (isr: any): Observable<any> =>
		this._http.post(`${SCHEDULER_URL}/patientArrived`, isr);

	startExamByAN = (accessionNumber: string): Observable<any> =>
		this._http.get(`${EXAMS_URL}/start-exam`, {
			params: new HttpParams().set('accessionNumber', accessionNumber),
		});

	finishExam = (accessionNumber: string): Observable<any> =>
		this._http.get(`${EXAMS_URL}/finish-exam`, {
			params: new HttpParams().set('accessionNumber', accessionNumber),
		});

	findSPSById = (spsId: number): Observable<any> =>
		this._http.get(`${SCHEDULER_URL}/spsById/${spsId}`);

	findAvailabilities = (avsc: AVSC): Observable<Availability> =>
		this._http.post<Availability>(
			`${SCHEDULER_URL}/findAvailabilities`,
			avsc
		);

	printRDV = (spsId: number): Observable<boolean> =>
		this._http.get<boolean>(`${PRINTER_URL}/print-rdv/${spsId}`);

	printRdvForResult(rdv: any): Observable<any> {
		return this._http
			.post(`${PRINTER_URL}/rdv-for-result`, rdv, {
				responseType: 'blob',
			})
			.pipe(tap(printBlob));
	}

	getSPSByISRID = (id: any): Observable<any> =>
		this._http.get<any>(`${SCHEDULER_URL}/sps-by-isr/${id}`);

	saveSPS = (value: any, isrId: number): Observable<any> =>
		this._http.post<any>(`${SCHEDULER_URL}/save-sps/${isrId}`, value);

	orderPayment = (order: PaymentOrder): Observable<any> =>
		this._http.post(`${SCHEDULER_URL}/orderPayment`, order);

	createPayment = (payment: PaymentDTO): Observable<PaymentDTO> =>
		this._http.post<PaymentDTO>(`${EXAMS_URL}/createPayment`, payment);

	getPaymentOrderDetails(
		accessionNumber: string
	): Observable<PaymentItemDTO[]> {
		return this._http.get<PaymentItemDTO[]>(`${EXAMS_URL}/payment-items`, {
			params: { accessionNumber },
		});
	}

	getISRByAN(accessionNumber: string): Observable<any> {
		const params = { accessionNumber };
		return this._http.get(`${SCHEDULER_URL}/isr-by-an`, { params });
	}

	exitPatient(accessionNumber: any): Observable<any> {
		const params = { accessionNumber };
		return this._http.get(`${EXAMS_URL}/patient-left`, { params });
	}

	updateReportingTaskStatus(id: number, status: string) {
		const params = new HttpParams()
			.set('reportingStatus', status)
			.set('id', String(id));
		return this._http.get(`${REPORTING_URL}/updateReportingStatus`, {
			params,
		});
	}

	printTicket(printable: ThermalPrintModel): Observable<any> {
		return this._http
			.post(`${PRINTER_URL}/printTicket`, printable, {
				responseType: 'blob',
			})
			.pipe(tap(printBlob));
	}

	printCupsTicket(printable: ThermalPrintModel): Observable<any> {
		return this._http.post(`${PRINTER_URL}/printCupsTicket`, printable);
	}

	printPaymentReceipt(payment: Payment): Observable<any> {
		return this._http
			.post(`${PRINTER_URL}/printPaymentReceipt`, payment, {
				responseType: 'blob',
			})
			.pipe(tap(printBlob));
	}

	printCupsPaymentReceipt(payment: Payment): Observable<any> {
		return this._http.post(
			`${PRINTER_URL}/printCupsPaymentReceipt`,
			payment
		);
	}

	updateOrder = (imagingOrder: any): Observable<any> =>
		this._http.post(`${SCHEDULER_URL}/updateOrder`, imagingOrder);

	cancelPayment(paymentID: string): Observable<any> {
		const params = { paymentID };
		return this._http.post(`${EXAMS_URL}/cancelPayment`, {}, { params });
	}

	deleteExams(accessionNumbers: any): Observable<boolean> {
		return this._http.delete<boolean>(`${EXAMS_URL}/delete-exams`, {
			params: { accessionNumbers },
		});
	}

	deleteFiles(patientID: string): Observable<any> {
		const params = { patientID };
		return this._http.get(`${DOCUMENT_URL}/deleteFiles`, { params });
	}

	createAppointmentDTO(appointment: AppointmentDetailsDTO): Observable<any> {
		return this._http.post<any>(`${APPOINTMENT_URL}/create`, appointment);
	}

	updateAppointmentDTO(appointment: AppointmentDetailsDTO): Observable<any> {
		return this._http.put<any>(`${APPOINTMENT_URL}/update`, appointment);
	}

	deleteAppointment(id: any): Observable<any> {
		return this._http.delete(`${APPOINTMENT_URL}/delete/${id}`);
	}

	getPatientAppointmentDTOs(id: any): Observable<AppointmentDTO[]> {
		return this._http.get<AppointmentDTO[]>(
			`${PATIENT_URL}/patient-appointments/${id}`
		);
	}

	generateEfactUrl(ans: string): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/efact`, { params: { ans } });
	}

	generateEHealthBoxUrl(accessionNumber: string): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/ehealthbox`, {
			params: { accessionNumber },
		});
	}

	getAppointmentsForResult(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		key: any
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${active},${direction}`,
			key,
		};
		return this._http.get(`${APPOINTMENT_URL}/appointments-for-result`, {
			params: params,
		});
	}

	moveEvents(eventIds: string, targetDate: string): Observable<boolean> {
		return this._http.post<boolean>(
			`${SCHEDULER_URL}/move-events`,
			undefined,
			{ params: { eventIds, targetDate } }
		);
	}

	cancelAppointmentDTO(id: any, reason: any): Observable<any> {
		return this._http.put(
			`${APPOINTMENT_URL}/cancel/${id}`,
			{},
			{ params: { reason } }
		);
	}

	restoreAppointment(id: number): Observable<any> {
		return this._http.get(`${APPOINTMENT_URL}/restore/${id}`);
	}

	getPatientDTOById = (patientId: number): Observable<PatientDTO> => {
		return this._http.get<PatientDTO>(`${PATIENT_URL}/${patientId}`);
	};

	getPatientFullDTOById(patientId: number): Observable<PatientFullDTO> {
		return this._http.get<PatientFullDTO>(
			`${PATIENT_URL}/${patientId}/full`
		);
	}

	getAppointmentDTOById(id: number): Observable<AppointmentDetailsDTO> {
		return this._http.get<AppointmentDetailsDTO>(
			`${APPOINTMENT_URL}/${id}`
		);
	}

	getExamDetailsByAccessionNumber(
		accessionNumber: string
	): Observable<ExamDetailDTO> {
		return this._http.get<ExamDetailDTO>(`${EXAMS_URL}/exam-details`, {
			params: { accessionNumber },
		});
	}

	getPatientVisits(patientId: number): Observable<VisitDTO[]> {
		return this._http.get<VisitDTO[]>(`${VISIT_URL}/patient-visits`, {
			params: { patientId },
		});
	}

	getPatientImagingOrders(externalPatientID: string): Observable<FireOMI[]> {
		return this._http.get<FireOMI[]>(`${VISIT_URL}/patient-orders`, {
			params: { externalPatientID },
		});
	}

	scheduleOrder(order: FireOMI): Observable<FireOMI> {
		return this._http.post(`${VISIT_URL}/schedule`, order);
	}

	changeProcedure(order: FireOMI): Observable<any> {
		return this._http.post(`${VISIT_URL}/change-procedure`, order);
	}
}
