@switch (menuType) {
	@case ('calendar') {
		<button
			[matTooltip]="'CALENDAR' | translate"
			mat-button
			[matMenuTriggerFor]="menu"
			style="color: white !important">
			{{ currentCalendar | translate }}
		</button>
	}
	@default {
		<button
			[matTooltip]="'FILTERBYRESOURCE' | translate"
			mat-icon-button
			[matMenuTriggerFor]="menu">
			<mat-icon
				fontSet="mdi"
				fontIcon="mdi-filter-menu"
				style="font-size: 1.1em !important"></mat-icon>
		</button>
	}
}

<mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="true">
	@for (item of listItem; track item) {
		<button mat-menu-item (click)="menuItemSelect.next(item.value)">
			<mat-icon [fontIcon]="item.icon" fontSet="mdi"></mat-icon>
			<span>{{ item.name | translate }}</span>
		</button>
	}
</mat-menu>
