<mat-toolbar class="dialog-toolbar">
	<mat-icon fontSet="mdi" fontIcon="mdi-cancel"></mat-icon>
	<h3 style="padding-left: 6px">{{ 'CANCELLATIONREASON' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button [matDialogClose]="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<mat-dialog-content id="form">
	<form>
		<mat-form-field class="full-width">
			<mat-label>{{ 'REASON' | translate }}</mat-label>
			<textarea
				matInput
				placeholder="{{ 'REASON' | translate }}"
				[formControl]="form"></textarea>
		</mat-form-field>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-raised-button color="warn" [matDialogClose]="null">
		{{ 'CANCEL' | translate }}
	</button>
	<button
		[disabled]="form.invalid"
		mat-raised-button
		color="primary"
		[matDialogClose]="form.value">
		<b>{{ 'CONFIRM' | translate }}</b>
	</button>
</mat-dialog-actions>
