import { Component, isDevMode, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { ReportingService } from '../../reporting/reporting.service';

declare let DocsAPI: any;

@Component({
	selector: 'ft-doc-reader',
	templateUrl: './doc-reader.component.html',
	styleUrls: ['./doc-reader.component.scss'],
})
export class DocReaderComponent implements OnInit {
	private editor: any;
	private readonly devHost: string = 'http://192.168.1.74:8000';

	constructor(
		private _route: ActivatedRoute,
		private _service: ReportingService
	) {}

	ngOnInit(): void {
		const user = JSON.parse(localStorage.getItem('user'));

		this._route.params
			.pipe(map((params: Params) => params['docId']))
			.subscribe(docId => {
				console.log(docId);

				this._service
					.getFileModel(
						'0',
						null,
						null,
						user?.id,
						user?.fullName,
						null,
						docId
					)
					.subscribe(this.openFile);
			});
	}

	openFile(config: any) {
		config.editorConfig.customization.toolbarNoTabs = false;
		config.editorConfig.customization.compactHeader = false;
		config.editorConfig.customization.autosave = false;
		config.editorConfig.customization.toolbarHideFileName = true;

		if (this.editor) this.editor.destroyEditor();

		const onError = event => {
			if (event) console.log(event.data);
		};

		const onInfo = data => {
			if (data && data.data && data.data.getConfig) {
				console.log('Getting config...');
				this.editor.serviceCommand('getConfig', config.document);
			}
		};

		config.events = {
			onError: onError,
			onInfo: onInfo,
		};

		config.mode = config.documentType === 'slide' ? 'view' : 'edit';
		config.editorConfig.mode = config.mode;

		config.editorConfig.customization.uiTheme =
			DocReaderComponent.uiTheme?.includes('dark')
				? 'default-dark'
				: 'default-light';

		if (isDevMode()) {
			config.document.url = config.document.url.replace(
				'localhost',
				this.devHost
			);
			config.editorConfig.callbackUrl =
				config.editorConfig.callbackUrl.replace(
					'localhost',
					this.devHost
				);
		}

		this.editor = new DocsAPI.DocEditor('doc-reader', config);
	}

	private static get uiTheme(): string {
		return JSON.parse(localStorage.getItem('glassyTheme')) || '';
	}
}
