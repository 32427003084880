<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-code-array"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'SEARCH_TEMPLATE' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>
<div
	class="fx-padding-24"
	style="padding: 0 !important; overflow: hidden; height: calc(100% - 95px)">
	@if (reports.length !== 0) {
		<div style="height: 100%; overflow: auto">
			<mat-accordion displayMode="flat" class="fx-fill fx-overflow-auto">
				@for (item of reports; track item) {
					<mat-expansion-panel
						[expanded]="item === selected"
						(click)="selected = item">
						<mat-expansion-panel-header
							[collapsedHeight]="'32px'"
							[expandedHeight]="'32px'">
							<mat-panel-title>
								{{ item.key }}
							</mat-panel-title>
						</mat-expansion-panel-header>

						<mat-nav-list dense class="my-list">
							@for (tm of item.data; track tm) {
								<mat-list-item
									(click)="selectRow(tm)"
									style="height: 27px !important">
									<span mat-line>{{ tm.name }}</span>
								</mat-list-item>
							}
						</mat-nav-list>
					</mat-expansion-panel>
				}
			</mat-accordion>
		</div>
	} @else {
		<div
			class="fx-layout-column fx-content-center fx-items-center"
			style="height: 100%">
			<mat-spinner [diameter]="50" [strokeWidth]="2"></mat-spinner>
		</div>
	}
</div>

<div matDialogActions align="end">
	<button color="primary" matDialogClose="" mat-raised-button>
		{{ 'OK' | translate }}
	</button>
	<button color="warn" matDialogClose="" mat-raised-button>
		{{ 'EXIT' | translate }}
	</button>
</div>
