@if (formattingRule) {
	<div
		class="list-style-line fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
		<div cdkDragHandle class="drag-icon">
			<mat-icon
				(click)="$event.preventDefault(); $event.stopPropagation()"
				fontIcon="mdi-drag-vertical"
				fontSet="mdi"></mat-icon>
		</div>
		<div
			[ngStyle]="previewStyle"
			class="preview-shape fx-layout-row fx-content-center fx-items-center">
			<span>{{ formattingRule.appliedTo === 'ROW' ? 'Li' : 'Col' }}</span>
		</div>
		<div
			style="width: 212px"
			class="style-detail fx-layout-column fx-content-center fx-items-start">
			<div class="header">
				{{ formattingRule.targetColumn | translate }}
			</div>
			<div class="description">
				{{ formattingRule.formatRule | translate }}:
				<span
					[style.color]="formattingRule.formattingStyle.color"
					[style.font-weight]="'bold'"
					>{{ formattingRule.primaryFormatValue | translate }}</span
				>
				@if (formattingRule.secondaryFormatValue) {
					<span
						[style.color]="formattingRule.formattingStyle.color"
						[style.font-weight]="'bold'">
						& {{ formattingRule.secondaryFormatValue }}</span
					>
				}
			</div>
		</div>

		<button (click)="deleteEvent.emit(true)" color="warn" mat-icon-button>
			<mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
		</button>
	</div>
}
