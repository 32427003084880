import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'ft-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
	commentControl: FormControl = new FormControl('');

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<CommentsComponent>
	) {}

	saveComments() {
		this.dialogRef.close(this.commentControl.value);
	}

	ngOnInit() {
		this.commentControl.patchValue(this.data);
	}
}
