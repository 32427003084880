<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">{{ 'CONFIRM' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button [matDialogClose]="false" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-24">
	<div class="confirm-content">
		{{ message || 'DELETEMESSAGE' | translate }}
	</div>
</div>
<mat-dialog-actions align="end">
	<button (click)="onNo()" color="warn" mat-raised-button tabindex="-1">
		{{ 'NO' | translate }}
	</button>
	<button (click)="onYes()" color="primary" mat-raised-button tabindex="2">
		{{ 'YES' | translate }}
	</button>
</mat-dialog-actions>
