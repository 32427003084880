export const PATIENT_LIST_CONFIG = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
		sortField: 'id',
		type: 'string',
		hidden: true,
		sortable: true,
	},
	{
		label: 'externalPatientID',
		header: 'PatientID',
		value: 'externalPatientID',
		sortField: 'external_patientid',
		type: 'string',
		hidden: true,
		sortable: true,
	},
	{
		label: 'titleId',
		header: 'Title',
		value: 'titleId',
		sortField: 'title_id',
		type: 'ID',
		hidden: false,
		sortable: true,
	},
	{
		label: 'firstName',
		header: 'FIRST_NAME',
		value: 'firstName',
		sortField: 'first_name',
		type: 'string',
		hidden: false,
		sortable: true,
	},
	{
		label: 'lastName',
		header: 'LAST_NAME',
		value: 'lastName',
		sortField: 'last_name',
		type: 'string',
		hidden: false,
		sortable: true,
	},
	{
		label: 'genderId',
		header: 'Sexe',
		value: 'genderId',
		sortField: 'gender_id',
		type: 'ID',
		hidden: false,
		sortable: true,
	},
	{
		label: 'dateOfBirth',
		header: 'DATEOFBIRTH',
		value: 'dateOfBirth',
		sortField: 'date_of_birth',
		type: 'date',
		hidden: false,
		sortable: true,
	},
	{
		label: 'cin',
		header: 'CIN',
		value: 'cin',
		sortField: 'cin',
		type: 'string',
		hidden: false,
		sortable: true,
	},
	{
		label: 'email',
		header: 'EMAIL',
		value: 'email',
		sortField: 'email',
		type: 'string',
		hidden: false,
		sortable: true,
	},
];
