<div class="fx-layout-row-nowrap fx-content-space-between fx-fill">
	<div class="fx-grow-1 fx-layout-column-nowrap fx-fill-width">
		<div id="reporter" class="fx-grow-1"></div>
		@if (templateMode === 0) {
			<button
				(click)="searchParagraphs()"
				class="paragraph-search"
				[color]="searchingParagraphs ? 'warn' : 'primary'"
				mat-mini-fab>
				<mat-icon
					style="font-size: 18px"
					fontSet="mdi"
					[fontIcon]="
						searchingParagraphs ? 'mdi-close' : 'mdi-text'
					"></mat-icon>
			</button>
		}
	</div>
	<div id="p-sidenav">
		@if (searchingParagraphs) {
			<ft-report-paragraph
				[procedureType]="procedureType"
				[procedureCode]="procedureCode"
				(insertParagraphEvent)="
					insertParagraph($event)
				"></ft-report-paragraph>
		}
	</div>
	@if (approved) {
		<img
			alt="Approved"
			id="signed"
			src="../../../assets/images/approved.png"
			tabindex="0" />
	}

	@if (templateMode === 0) {
		<button
			mat-button
			class="bottom-action-btn save-template-btn"
			(click)="saveReportAsTemplate()">
			<mat-icon fontSet="mdi" fontIcon="mdi-file-plus"></mat-icon>
			{{ 'SAVE_AS_TEMPLATE' | translate }}
		</button>
	}
	@if (templateMode === 0 && cvis) {
		<button
			mat-button
			class="bottom-action-btn compile-btn"
			(click)="compileContentControls()">
			<mat-icon fontSet="mdi" fontIcon="mdi-file"></mat-icon>
			{{ 'COMPILE' | translate }}
		</button>
	}
</div>

@if (editorStopped) {
	<div
		class="editor-stopped-message fx-fill fx-gap-24 fx-layout-column fx-content-center fx-items-center">
		<div class="fx-layout-row-nowrap fx-content-center fx-items-center">
			<mat-progress-spinner
				[strokeWidth]="6"
				[diameter]="120"
				color="primary"
				mode="determinate"
				[value]="100 - (waitingSeconds * 10) / 6">
			</mat-progress-spinner>

			<span
				style="
					z-index: 10;
					color: #1976d2;
					position: absolute;
					font-weight: bold;
					font-size: 16px;
				"
				>{{ (100 - (waitingSeconds * 10) / 6).toFixed(0) }} %</span
			>
		</div>

		<div>{{ 'EDITOR_RESTARTING' | translate }}</div>
		<button
			mat-raised-button
			[disabled]="waitingSeconds !== 0"
			color="warn"
			(click)="onRefresh()">
			{{ 'REFRESH' | translate }}
		</button>
	</div>
}
