<div class="fx-layout-column fx-content-space-between fx-fill">
	<div
		class="player-bar fx-layout-row-nowrap fx-content-space-between fx-items-center">
		<mat-icon fontIcon="mdi-play" fontSet="mdi"></mat-icon>
		<h4>{{ 'AUDIO_PLAY' | translate }}</h4>
		<span class="fx-grow-1"></span>
		<button mat-icon-button matDialogClose="">
			<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
		</button>
	</div>

	<div class="fx-grow-1 fx-layout-row fx-content-space-between">
		<div
			class="fx-grow-1 capture fx-layout-column fx-content-center fx-items-center">
			<div class="current-audio">{{ current.name }}</div>
		</div>

		<div
			class="fx-grow-1 audio-list fx-layout-column fx-content-start fx-items-stretch">
			<div class="list-title">
				<mat-icon fontSet="mdi" fontIcon="mdi-playlist-play"></mat-icon>
				{{ 'PLAYLIST' | translate }}
			</div>
			@for (audio of audioFiles; track audio) {
				<div
					class="audio-item"
					(click)="current = audio"
					[ngClass]="{ selected: current === audio }">
					{{ audio.name }}
				</div>
			}
		</div>
	</div>
	<div>
		<audio
			class="audio-player"
			[src]="current.url"
			autoplay
			controls
			preload="auto"></audio>
	</div>
</div>
