<div class="parent-layout">
	<div class="grid-display">
		<mat-grid-list
			[cols]="cols"
			[gutterSize]="gutterSize"
			[rowHeight]="rowHeight"
			style="margin: 16px 8px">
			@for (image of selectedImages; track image) {
				<mat-grid-tile
					[id]="image.url"
					[style.background]="imageBackground.toLowerCase()"
					[images]="selectedImages"
					[synchronized]="image['synchronized']"
					[viewport]="image"
					class="cornerstone-canvas fx-layout-column fx-content-center fx-items-center">
					<button
						(click)="toggleSynchronize(image)"
						class="fx-self-start"
						mat-icon-button
						style="position: absolute; top: 0; left: 0">
						<mat-icon
							[fontIcon]="
								image['synchronized']
									? 'mdi-link'
									: 'mdi-link-off'
							"
							fontSet="mdi"></mat-icon>
					</button>
				</mat-grid-tile>
			}
		</mat-grid-list>
	</div>
</div>
