import { Component, isDevMode, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReportingService } from '../../reporting/reporting.service';

@Component({
	selector: 'ft-barcode-search',
	template: `
		<div class="barcode-container">
			<div class="spacer"></div>
			<div class="anim-box center spacer">
				<div></div>
				<div class="scanner"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-md"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-md"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-md"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-lg"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-md"></div>
				<div class="anim-item anim-item-sm"></div>
				<div class="anim-item anim-item-sm"></div>
			</div>
			<div class="spacer"></div>

			<input tabindex="0" type="text" (change)="search($event)" />

			@if (notFound) {
				<div class="not-found">
					{{ 'NOT_FOUND' | translate }}
				</div>
			}
			<div
				class="fx-layout-row fx-content-center fx-items-center"
				style="position:absolute;bottom: 2em;">
				<button color="primary" mat-mini-fab [matDialogClose]="null">
					<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
				</button>
			</div>
		</div>
	`,
	styles: [
		`
			::ng-deep.barcode-panel > .mat-mdc-dialog-container {
				background: radial-gradient(#030912, #5e371c82);
			}

			::ng-deep.barcode-panel
				> .mat-mdc-dialog-container
				.mdc-dialog__surface {
				background: transparent !important;
			}

			input {
				background: #afafaf3d;
				border: 0px solid #a5a5a5;
				height: 64px;
				width: 60%;
				text-align: center;
				font-size: 28px;
				color: white;
				border-radius: 6px;
				outline: none;
			}

			.barcode-container {
				color: white;
				padding: 48px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.spacer {
				margin-top: 6rem;
			}

			/* barcode container */
			.anim-box {
				position: relative;
				width: 220px;
				height: 100px;
				padding: 25px 30px;
				transform: scale(1.5, 2);
				transition: transform 0.6s ease-out;
			}

			/* adds the 4 corners */
			.anim-box:before,
			.anim-box:after,
			.anim-box > :first-child:before,
			.anim-box > :first-child:after {
				position: absolute;
				width: 10%;
				height: 15%;
				border-color: white;
				border-style: solid;
				content: ' ';
			}

			/* top left corner */
			.anim-box:before {
				top: 0;
				left: 0;
				border-width: 2px 0 0 2px;
			}

			/* top right corner */
			.anim-box:after {
				top: 0;
				right: 0;
				border-width: 2px 2px 0 0;
			}

			/* bottom right corner */
			.anim-box > :first-child:before {
				bottom: 0;
				right: 0;
				border-width: 0 2px 2px 0;
			}

			/* bottom left corner */
			.anim-box > :first-child:after {
				bottom: 0;
				left: 0;
				border-width: 0 0 2px 2px;
			}

			/* barcode bars */
			.anim-item {
				display: inline-block;
				background-color: white;
				height: 100px;
			}

			.anim-item-sm {
				width: 2px;
				margin-right: 3px;
			}

			.anim-item-md {
				width: 3px;
				margin-right: 2px;
			}

			.anim-item-lg {
				width: 5px;
				margin-right: 5px;
			}

			/* grow on hover */
			.anim-box:hover {
				transform: scale(1.5, 2);
			}

			.anim-box:hover .scanner {
				animation-play-state: running;
			}

			/* animated laser beam */
			.scanner {
				width: 100%;
				height: 3px;
				background-color: red;
				opacity: 0.7;
				position: relative;
				box-shadow: 0 0 8px 10px rgba(170, 11, 23, 0.49);
				top: 50%;
				animation-name: scan;
				animation-duration: 4s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-play-state: running;
			}

			.not-found {
				margin-top: 16px;
				color: orange;
				font-style: italic;
			}

			@keyframes scan {
				0% {
					box-shadow: 0 0 8px 10px rgba(170, 11, 23, 0.49);
					top: 50%;
				}
				25% {
					box-shadow: 0 6px 8px 10px rgba(170, 11, 23, 0.49);
					top: 5px;
				}
				75% {
					box-shadow: 0 -6px 8px 10px rgba(170, 11, 23, 0.49);
					top: 98%;
				}
			}
		`,
	],
})
export class BarcodeSearchComponent implements OnDestroy {
	public notFound: boolean;

	constructor(
		private _router: Router,
		private _service: ReportingService,
		private _dialogRef: MatDialogRef<any>
	) {}

	search(event: any) {
		console.log(event.target.value);

		const code = isDevMode()
			? 'IP22051010380551_EX22082915421158'
			: event.target.value;

		const codeTokens = code.split('_');

		const patientID = codeTokens[0];
		const accessionNumber = codeTokens[1];

		this._service
			.getAllPatientReportingTasks(patientID, 0)
			.subscribe(tasks => {
				const task = tasks.find(
					it => it.reportingTask.accessionNumber === accessionNumber
				);
				if (!task) {
					this.notFound = true;
					return;
				}

				this._router
					.navigateByUrl(
						`/reporting/report-edition/${task.reportingTask.id}`
					)
					.then(_ => this._dialogRef.close());
			});
	}

	ngOnDestroy() {
		this.notFound = false;
	}
}
