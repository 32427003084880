<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'TASKS_LIST' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>
<div class="fx-padding-16">
	<div
		class="fx-layout-column-nowrap fx-content-start fx-gap-8"
		[formGroup]="form">
		<div class="fx-layout-column-nowrap">
			<span class="label-option">{{ 'RESOURCE' | translate }}</span>
			<mat-button-toggle-group
				#group="matButtonToggleGroup"
				aria-label="resource"
				formControlName="resource"
				name="resource">
				@for (
					resource of ['AET', 'ROOM', 'RADIOLOGIST', 'TECHNICIAN'];
					track resource
				) {
					<mat-button-toggle [value]="resource">
						{{ resource | translate }}
					</mat-button-toggle>
				}
			</mat-button-toggle-group>
		</div>

		@if (group) {
			<mat-form-field>
				<mat-label>{{ group.value | translate }}</mat-label>
				<mat-select
					formControlName="id"
					[placeholder]="group.value | translate"
					required>
					@for (item of resources[group.value]; track item) {
						<mat-option [value]="item.id"
							>{{
								item[
									['AET', 'ROOM'].includes(group.value)
										? 'name'
										: 'fullName'
								]
							}}
						</mat-option>
					}
				</mat-select>
			</mat-form-field>
		}

		<mat-form-field>
			<mat-date-range-input [rangePicker]="picker" required>
				<input
					[placeholder]="'FROM' | translate"
					formControlName="startDate"
					matStartDate />
				<input
					[placeholder]="'TO' | translate"
					formControlName="endDate"
					matEndDate />
			</mat-date-range-input>
			<mat-datepicker-toggle
				[for]="picker"
				matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>
	</div>
</div>
<div
	matDialogActions
	class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4">
	<button mat-raised-button color="warn" matDialogClose="">
		{{ 'CLOSE' | translate }}
	</button>
	<span class="fx-grow-1"></span>
	<button
		mat-raised-button
		color="primary"
		[disabled]="form.invalid"
		(click)="downloadList()">
		<mat-icon fontSet="mdi" fontIcon="mdi-cloud-download"></mat-icon>
		{{ 'DOWNLOAD' | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		[disabled]="form.invalid"
		(click)="generateList()">
		<mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
		{{ 'PRINT' | translate }}
	</button>
</div>
