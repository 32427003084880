import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { LabelDTO } from '../../model';
import { SharedService } from '../shared.service';
import { MatDialog } from '@angular/material/dialog';
import { LabelEditComponent } from '../label-edit/label-edit.component';

@Component({
	selector: 'ft-labels-popup',
	template: `
		<div class="popup-suggestion">
			@for (label of labels; track label) {
				<div
					style="width: 100%; height: 26px;line-height: 26px;"
					class="popup-row"
					(click)="selectLabel(label)">
					<div [style.color]="label.color">{{ label.value }}</div>
					<div>{{ label.description }}</div>
				</div>
			}
			@if (labels.length === 0) {
				<button
					color="primary"
					style="width: 100%"
					mat-button
					(click)="createLabel()">
					<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
					{{ 'NEW' | translate }}
				</button>
			}
		</div>
	`,
	styles: [
		`
			.popup-suggestion {
				background: #ffffff;
				padding: 8px 12px;
				border-radius: 4px;
				margin-bottom: 36px;
				box-shadow: 0 0 16px #424242;
				max-height: 200px;
				overflow: auto;
			}

			.popup-row {
				display: flex;
				flex-direction: row;
				gap: 6px;
				justify-content: flex-start;
				border-bottom: 1px solid lightgrey;
			}

			.popup-row:hover {
				background: #ffface;
				cursor: pointer;
			}

			.popup-row:last-of-type {
				border-bottom: none;
			}

			.popup-row > div:first-child {
				width: 120px;
			}
			.popup-row > div:last-child {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 256px;
			}
		`,
	],
})
export class LabelsPopupComponent implements OnChanges, AfterViewInit {
	labels: LabelDTO[] = [];

	@Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
	@Input() searchKey: string;

	constructor(
		private _service: SharedService,
		private _dialog: MatDialog
	) {}

	ngAfterViewInit() {
		this.queryItems();
	}

	selectLabel(label: LabelDTO) {
		this.itemSelected.emit(label);
		this.queryItems(this.searchKey);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { searchKey } = changes;
		if (searchKey.currentValue) this.queryItems(searchKey.currentValue);
		else this.queryItems();
	}

	queryItems(searchKey: string = ''): void {
		this._service
			.getLabels(10, 0, 'value', 'asc', `${searchKey?.trim()}_`)
			.subscribe(data => (this.labels = data.content));
	}

	createLabel() {
		this._dialog
			.open(LabelEditComponent, {
				data: new LabelDTO(null, this.searchKey),
				disableClose: true,
			})
			.afterClosed()
			.subscribe(_ => this.queryItems(this.searchKey));
	}
}
