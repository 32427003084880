<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-label-multiple"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">{{ 'LABEL' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-24">
	<form [formGroup]="labelForm">
		<div class="fx-layout-column fx-gap-8">
			<mat-form-field>
				<mat-label>{{ 'value' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'value' | translate"
					formControlName="value"
					required />
			</mat-form-field>

			<mat-form-field>
				<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'DESCRIPTION' | translate"
					formControlName="description" />
			</mat-form-field>

			<div class="fx-layout-column" style="margin-top: 8px">
				<h5 style="margin: 6px 0">{{ 'LABEL_MODULE' | translate }}</h5>
				<mat-button-toggle-group
					aria-label="labelModule"
					name="labelModule"
					formControlName="module">
					@for (lbl of labelModules; track lbl) {
						<mat-button-toggle [value]="lbl">
							{{ lbl | translate }}
						</mat-button-toggle>
					}
				</mat-button-toggle-group>
			</div>

			<div class="ft-color">
				<div>{{ 'COLOR' | translate }}:</div>
				<div
					class="color-area"
					[(colorPicker)]="color"
					[style.background]="color"></div>
			</div>
		</div>
	</form>
</div>
<div matDialogActions align="end">
	<button mat-button color="warn" [mat-dialog-close]="null">
		{{ 'CANCEL' | translate }}
	</button>
	<button
		mat-button
		color="primary"
		(click)="save(labelForm.value)"
		[disabled]="labelForm.invalid">
		{{ 'SAVE' | translate }}
	</button>
</div>
