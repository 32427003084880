<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-folder"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'TEMPLATEMODEL' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div [formGroup]="form" class="fx-layout-column-nowrap fx-padding-24">
	<div
		class="message-usr fx-layout-row-nowrap fx-content-start fx-items-start fx-gap-12">
		<mat-icon
			style="width: 24px"
			fontIcon="mdi-alert"
			fontSet="mdi"></mat-icon>
		<span class="fx-grow-1">{{ 'REPORT_SAVE_WARNING' | translate }}</span>
	</div>
	<mat-form-field class="fx-grow-1">
		<mat-label>{{ 'TEMPLATENAME' | translate }}</mat-label>
		<input
			matInput
			placeholder="{{ 'TEMPLATENAME' | translate }}"
			formControlName="name" />
	</mat-form-field>
	<mat-form-field>
		<mat-label>{{ 'FAMILY' | translate }}</mat-label>
		<mat-select
			placeholder="{{ 'FAMILY' | translate }}"
			formControlName="category">
			@for (cat of categories; track cat) {
				<mat-option [value]="cat">{{ cat }}</mat-option>
			}
		</mat-select>
	</mat-form-field>
	<mat-slide-toggle style="font-size: 13px" formControlName="isPrivate">{{
		'PRIVATE' | translate
	}}</mat-slide-toggle>
</div>

<div matDialogActions align="end">
	<button mat-raised-button color="warn" matDialogClose="">
		{{ 'CANCEL' | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="saveReportTemplate(form.value)">
		{{ 'SAVE' | translate }}
	</button>
</div>
