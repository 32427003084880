import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    DefaultValues,
    GeneralSettingDTO,
    Hl7IdName,
    PaymentDTO,
    PaymentItemDTO,
    PaymentStatus, ProcedureCode, ProcedureCodeDTO,
    Profile,
} from '../../model';
import {assign, keys} from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {SharedService} from '../shared.service';
import {PathologyEditComponent} from '../../setting/pathology-setting/pathology-edit/pathology-edit.component';
import moment from 'moment';
import {AppConfigService} from '../../app-config.service';
import {map, startWith} from 'rxjs/operators';
import set = Reflect.set;
import {ProcedureCodeSearchComponent} from "../../scheduler/procedure-code-search/procedure-code-search.component";

@Component({
    selector: 'ft-payment-form',
    templateUrl: './payment-form.component.html',
    styleUrl: './payment-form.component.scss'
})
export class PaymentFormComponent implements OnInit, OnDestroy {

    form: FormGroup;
    details = false;
    currencyFormat = 'DH';
    banks: any[] = [];
    organisms: any[] = [];
    paymentMethods: any[] = [];

    organismPart = 0.0;
    dialogData = {type: 'external', icon: 'plus'};
    private basePrice: number;
    payment = new PaymentDTO();
    paymentItems: PaymentItemDTO[];
    performedPayment: boolean;
    leftAmount: number;
    public profile: Profile;

    conventions: any = [];
    selectedConvention: { name: string, patientPartPercentage: number, organismPartPercentage: number };
    selectedConventionString: string;
    selectedOrganism: { name?: string, code?: string, id?: string } = {};
    private subs: Subscription[] = [];
    private generalSetting: GeneralSettingDTO;

    private defaultValues: DefaultValues;
    filteredOrganisms: Observable<Hl7IdName[]>;
    organismRequired: boolean;
    private conventionExceptions: any[];

    private static adjustConvention(convention: string) {
        if (!convention) return {name: '', organismPartPercentage: 0, patientPartPercentage: 100};
        const cnv = convention.split('@');
        const patientPartPercentage = parseFloat(cnv[2]);
        const organismPartPercentage = parseFloat(cnv[3]);
        return {name: convention, organismPartPercentage, patientPartPercentage};
    }

    private static formatNumber(value: number): string {
        return value.toFixed(2);
    }

    private static getPayer(paymentType: string) {
        if (paymentType === 'THIRD_PARTY_PAYMENT') return 'THIRD_PAYER';
        if (paymentType === 'INSURED_PATIENT') return 'PATIENT';
        return paymentType;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private service: SchedulerService,
                private _appConfig: AppConfigService,
                private shared: SharedService,
                private dialog: MatDialog,
                private snackBar: MatSnackBar) {
        this.currencyFormat = _appConfig.currencyFormat;

        this.createForm();

        this.profile = JSON.parse(localStorage.getItem('profile'));

        this.subs.push(this.form.get('paymentDispense').valueChanges
            .subscribe(value => {
                this.payment.paymentDispense = value;
                for (const key in this.form.controls) if (!['paymentDispense', 'locked'].includes(key)) {
                    value ? this.form.controls[key].disable() : this.form.controls[key].enable()
                }
            }));
    }

    private updatePayment(paymentItems: PaymentItemDTO[]) {
        this.basePrice = paymentItems.map(v => v.initialPrice !== 0.0 ? v.initialPrice : v.price).reduce((a, b) => a + b, 0);
        const totalDue = PaymentFormComponent.formatNumber(paymentItems.map(v => (v.price * (v.quantity || 1) - v.discount)).reduce((a, b) => a + b, 0));
        const patientDue = PaymentFormComponent.formatNumber(paymentItems.map(v => (v.price * (v.quantity || 1) - v.discount) * v.patientPart / 100).reduce((a, b) => a + b, 0));
        this.form.patchValue({
            baseAmount: PaymentFormComponent.formatNumber(this.basePrice),
            due: totalDue,
            enteredAmount: Number(patientDue) - this.payment.discount,
            patientName: this.data.patientName,
            patientID: this.data.patientID,
            paymentItems: paymentItems,
            patientPart: patientDue,
            organismPart: (Number(totalDue) - Number(patientDue)).toFixed(2),
            totalAfterDiscount: Number(totalDue) - this.payment.discount
        });
    }

    updatePaymentForm(paymentItems: PaymentItemDTO[], disable?: boolean) {
        this.updatePayment(paymentItems);

        this.form.get('payer').patchValue(PaymentFormComponent.getPayer(paymentItems[0].paymentType));

        const [organismName, organismId] = paymentItems[0].organismName && paymentItems[0].organismName.length > 0 ? paymentItems[0].organismName.split('@') : []
        this.form.get('organismName').patchValue(organismName);
        this.selectedOrganism = this.organisms.find(org => org.name === organismName) || {}

        if (organismId) this.shared.getOrganismConventions(organismId).subscribe(conventions => {
            this.conventions = conventions;
            this.selectedConventionString = this.conventions.find((conv: any) => conv.startsWith(this.orgAttrByIdx(paymentItems[0].conventionName, 0)))
            this.selectedConvention = PaymentFormComponent.adjustConvention(this.selectedConventionString);
        });

        if (disable) setTimeout(() => {
            this.form.get('baseAmount').disable();
            this.form.get('due').disable();
            this.form.get('patientPart').disable();
            this.form.get('organismPart').disable();
            this.form.get('totalAfterDiscount').disable();
        });
    }

    ngOnInit() {
        const datasets = 'banks,paymentMethods,defaultValues,generalSetting';
        this.subs.push(this.shared.getDatasets(datasets).subscribe(data => {
            datasets.split(',').forEach(it => this[it] = data[it]);

            this.shared.getOrganismsList().subscribe(organisms => this.organisms = organisms);


            if (this.defaultValues.defaultPaymentMethod) this.form.get('paymentMethodId').patchValue(this.paymentMethods.find(v => v.code === this.defaultValues.defaultPaymentMethod)?.id);
            else this.form.get('paymentMethodId').patchValue(this.paymentMethods.find(v => v.code === 'CASH')?.id);

            this.form.get('payer').patchValue(this.defaultValues.defaultPaymentModality);

            this.filteredOrganisms = this.form.get('organismName').valueChanges.pipe(
                startWith(''),
                map(value => this._filter(value))
            );

            this.getPaymentDetails();
            this.calculateDue();
        }));
    }

    private _filter(value: string): Hl7IdName[] {
        const filterValue = value ? value.toLowerCase() : '';

        return this.organisms.filter(it => (it.name + it.code).toLowerCase().includes(filterValue));
    }

    createForm = () => this.form = this.fb.group(new PaymentDTO());

    orgAttrByIdx = (organism: string, idx: number): string => {
        return organism ? organism.split('@')[idx] : organism;
    };

    createPayment(): void {
        this.payment = this.form.getRawValue();

        const status = this.getPaymentStatus();

        assign(this.payment, {
            date: moment(this.payment.date).add(1, 'hour'),
            dueDate: moment(this.payment.dueDate).add(1, 'hour'),
            paymentItems: this.paymentItems.map(item => {
                item.status = status;
                item.paymentID = this.payment.paymentID;
                item.conventionName = this.selectedConventionString
                return item;
            }),
            organismName: this.selectedOrganism?.name,
            organismNameId: this.selectedOrganism?.name,
            organismCode: this.selectedOrganism?.code,
            conventionName: this.selectedConvention?.name,
            paymentStatus: status
        });

        this.subs.push(this.service.createPayment(this.payment).subscribe(res => {
            if (res && res.id) {
                this.snackBar.open('Paiement reçu avec succès!', '', {duration: 2000});
                this.performedPayment = true;
                setTimeout(() => this.disableForm());
            }
        }));
    }

    addBank(): void {
        assign(this.dialogData, {title: 'BANK'});
        this.subs.push(this.dialog.open(PathologyEditComponent, {data: this.dialogData, disableClose: true})
            .afterClosed()
            .subscribe(data => {
                if (data) {
                    this.shared.createBank(data)
                        .subscribe(res => {
                            if (res && res.id) {
                                this.banks.push(res);
                                this.form.get('bank').patchValue(res);
                                this.snackBar.open('Nouvelle Banque enregistrée avec succès!', 'Ok', {duration: 2000})
                            }
                        })
                }
            }));
    }

    enterDiscount(e: any): void {
        const discount = parseFloat(e.target.value);
        const due = this.form.get('due').value;
        const totalAfterDiscount = due - discount;
        const percentage = discount * 100 / due;

        const patientPart = (this.selectedConvention ? this.selectedConvention.patientPartPercentage : 100) * totalAfterDiscount / 100;
        const organismPart = (this.selectedConvention ? this.selectedConvention.organismPartPercentage : 0) * totalAfterDiscount / 100;
        const organismPartPercentage = organismPart * 100 / totalAfterDiscount;

        this.form.get('patientPart').patchValue(patientPart.toFixed(2));
        this.form.get('organismPart').patchValue(organismPart.toFixed(2));
        this.form.get('organismPartPercentage').patchValue(organismPartPercentage.toFixed(2));
        this.form.get('totalAfterDiscount').patchValue(totalAfterDiscount.toFixed(2));
        this.form.get('discountPercentage').patchValue(percentage.toFixed(2));
        this.form.get('enteredAmount').patchValue(patientPart.toFixed(2));
    }

    cancelPayment() {
        this.subs.push(this.service.cancelPayment(this.payment.paymentID).subscribe(res => {
            if (res) {
                this.performedPayment = false;
                this.payment = new PaymentDTO();
                this.getPaymentDetails();
                this.snackBar.open('Le paiement a été annulé !', 'OK', {duration: 2000});
            }
        }));
    }

    enterAmount(e: any): void {
        const due = this.form.get('due').value;
        let amount = parseFloat(e.target.value);
        const discount = this.form.get('discount').value;
        const orgPart = this.form.get('organismPart').value;

        const amt = due - discount - orgPart;

        if (amount > amt) amount = amt;

        this.form.get('enteredAmount').patchValue(amount.toFixed(2));
    }

    private get printMode(): 'CUPS' | 'CHROME' | string {
        return this.generalSetting.receiptPrintMode;
    }

    onSelectConvention(selection: any) {
        this.adjustParts(selection.value);
    }

    ngOnDestroy() {
        this.subs?.forEach(sub => sub?.unsubscribe());
    }

    private adjustParts(convention: any) {
        this.conventionExceptions = [];
        if (!convention) return;

        this.selectedConvention = PaymentFormComponent.adjustConvention(convention);
        this.paymentItems.forEach(item => {
            set(item, 'organismPart', this.selectedConvention.organismPartPercentage);
            set(item, 'patientPart', this.selectedConvention.patientPartPercentage);
            set(item, 'patientPartPrice', (this.selectedConvention.patientPartPercentage * (item.price * item.quantity - item.discount) / 100).toFixed(2));
            set(item, 'organismPartPrice', (this.selectedConvention.organismPartPercentage * (item.price * item.quantity - item.discount) / 100).toFixed(2));
            set(item, 'conventionName', convention);
        });

        this.subs.push(this.shared.getConventionException(this.orgAttrByIdx(convention, 1)).subscribe(data => {
            if (data) {
                this.conventionExceptions = data.map(v => {
                    const [procedureCode, patientPart, organismPart] = v.split('@');
                    return {
                        procedureCode: procedureCode.trim(),
                        patientPart: Number(Number(patientPart).toFixed(2)),
                        organismPart: Number(Number(organismPart).toFixed(2))
                    };
                });
                this.updatePaymentExceptions();
            }
        }));


        const payment = this.form.getRawValue() as PaymentDTO;

        const patientPart = Number((this.selectedConvention.patientPartPercentage * payment.totalAfterDiscount / 100).toFixed(2));

        this.form.get('patientPart').patchValue(patientPart);
        this.form.get('enteredAmount').patchValue(patientPart);
        this.form.get('organismPart').patchValue(Number((this.selectedConvention.organismPartPercentage * payment.totalAfterDiscount / 100).toFixed(2)));
    }

    private getPaymentStatus(): string {
        const payment = this.form.getRawValue() as PaymentDTO;

        if (payment.totalAfterDiscount === 0 || payment.paymentDispense) return PaymentStatus.EXEMPT;
        else if (payment.enteredAmount === 0) return PaymentStatus.NOT_PAID;
        else if (payment.enteredAmount === payment.totalAfterDiscount) return PaymentStatus.PAID;
        else return PaymentStatus.PAID_PARTIALLY;
    }

    changeItemValue(property: string, item: PaymentItemDTO, event: any): void {
        const new_value = Number(event.target['innerText'] || 0);
        set(item, property, new_value);
        set(item, 'leftAmount', Number(item.price * item.quantity - item.discount).toFixed(2));
        set(item, 'percentageDiscount', Number((item.discount * 100 / (item.price * item.quantity)).toFixed(2)));
        set(item, 'patientPartPrice', (item.price * item.patientPart / 100).toFixed(2));
        set(item, 'organismPartPrice', (item.price * item.organismPart / 100).toFixed(2));

        setTimeout(() => this.updatePaymentForm(this.payment.paymentItems));
    }

    private getPaymentDetails(): void {
        this.subs.push(this.service.getPaymentDetails(this.data.paymentID)
            .subscribe(payment => {
                if (payment) {
                    const {enteredAmount} = payment;

                    this.payment = payment;
                    this.basePrice = payment.due;
                    this.paymentItems = payment.paymentItems.map(it => {
                        return assign(it, {
                            patientPartPrice: (it.patientPart * it.price / 100).toFixed(2),
                            organismPartPrice: (it.organismPart * it.price / 100).toFixed(2),
                        });
                    });

                    this.performedPayment = [
                        PaymentStatus.EXEMPT,
                        PaymentStatus.PAID,
                        PaymentStatus.PAID_PARTIALLY
                    ].includes(PaymentStatus[payment.paymentStatus]);

                    this.form.patchValue(payment);
                    this.updatePaymentForm(payment.paymentItems);
                    this.form.patchValue({enteredAmount});
                    const [organismName, organismId] = payment?.organismName && payment?.organismName.length > 0 ? payment.organismName.split('@') : []

                    this.form.get('organismName').patchValue(organismName);
                    if (payment.payer?.toString() === 'INSURED_PATIENT') this.form.get('payer').patchValue('PATIENT');

                    this.selectedOrganism = this.organisms.find(it => it.name === organismName);


                    if (organismId) this.shared.getOrganismConventions(organismId).subscribe(conventions => {
                        this.conventions = conventions;
                        this.selectedConventionString = this.conventions.find((conv: any) => conv.startsWith(this.orgAttrByIdx(payment.conventionName, 0)))
                        this.selectedConvention = PaymentFormComponent.adjustConvention(this.selectedConventionString);
                    });

                } else {
                    this.service.getPaymentOrderDetails(this.data.accessionNumber).subscribe(paymentItems => {

                        this.paymentItems = paymentItems.map(it =>
                            assign(it, {
                                patientPartPrice: (it.patientPart * it.price / 100).toFixed(2),
                                organismPartPrice: (it.organismPart * it.price / 100).toFixed(2),
                            })
                        );
                        this.payment.paymentItems = paymentItems;

                        this.form.get('payer').valueChanges.subscribe(value => {
                            if (['THIRD_PAYER', 'THIRD_PARTY_PAYMENT'].includes(value)) {
                                this.organismRequired = true;
                                this.form.get('organismName').setValidators([Validators.required]);
                                this.updatePayment(this.paymentItems)
                            } else {
                                this.organismRequired = false;
                                this.form.get('organismName').setValidators(null);
                                this.form.get('organismName').patchValue(null);
                                this.selectedConvention = {
                                    name: '',
                                    organismPartPercentage: 0,
                                    patientPartPercentage: 100
                                };
                                this.selectedConventionString = '';
                                this.updatePatientPayment();
                            }
                            this.form.get('organismName').updateValueAndValidity({emitEvent: false, onlySelf: true})
                        });

                        this.performedPayment = false;
                        this.updatePaymentForm(paymentItems, true);
                    });
                }

                setTimeout(() => this.disableForm());
            }));
    }

    private disableForm() {
        const formFields = keys(new PaymentDTO());
        if (this.performedPayment) formFields.forEach(key => this.form.get(key).disable({
            onlySelf: true,
            emitEvent: false
        }));
        else formFields.forEach(key => this.form.get(key).enable({onlySelf: true, emitEvent: false}));
    }

    private calculateDue() {
        this.subs.push(this.form.valueChanges.subscribe((payment: PaymentDTO) => {
            const total = this.form.getRawValue().due || 0;
            const amount = payment.enteredAmount || 0;
            const discount = payment.discount || 0;

            const leftAmount = Number((total - amount - discount).toFixed(2));
            this.leftAmount = Number((leftAmount < 0 ? 0 : leftAmount).toFixed(2));
        }));
    }

    printPaymentReceipt() {
        const snackBarRef = this.snackBar.open('Printing receipt ...', '', {duration: 10000});
        if (this.printMode === 'CHROME') {
            this.subs.push(this.service.printPaymentReceipt(this.payment).subscribe(_ => snackBarRef.dismiss()));
        } else {
            this.subs.push(this.service.printCupsPaymentReceipt(this.payment).subscribe(ok => {
                if (ok['status'] !== 'ok') alert('Cannot print the receipt');
                snackBarRef.dismiss();
            }));
        }
    }

    onSelectOrganismNew() {
        const organismName = this.form.get('organismName').value;
        if (!organismName) return;

        const org = this.organisms.find(it => it.name.trim() === organismName.trim());
        if (!org) return;

        this.selectedOrganism = org;
        const organism = [org.name, org.id].join('@');

        this.paymentItems.forEach(item => set(item, 'organismName', organism));

        this.shared.getOrganismConventions(org.id).subscribe(conventions => {
            this.conventions = conventions;
            this.selectedConventionString = this.conventions[0];
            this.selectedConvention = PaymentFormComponent.adjustConvention(this.selectedConventionString);
            this.onSelectConvention({value: this.selectedConventionString});
        });
    }

    private updatePaymentExceptions() {
        this.paymentItems.forEach(item => {
            const exception = this.conventionExceptions.find(it => it.procedureCode.trim() === item.procedureCode.trim());
            if (exception) {
                set(item, 'organismPart', exception.organismPart);
                set(item, 'patientPart', exception.patientPart);
                set(item, 'organismPartPrice', ((item.price * item.quantity - item.discount) * exception.organismPart / 100).toFixed(2));
                set(item, 'patientPartPrice', ((item.price * item.quantity - item.discount) * exception.patientPart / 100).toFixed(2));
            }
        })
    }

    private updatePatientPayment() {
        this.paymentItems.forEach(item => {
            set(item, 'organismPart', 0);
            set(item, 'patientPart', 100);
            set(item, 'organismPartPrice', 0);
            set(item, 'patientPartPrice', (item.price * item.quantity - item.discount).toFixed(2));
        });

        this.updatePayment(this.paymentItems);
    }

    editProcedureCode(item: PaymentItemDTO) {
        this.subs.push(this.dialog.open(ProcedureCodeSearchComponent, {minWidth: '600px'})
            .afterClosed()
            .subscribe((code: ProcedureCode) => {
                if (code) {

                    console.log(code);

                    item.initialPrice = code.billingCode.price;
                    item.price = code.billingCode.price;
                    item.procedureCode = code.code;

                    item['patientPartPrice'] = (item.price * item.patientPart / 100).toFixed(2);
                    item['organismPartPrice'] = (item.price * item.organismPart / 100).toFixed(2);


                    this.updatePaymentForm(this.payment.paymentItems);
                }
            }));
    }
}
