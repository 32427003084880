export class PrescriptionItemNode {
	children: PrescriptionItemNode[];
	name: string;
}

export class PrescriptionItemFlatNode {
	name: string;
	level: number;
	expandable: boolean;
}

export class Prescription {
	id: number;
	patientID: string;
	patientName: string;
	title: string;
	physician: string;
	prescriptionDate: Date = new Date();
	prescriptionItems: PrescriptionItem[];
}

export class PrescriptionItem {
	id: number;
	uuid: string;
	name: string;
	parentItemUUID: string;
}
