<div style="overflow: hidden">
	<mat-toolbar color="primary">
		<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>&nbsp;
		{{ 'DEFAULT_COPIES' | translate }}
		<span class="fx-grow-1"></span>
		<button mat-dialog-close mat-icon-button>
			<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
		</button>
	</mat-toolbar>

	<div class="fx-layout-column" style="padding: 8px">
		<mat-form-field>
			<mat-label>{{ 'PRINT_COUNT' | translate }}</mat-label>
			<input
				#element
				[value]="count"
				matInput
				style="text-align: right; font-weight: bold"
				type="number" />
		</mat-form-field>
	</div>

	<mat-dialog-actions align="end">
		<button color="warn" mat-dialog-close="0" mat-icon-button>
			<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
		</button>
		<button
			[disabled]="element.value.length === 0"
			[mat-dialog-close]="element.value"
			color="primary"
			mat-icon-button>
			<mat-icon fontIcon="mdi-floppy" fontSet="mdi"></mat-icon>
		</button>
	</mat-dialog-actions>
</div>
