import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
	BehaviorSubject,
	forkJoin,
	fromEvent as observableFromEvent,
	merge,
	of as observableOf,
} from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SharedService } from '../shared.service';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import { PATIENT_LIST_CONFIG } from './table-conf';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientService } from '../../patient/patient.service';

@Component({
	selector: 'ft-patient-list',
	templateUrl: './patient-list.component.html',
	styleUrls: ['./patient-list.component.scss'],
	animations: [rowsAnimation],
})
export class PatientListComponent implements OnInit, AfterViewInit {
	dataSource = new MatTableDataSource();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	filterChange = new BehaviorSubject('');

	cols: any[];
	displayedColumns = [];

	titles = {};
	genders = {};

	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _shared: SharedService,
		private _patientService: PatientService,
		private _dialogRef: MatDialogRef<PatientListComponent>
	) {
		this.cols = PATIENT_LIST_CONFIG.filter(it => !it.hidden).map(
			it => it.label
		);
		this.displayedColumns = PATIENT_LIST_CONFIG;
	}

	ngOnInit() {
		forkJoin([
			this._shared.getTitles(),
			this._shared.getGenders(),
		]).subscribe(data => {
			const [titles, genders] = data;
			titles.forEach(it => (this.titles[it.id] = it.value));
			genders.forEach(it => (this.genders[it.id] = it.value));
		});
	}

	ngAfterViewInit() {
		const query = this.data.firstName + ' ' + this.data.lastName;
		this.filterChange.next(query);
		this.filter.nativeElement.value = query;

		observableFromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.dataSource) return;
				this.paginator.pageIndex = 0;
				this.filterChange.next(this.filter.nativeElement.value);
			});

		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		merge(
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.filterChange
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this._patientService.getPatients(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						this.filter.nativeElement.value
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
					return data['content'];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	selectPatient(row) {
		this._dialogRef.close(row);
	}
}
