import moment from 'moment';

export const APPOINTMENT_STATUSES = [
	'canceled',
	'waiting_list',
	'entered',
	'missed',
	'planned',
	'unplanned',
];

export const APT_FOR_RESULT_STATUSES = [
	'canceled',
	'missed',
	'planned',
	'completed',
];

export function getOrderStatusColor(status: string): string {
	switch (status) {
		case 'unplanned':
			return '#7764a6';
		case 'entered':
			return '#36a5fa';
		case 'waiting_list':
			return '#ab6d00';
		case 'planned':
			return '#e1079e';
		case 'canceled':
			return '#fa1848';
		case 'missed':
			return '#cb7604';
		case 'completed':
			return '#00a131';
		default:
			return '#f12727';
	}
}

export function getOrderStatusIcon(status: string): string {
	switch (status) {
		case 'unplanned':
			return 'mdi-calendar-clock';
		case 'entered':
			return 'mdi-account-arrow-left';
		case 'waiting_list':
			return 'mdi-timer-sand';
		case 'planned':
			return 'mdi-calendar-check';
		case 'canceled':
			return 'mdi-calendar-remove';
		case 'missed':
			return 'mdi-calendar-export';
		case 'completed':
			return 'mdi-calendar-lock';
	}
}

export function getRdvStatusIcon(status: string): string {
	switch (status) {
		case 'planned':
			return 'mdi-calendar-clock';
		case 'canceled':
			return 'mdi-calendar-remove';
		case 'missed':
			return 'mdi-calendar-export';
		case 'completed':
			return 'mdi-calendar-lock';
	}
}

export const SOURCES_COLORS: any = {
	FireRIS: '#00bcd4',
	PORTAL: '#f44336',
	HIS: '#48f0a8',
};

export enum AptStatus {
	waiting_list = 'waiting_list',
	canceled = 'canceled',
	planned = 'planned',
	unplanned = 'unplanned',
	missed = 'missed',
	entered = 'entered',
	completed = 'completed',
}

export class OrderFilter {
	constructor(
		public key: string = '',
		public dateRange: string = '',
		public physicianId: string = null,
		public appointmentStatus: string = 'ALL',
		public priority: string = 'ALL',
		public examType?: string,
		public modality: string = 'ALL'
	) {
		const today = moment().format('YYYYMMDD');
		this.dateRange = `${today}-${today}`;
	}
}
