<div class="fx-layout-column fx-fill">
	<div class="outer-container">
		<button (click)="close()" mat-icon-button color="warn">
			<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
		</button>

		<div class="inner-container">
			@for (p of reportText; track p) {
				<div class="text-p">{{ p }}</div>
			} @empty {
				<p>No content.</p>
			}
		</div>
	</div>
</div>
