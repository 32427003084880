<mat-toolbar>
	<span>Recorder</span>
</mat-toolbar>
<mat-dialog-content>
	<div style="margin: -12px">
		<video #video class="video"></video>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button color="primary" (click)="startRecording()">
		Record
	</button>
	<button mat-button color="primary" (click)="stopRecording()">Stop</button>
	<button mat-button color="primary" (click)="download()">Download</button>
</mat-dialog-actions>
