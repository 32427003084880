import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarEvent, GeneralSettingDTO } from '../../../model';

@Component({
	selector: 'ft-calendar-event',
	templateUrl: './calendar-event.component.html',
	styleUrls: ['./calendar-event.component.scss'],
})
export class CalendarEventComponent {
	@Output() deleteEvent = new EventEmitter<CalendarEvent>();
	@Output() editEvent = new EventEmitter<CalendarEvent>();
	@Output() scheduleEvent = new EventEmitter<CalendarEvent>();
	@Output() paymentEditEvent = new EventEmitter<CalendarEvent>();
	@Output() patientEditEvent = new EventEmitter<CalendarEvent>();
	@Output() selectEvent = new EventEmitter<CalendarEvent>();
	@Output() patientAuthorizeEvent = new EventEmitter<CalendarEvent>();

	_event: CalendarEvent;
	_viewType: string;
	_user: any;
	_visible: any;
	_generalSetting: GeneralSettingDTO;
	_eventSelection: boolean;

	@Input()
	set event(event: CalendarEvent) {
		this._event = event;
	}

	get event(): CalendarEvent {
		return this._event;
	}

	@Input()
	set viewType(viewType: string) {
		this._viewType = viewType;
	}

	get viewType(): string {
		return this._viewType;
	}

	@Input()
	set eventSelection(eventSelection: boolean) {
		this._eventSelection = eventSelection;
	}

	get eventSelection(): boolean {
		return this._eventSelection;
	}

	@Input()
	set user(user: any) {
		this._user = user;
	}

	get user(): any {
		return this._user;
	}

	@Input()
	set visible(visible: boolean) {
		this._visible = visible;
	}

	get visible(): boolean {
		return this._visible;
	}

	get generalSetting(): GeneralSettingDTO {
		return this._generalSetting;
	}

	@Input()
	set generalSetting(generalSetting: GeneralSettingDTO) {
		this._generalSetting = generalSetting;
	}
}
