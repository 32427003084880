import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { ReportingTask, ReportingTaskDTO } from '../../model';
import { ReportingService } from '../../reporting/reporting.service';

@Component({
	selector: 'ft-report-dialog',
	templateUrl: './report-dialog.component.html',
	styleUrls: ['./report-dialog.component.scss'],
})
export class ReportDialogComponent implements OnChanges {
	@Input() reportingTask: ReportingTask | ReportingTaskDTO;
	@Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

	reportText: string[] = [];

	private _service = inject(ReportingService);

	ngOnChanges(changes: SimpleChanges) {
		const { reportingTask } = changes;

		if (reportingTask.currentValue)
			this._service
				.getReportText(reportingTask.currentValue.id)
				.subscribe(data => (this.reportText = data));
	}

	close() {
		this.reportText = [];
		this.closeEvent.emit(true);
	}
}
