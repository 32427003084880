import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, fromEvent, merge, of } from 'rxjs';
import { SettingService } from '../../setting/setting.service';
import { SharedService } from '../../shared';
import { MatDialogRef } from '@angular/material/dialog';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import { PROCEDURE_CODE_TABLE_CONFIG } from './table-conf';

@Component({
	selector: 'ft-procedure-codes',
	templateUrl: './procedure-codes.component.html',
	styleUrls: ['./procedure-codes.component.scss'],
	animations: [rowsAnimation],
})
export class ProcedureCodesComponent implements AfterViewInit {
	cols: any[];
	displayedColumns = [];

	dataSource = new MatTableDataSource();
	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	filterChange = new BehaviorSubject('');
	trackById = (index: number, item: any): string => item.id;

	constructor(
		private service: SettingService,
		private sharedService: SharedService,
		public dialogRef: MatDialogRef<ProcedureCodesComponent>
	) {
		this.displayedColumns = PROCEDURE_CODE_TABLE_CONFIG;
		this.cols = PROCEDURE_CODE_TABLE_CONFIG.map(it => it.label);
	}

	selectCode = row => {
		if (row) this.dialogRef.close(row);
	};

	ngAfterViewInit() {
		fromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.dataSource) return;
				this.paginator.pageIndex = 0;
				this.filterChange.next(this.filter.nativeElement.value);
			});

		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		const observedFilters = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.filterChange.asObservable(),
		];

		merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this.sharedService.queryProcedureCodes(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						this.filterChange.getValue()
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];

					return data['content'];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return of([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}
}
