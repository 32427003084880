<full-calendar #calendar [options]="calendarOptions"></full-calendar>

<mat-form-field style="display: none">
	<mat-label></mat-label>
	<input matInput [matDatepicker]="picker" (dateChange)="gotoDate($event)" />
	<mat-datepicker #picker [touchUi]="true"></mat-datepicker>
</mat-form-field>

<mat-form-field style="display: none">
	<mat-label></mat-label>
	<input
		matInput
		[matDatepicker]="targetPicker"
		(dateChange)="moveToDate($event)" />
	<mat-datepicker #targetPicker [touchUi]="true"></mat-datepicker>
</mat-form-field>

<ng-template let-event="event">
	<div class="fc-content">
		<span>{{ event.title }}</span>
	</div>
</ng-template>
