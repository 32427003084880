import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { Field } from '../../models/field.interface';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

export function arrayDiffObj(s: any[], v: any[], key: string) {
	const reducedIds = v.map(o => o[key]);
	return s.filter((obj: any) => reducedIds.indexOf(obj[key]) === -1);
}

const CUSTOM_INPUT_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => FormMultiselectComponent),
	multi: true,
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => FormMultiselectComponent),
	multi: true,
};

@Component({
	selector: 'ft-form-multiselect',
	templateUrl: './form-multiselect.component.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
	styles: [
		`
			.chip {
				padding: 2px 6px !important;
			}
		`,
	],
})
export class FormMultiselectComponent
	implements Field, ControlValueAccessor, OnInit
{
	config: any;
	group: FormGroup;

	@Input() _value: any[] = [];
	@ViewChild('chipInput', { static: true }) chipInput: MatInput;

	get value(): any[] {
		return this._value;
	}

	set value(v: any[]) {
		this._value = v;
		this.onChange(this._value);
	}

	ngOnInit() {
		if (!this.group.controls[this.config.name].value) return;

		const vals = this.group.controls[this.config.name].value
			.split(', ')
			.map(v => {
				return { value: v };
			});

		const reducedIds = vals.map(o => o['value']);
		this._value = this.config.options.filter(
			(obj: any) => reducedIds.indexOf(obj['value']) !== -1
		);
	}

	onChange = (_: any): void => {
		// mock
	};

	onTouched = (_: any): void => {
		// mock
	};

	writeValue(v: any[]): void {
		this._value = v;
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	sourceFiltered(): any[] {
		return arrayDiffObj(this.config.options, this._value, 'value');
	}

	validate(c: FormControl): any {
		return this._value
			? undefined
			: {
					tinyError: {
						valid: false,
					},
				};
	}

	addSelected(event: MatAutocompleteSelectedEvent): void {
		const t: any = event.option.value;
		this._value.push(t);
		this.value = this._value;
		this.chipInput['nativeElement'].blur();

		this.group.controls[this.config.name].patchValue(
			this.value.map(v => v.value).join(', ')
		);
	}

	addNew(input: MatInput): void {
		// create a tmp id for interaction until the api has assigned a new one
		const newId: number =
			Math.floor(Math.random() * (100000 - 10000 + 1)) + 10000;
		const newItem: any = { id: newId, key: '', value: input.value };
		this._value.push(newItem);
		this.value = this._value;
		this.chipInput['nativeElement'].value = '';
		this.config.options.push(newItem);
	}

	remove(tag: any): void {
		this._value = this._value.filter((i: any) => i.value !== tag.value);
		this.value = this._value;
		this.chipInput['nativeElement'].blur();
	}

	displayFn(tag: any): string {
		return tag && typeof tag === 'object' ? tag.value : tag;
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our item
		if ((value || '').trim()) {
			const option = { id: null, key: '', value: value.trim() };

			this.config.options.push(option);
			this._value.push({ id: null, key: '', value: value.trim() });
			this.value = this._value;

			this.group.controls[this.config.name].patchValue(
				this.value.map(v => v.value).join(', ')
			);
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}
	}
}
