import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';

@Component({
	selector: 'ft-form-select',
	templateUrl: './form-select.component.html',
})
export class FormSelectComponent implements Field {
	config: any;
	group: FormGroup;
}
