<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-account"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'REFERRING_PHYSICIAN' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-layout-column fx-padding-0">
	<div class="fx-layout-row-nowrap">
		<div
			class="fx-layout-column fx-overflow-hidden"
			style="min-height: calc(60vh - 80px) !important">
			<div
				class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8">
				<mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
					<mat-icon
						matIconPrefix
						fontIcon="mdi-magnify"
						fontSet="mdi"></mat-icon>
					<input
						matInput
						#filter
						placeholder="{{ 'SEARCHING' | translate }}" />
				</mat-form-field>
			</div>

			<div class="fx-grow-1 fx-overflow-auto">
				<mat-table
					[dataSource]="dataSource"
					style="height: 280px"
					[trackBy]="trackByLastName"
					matSort
					matSortActive="lastName"
					matSortDirection="desc">
					<!--Table-->
					@for (col of displayedColumns; track col) {
						<ng-container
							[class.hidden]="col.hidden"
							matColumnDef="{{ col.label }}">
							<mat-header-cell *matHeaderCellDef mat-sort-header>
								{{ col.header | translate }}
								@if (
									col.unit !== 'date' && col.unit !== 'color'
								) {
									<span>{{ col.unit }}</span>
								}
							</mat-header-cell>

							@if (col.unit === 'date') {
								<mat-cell *matCellDef="let row">
									{{
										row[col.label] | date: 'dd/MM/yyyy'
									}}</mat-cell
								>
							}
							@if (col.unit !== 'date' && col.unit !== 'color') {
								<mat-cell *matCellDef="let row">
									{{ row[col.label] }}</mat-cell
								>
							}
							@if (col.unit === 'color') {
								<mat-cell *matCellDef="let row">
									<mat-icon
										[ngStyle]="{ color: row[col.label] }"
										fontIcon="mdi-checkbox-blank-circle"
										fontSet="mdi"></mat-icon>
								</mat-cell>
							}
						</ng-container>
					}

					<!--actions-->
					<ng-container matColumnDef="action">
						<mat-header-cell *matHeaderCellDef></mat-header-cell>
						<mat-cell
							*matCellDef="let row"
							class="fx-layout-row-nowrap fx-content-end fx-items-center">
							<button
								(click)="
									$event.stopPropagation();
									editReferringPhysician(row)
								"
								[matTooltip]="'EDIT' | translate"
								mat-icon-button>
								<mat-icon
									fontIcon="mdi-pencil"
									fontSet="mdi"></mat-icon>
							</button>
						</mat-cell>
					</ng-container>

					<!--header-->
					<mat-header-row
						*matHeaderRowDef="cols; sticky: true"></mat-header-row>
					<mat-row
						[@rowsAnimation]=""
						(click)="selectPhysician(row)"
						*matRowDef="let row; columns: cols">
					</mat-row>
				</mat-table>

				<div
					[style.display]="dataSource.data.length === 0 ? '' : 'none'"
					class="fx-table-empty"
					style="
						height: calc(60vh - 218px) !important;
						align-items: center;
					">
					@if (isLoadingResults) {
						<div>
							<mat-spinner
								[diameter]="50"
								[strokeWidth]="3"
								color="warn"></mat-spinner>
						</div>
					} @else {
						{{ 'NOITEMSFOUND' | translate }}
					}
				</div>
			</div>
			<mat-paginator
				[length]="resultsLength"
				[pageIndex]="0"
				[pageSizeOptions]="[5, 10, 25, 50, 100]"
				[pageSize]="25"
				[showFirstLastButtons]="true">
			</mat-paginator>
		</div>
		<div id="sidenav">
			<div [formGroup]="referringForm">
				<div class="fx-layout-row-nowrap fx-gap-4">
					<mat-form-field>
						<mat-label>{{ 'LAST_NAME' | translate }}</mat-label>
						<input
							matInput
							[placeholder]="'LAST_NAME' | translate"
							formControlName="lastName"
							required />
					</mat-form-field>
					<mat-form-field>
						<mat-label>{{ 'FIRST_NAME' | translate }}</mat-label>
						<input
							matInput
							[placeholder]="'FIRST_NAME' | translate"
							formControlName="firstName"
							required />
					</mat-form-field>
				</div>
				<div class="fx-layout-row-nowrap fx-gap-4">
					<mat-form-field>
						<mat-label>{{ 'TITLE' | translate }}</mat-label>
						<mat-select
							[placeholder]="'TITLE' | translate"
							formControlName="titleId"
							tabindex="-1">
							@for (title of titles; track title) {
								<mat-option [value]="title.id">{{
									title.value
								}}</mat-option>
							}
						</mat-select>
					</mat-form-field>
					<mat-form-field>
						<mat-label>{{ 'SPECIALITY' | translate }}</mat-label>
						<mat-select
							[placeholder]="'SPECIALITY' | translate"
							formControlName="specialtyId">
							@for (
								speciality of specialities;
								track speciality
							) {
								<mat-option [value]="speciality.id"
									>{{ speciality.value }}
								</mat-option>
							}
						</mat-select>
					</mat-form-field>
				</div>

				<mat-form-field>
					<mat-label>{{ 'CODE_PRO' | translate }}</mat-label>
					<input
						[placeholder]="'CODE_PRO' | translate"
						formControlName="codeAnam"
						matInput />
				</mat-form-field>

				<div class="fx-layout-row-nowrap fx-gap-4">
					<mat-form-field>
						<mat-label>{{ 'PHONE' | translate }}</mat-label>
						<input
							matInput
							[placeholder]="'PHONE' | translate"
							formControlName="phone" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>{{ 'EMAIL' | translate }}</mat-label>
						<input
							matInput
							[placeholder]="'EMAIL' | translate"
							formControlName="email" />
					</mat-form-field>
				</div>

				<mat-form-field class="fx-grow-1">
					<mat-label>{{ 'ADDRESS' | translate }}</mat-label>
					<textarea
						matInput
						[placeholder]="'ADDRESS' | translate"
						formControlName="primaryAddress"></textarea>
				</mat-form-field>

				<div
					class="fx-layout-row fx-content-end fx-items-center fx-gap-8">
					<button mat-button color="warn" (click)="toggleSidenav()">
						{{ 'CANCEL' | translate }}
					</button>
					<button
						mat-button
						color="primary"
						[disabled]="referringForm.invalid"
						(click)="saveReferringPhysician(referringForm.value)">
						{{ 'SAVE' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div matDialogActions align="end">
	<button color="warn" mat-raised-button matDialogClose>
		{{ 'CLOSE' | translate }}
	</button>
</div>
