import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'ft-menu',
	templateUrl: './ft-menu.component.html',
	styleUrls: ['./ft-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FtMenuComponent {
	@Output() menuItemSelect = new EventEmitter();

	private _listItem: MenuItem[];
	private _menuType!: string;
	currentCalendar = 'RDV';

	@Input()
	set listItem(listItem: MenuItem[]) {
		this._listItem = listItem;
	}

	get listItem(): MenuItem[] {
		return this._listItem;
	}

	@Input()
	set menuType(menuType: string) {
		this._menuType = menuType;
	}

	get menuType(): string {
		return this._menuType;
	}

	constructor() {
		this.menuItemSelect.subscribe(value => {
			if (this.menuType === 'calendar') {
				this.currentCalendar = value;
			}
		});
	}
}

export interface MenuItem {
	icon: string;
	name: string;
	value: string;
}
