import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'ft-print-count',
	templateUrl: './print-count.component.html',
	styleUrls: ['./print-count.component.scss'],
})
export class PrintCountComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public count: any = 1) {}
}
