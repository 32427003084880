export const PROCEDURE_CODE_TABLE_CONFIG = [
	{
		label: 'code',
		header: 'CODE',
		value: 'code',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'description',
		header: 'DESCRIPTION',
		value: 'description',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'billingCode',
		header: 'BILLING_CODE',
		value: 'billingCode',
		type: 'string',
		unit: null,
		defaultValue: '0',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'duration',
		header: 'DURATION',
		value: 'duration',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'pharmaceutical',
		header: 'PHARMACEUTICAL',
		value: 'pharmaceutical',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'reasonForExamValue',
		header: 'PROCEDURE_TYPE',
		value: 'reasonForExamValue',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
];
