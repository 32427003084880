import { Component, Inject, OnInit } from '@angular/core';
import { Technique } from '../../model';
import { SharedService } from '../shared.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'ft-technique-edit',
	templateUrl: './technique-edit.component.html',
	styleUrls: ['./technique-edit.component.scss'],
})
export class TechniqueEditComponent implements OnInit {
	form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Technique,
		private fb: FormBuilder,
		private sharedService: SharedService,
		private dialogRef: MatDialogRef<TechniqueEditComponent>
	) {
		this.form = this.fb.group(new Technique());
	}

	ngOnInit(): void {
		this.form.patchValue(this.data || new Technique());
	}

	onSave(value: Technique) {
		this.sharedService.createTechnique(value).subscribe(res => {
			this.dialogRef.close(res);
		});
	}
}
