export const RESOURCE_TYPES: any[] = [
	{
		icon: 'mdi-seat-flat',
		name: 'AET',
		value: 'aet',
	},
	{
		icon: 'mdi-door',
		name: 'ROOM',
		value: 'room',
	},
	{
		icon: 'mdi-account-multiple-check',
		name: 'PERFORMING_PHYSICIAN',
		value: 'physician',
	},
];

export const CALENDAR_TYPES: any[] = [
	{
		icon: 'mdi-seat-flat',
		name: 'RDV',
		value: 'RDV',
	},
	{
		icon: 'mdi-door',
		name: 'EXAM',
		value: 'EXAM',
	},
];
