import { NestedTreeControl } from '@angular/cdk/tree';
import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { ReportingService } from '../../reporting/reporting.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { StudyAvailability, StudyItem } from '../../model';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { StudyViewerComponent } from '../study-viewer/study-viewer.component';
import { sortBy } from 'lodash';

@Component({
	selector: 'ft-study-explorer',
	templateUrl: './study-explorer.component.html',
	styleUrls: ['./study-explorer.component.scss'],
})
export class StudyExplorerComponent implements OnChanges {
	@Input() patientID: string;
	@Input() pacsPatientID: string;

	treeControl = new NestedTreeControl<StudyItem>(node => node.studyItems);
	dataSource = new MatTreeNestedDataSource<StudyItem>();

	hasChild = (_: number, node: StudyItem) =>
		node.studyItems && node.studyItems.length > 0;
	@Output() keyImageSelection: EventEmitter<any> = new EventEmitter();

	private _service = inject(ReportingService);
	private _dialog = inject(MatDialog);

	ngOnChanges(changes: SimpleChanges) {
		const { patientID } = changes;
		if (patientID?.currentValue)
			this.getPatientStudies(patientID?.currentValue);
	}

	private getPatientStudies(patientID: string): void {
		this._service
			.getPatientStudies(patientID)
			.pipe(first())
			.subscribe(data => {
				this.dataSource.data = data.map(
					it =>
						new StudyItem(
							it.procedureCode,
							'',
							it.pacsPatientID,
							it.studyInstanceUID,
							it.studyAvailability,
							sortBy(it.studyItems, ['name']),
							it.studyDateTime,
							true,
							0
						)
				);
			});
	}

	public retrieveImagesIfNotAvailable(node: StudyItem) {
		node['loading'] = true;
		if (node.studyAvailability === StudyAvailability.AVAILABLE)
			this._service
				.retrieveStudy(node.studyInstanceUID)
				.pipe(first())
				.subscribe(res => {
					setTimeout(() => {
						node['loading'] = false;
						if (res === 0) {
							node['error'] = false;
							this.getPatientStudies(this.patientID);
						} else node['error'] = true;
					}, 2000);
				});
	}

	cleanAndRetrieve(node: StudyItem) {
		node['loading'] = true;
		this.treeControl.collapse(node);
		this._service
			.cleanAndRetrieveStudy(node.studyInstanceUID, node.pacsPatientID)
			.pipe(first())
			.subscribe(res => {
				setTimeout(() => {
					node['loading'] = false;
					if (res === 0) {
						node['error'] = false;
						this.getPatientStudies(this.patientID);
					} else node['error'] = true;
				}, 2000);
			});
	}

	isImageContainer(node: StudyItem): boolean {
		return (
			node.studyItems?.length && !!node.studyItems?.find(it => !it.folder)
		);
	}

	openImage(node: StudyItem) {
		const images = this.dataSource.data
			.find(it => it.studyInstanceUID === node.studyInstanceUID)
			.studyItems.map(it => it.studyItems)
			.reduce((acc, others) => [...acc, ...others], []);

		this._dialog
			.open(StudyViewerComponent, {
				data: {
					images: images.map(it => {
						return {
							name: it.name,
							url: '/' + it.url,
						};
					}),
					selected: {
						name: node.name,
						url: '/' + node.url,
					},
				},
				minWidth: '100vw',
				minHeight: '100vh',
			})
			.afterClosed()
			.subscribe(kos => {
				this.keyImageSelection.emit(kos);
			});
	}
}
