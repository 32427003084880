<div class="fx-padding-16">
	<div
		class="fx-layout-row fx-content-space-between fx-items-stretch fx-gap-12">
		<div class="fx-grow-1">
			@if (dataSource.data) {
				<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
					<!-- This is the tree node template for leaf nodes -->
					<mat-tree-node
						*matTreeNodeDef="let node"
						matTreeNodeToggle
						[class.image-node]="!node.folder"
						style="position: relative">
						<button mat-icon-button disabled>
							@if (node.folder) {
								<mat-icon
									fontSet="mdi"
									[fontIcon]="'mdi-folder'"></mat-icon>
							}
						</button>
						@if (!node.folder) {
							<button
								style="position: absolute; left: 2px; top: 2px"
								mat-icon-button
								(click)="selectImage(node)">
								<mat-icon
									[fontIcon]="
										isSelected(node)
											? 'mdi-check-circle'
											: 'mdi-circle-outline'
									"
									[style.color]="'cyan'"
									fontSet="mdi"></mat-icon>
							</button>
							<img
								height="72px"
								width="72px"
								[src]="node.url"
								[alt]="node.name"
								(click)="selectImage(node)" />
						} @else {
							<div
								class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4"
								[style.width.%]="100">
								<div
									class="fx-grow-1 fx-layout-column fx-content-start fx-items-start">
									<div
										class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-12">
										<span>{{ node.name }}</span>
										@if (
											pacsPatientID === node.pacsPatientID
										) {
											<span
												style="
													font-style: italic;
													font-size: 12px;
												"
												>~{{
													'CURRENT' | translate
												}}</span
											>
										}
									</div>
									<span
										style="
											font-size: 11px;
											font-style: italic;
											font-weight: 500;
										"
										>{{
											node.studyDateTime
												| date: 'dd/MM/yyyy'
										}}</span
									>
								</div>

								@if (node.loading) {
									<mat-progress-bar
										color="accent"
										class="fx-grow-1"
										mode="indeterminate"></mat-progress-bar>
								}
								@if (node.studyAvailability === 'AVAILABLE') {
									<button
										mat-icon-button
										[matTooltip]="'DOWNLOAD' | translate"
										(click)="
											retrieveImagesIfNotAvailable(node)
										">
										<mat-icon
											class="download-icon-gradient"
											fontSet="mdi"
											fontIcon="mdi-cloud-download"></mat-icon>
									</button>
								}
							</div>
						}
						<ng-template #name>
							<div
								class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4"
								[style.width.%]="100">
								<div
									class="fx-grow-1 fx-layout-column fx-content-start fx-items-start">
									<div
										class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-12">
										<span>{{ node.name }}</span>
										@if (
											pacsPatientID === node.pacsPatientID
										) {
											<span
												style="
													font-style: italic;
													font-size: 12px;
												"
												>~{{
													'CURRENT' | translate
												}}</span
											>
										}
									</div>
									<span
										style="
											font-size: 11px;
											font-style: italic;
											font-weight: 500;
										"
										>{{
											node.studyDateTime
												| date: 'dd/MM/yyyy'
										}}</span
									>
								</div>

								@if (node.loading) {
									<mat-progress-bar
										color="accent"
										class="fx-grow-1"
										mode="indeterminate"></mat-progress-bar>
								}
								@if (node.studyAvailability === 'AVAILABLE') {
									<button
										mat-icon-button
										[matTooltip]="'DOWNLOAD' | translate"
										(click)="
											retrieveImagesIfNotAvailable(node)
										">
										<mat-icon
											class="download-icon-gradient"
											fontSet="mdi"
											fontIcon="mdi-cloud-download"></mat-icon>
									</button>
								}
							</div>
						</ng-template>
					</mat-tree-node>

					<!-- This is the tree node template for expandable nodes -->
					<mat-nested-tree-node
						*matTreeNodeDef="let node; when: hasChild"
						[style.paddingLeft.px]="node.level === 0 ? 0 : 24">
						<div class="mat-tree-node">
							<button
								mat-icon-button
								[attr.aria-label]="'Toggle ' + node.name"
								matTreeNodeToggle>
								<mat-icon
									class="list-icon-gradient"
									fontSet="mdi"
									[fontIcon]="
										treeControl.isExpanded(node)
											? 'mdi-folder-open'
											: 'mdi-folder'
									"></mat-icon>
							</button>
							<div
								class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4"
								style="position: relative"
								[style.width.%]="100">
								<div
									class="fx-grow-1 fx-layout-column fx-content-start fx-items-center">
									<div
										class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-12">
										<span style="font-weight: 500">{{
											node.name
										}}</span>
										@if (
											pacsPatientID ===
												node.pacsPatientID &&
											node.level === 0
										) {
											<span
												style="
													font-style: italic;
													font-size: 12px;
												"
												>~{{
													'CURRENT' | translate
												}}</span
											>
										}
									</div>
									<span
										style="
											font-size: 11px;
											font-style: italic;
											font-weight: 500;
										"
										>{{
											node.studyDateTime
												| date: 'dd/MM/yyyy'
										}}</span
									>
								</div>
								@if (node.loading && node.level === 0) {
									<mat-progress-bar
										color="accent"
										class="fx-grow-1"
										mode="indeterminate"></mat-progress-bar>
								}
								@if (node.level !== 0) {
									<button
										style="position: absolute; right: 0"
										mat-icon-button
										(click)="selectAll(node)">
										<mat-icon
											[fontIcon]="
												isAllSelected(node)
													? 'mdi-check-circle'
													: 'mdi-circle-outline'
											"
											[style.color]="'#ff0099'"
											fontSet="mdi"></mat-icon>
									</button>
								}
								@if (node.level === 0) {
									<button
										mat-icon-button
										[matTooltip]="'REFRESH' | translate"
										(click)="cleanAndRetrieve(node)">
										<mat-icon
											[style.color]="'#11a0ac'"
											fontSet="mdi"
											fontIcon="mdi-refresh-circle"></mat-icon>
									</button>
								}
							</div>
						</div>
						<div
							[style.display]="
								!treeControl.isExpanded(node) ? 'none' : 'grid'
							"
							[class.images-grid]="isImageContainer(node)">
							<ng-container matTreeNodeOutlet></ng-container>
						</div>
					</mat-nested-tree-node>
				</mat-tree>
			} @else {
				<div
					[style.height.%]="100"
					class="fx-layout-column fx-content-center fx-items-center fx-gap-16">
					<span style="font-size: 14px; font-weight: bold">{{
						'RETRIEVING_IMAGES' | translate
					}}</span>
					<mat-spinner
						[diameter]="100"
						[strokeWidth]="5"
						color="warn"></mat-spinner>
				</div>
			}
		</div>

		<div class="fx-grow-1 fx-layout-column-nowrap fx-gap-8">
			<div
				class="fx-layout-row-nowrap fx-content-space-between fx-items-end fx-gap-8">
				<mat-form-field style="width: 120px" subscriptSizing="dynamic">
					<mat-label>{{ 'LAYOUT' | translate }}</mat-label>
					<mat-select
						[formControl]="form"
						[placeholder]="'LAYOUT' | translate">
						@for (layout of layouts; track layout) {
							<mat-option [value]="layout">{{
								layout
							}}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				<mat-form-field style="width: 100px" subscriptSizing="dynamic">
					<mat-label>{{ 'PRINT_COUNT' | translate }}</mat-label>
					<input
						[(ngModel)]="numberOfCopies"
						inputmode="numeric"
						matInput
						style="font-weight: bold; text-align: right"
						type="number" />
				</mat-form-field>

				<span class="fx-grow-1"></span>
				<div class="fx-layout-column-nowrap">
					<h5 style="margin: 0 8px">
						{{ 'IMAGE_BACKGROUND' | translate }}
					</h5>
					<mat-radio-group
						[(ngModel)]="imageBackground"
						[ngModelOptions]="{ standalone: true }"
						class="fx-layout-row-nowrap fx-content-start fx-items-end">
						@for (option of ['BLACK', 'WHITE']; track option) {
							<mat-radio-button
								class="fx-grow-1"
								[value]="option">
								{{ option | translate }}
							</mat-radio-button>
						}
					</mat-radio-group>
				</div>
				<span class="fx-grow-1"></span>
				<div class="fx-layout-column-nowrap">
					<h5 style="margin: 0 8px">
						{{ 'PRINT_OPTION' | translate }}
					</h5>
					<mat-radio-group
						[(ngModel)]="printOption"
						[ngModelOptions]="{ standalone: true }"
						class="fx-layout-row-nowrap fx-content-start fx-items-end">
						@for (
							option of [
								'REPORT_ONLY',
								'IMAGES_ONLY',
								'BOTH',
								'SIMPLE_A4'
							];
							track option
						) {
							<mat-radio-button
								[value]="option"
								class="fx-grow-1">
								{{ option | translate }}
							</mat-radio-button>
						}
					</mat-radio-group>
				</div>

				<button
					[matTooltip]="'CLOSE' | translate"
					color="warn"
					matDialogClose
					mat-icon-button
					matTooltipPosition="left"
					style="position: absolute; top: 4px; right: 8px">
					<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
				</button>
			</div>

			<div class="layout-container">
				<button
					(click)="linkOrUnlinkAll()"
					class="tool-btn link-btn"
					mat-mini-fab>
					<mat-icon
						style="color: #00dbf1"
						[fontIcon]="isAllLinked ? 'mdi-link' : 'mdi-link-off'"
						fontSet="mdi"></mat-icon>
				</button>

				@for (array of imagesArray; track array) {
					<ft-print-layout
						[imageBackground]="imageBackground.toLowerCase()"
						[printLayout]="selectedLayout"
						[selectedImages]="array"></ft-print-layout>
				}
				<div class="total-pages">
					{{ 'TOTAL_PAGES' | translate }} {{ imagesArray.length }}
				</div>
			</div>
			<div
				class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4"
				style="
					position: absolute;
					z-index: 9;
					bottom: 24px;
					right: 24px;
				">
				@switch ((generalSetting | async)?.reportPrintMode) {
					@case ('CUPS') {
						<button
							[matMenuTriggerFor]="printersMenu"
							color="primary"
							mat-mini-fab>
							<mat-icon
								fontIcon="mdi-printer"
								fontSet="mdi"></mat-icon>
						</button>
					}
					@default {
						<button
							(click)="chromePrint()"
							color="primary"
							mat-mini-fab>
							<mat-icon
								fontIcon="mdi-printer"
								fontSet="mdi"></mat-icon>
						</button>
					}
				}

				<mat-menu #printersMenu="matMenu">
					@for (printer of printers; track printer) {
						<button
							(click)="validate(printer['label'])"
							mat-menu-item>
							<mat-icon
								fontIcon="mdi-printer"
								fontSet="mdi"></mat-icon>
							{{ printer['label'] }}
						</button>
					}
				</mat-menu>
			</div>
		</div>
	</div>
</div>
