<div
	class="speech-container fx-layout-column fx-content-space-between fx-gap-12">
	<div class="speech-bar">
		<input
			type="text"
			id="note-name"
			[placeholder]="('TITLE' | translate) + '...'"
			#noteName />
	</div>

	<div
		id="result"
		class="text-result fx-grow-1"
		contenteditable
		(keydown)="clearInput($event)">
		<span class="placeholder">Note ici...</span>
	</div>

	<div
		class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4">
		@if (!data) {
			<button
				mat-mini-fab
				[disabled]="recording.getValue()"
				(click)="startSpeak()"
				[matTooltip]="'START_SPEECH' | translate">
				<mat-icon fontSet="mdi" fontIcon="mdi-microphone"></mat-icon>
			</button>
		}
		<span class="fx-grow-1"></span>
		@if (!data) {
			<button mat-raised-button color="primary" (click)="save()">
				{{ 'SAVE' | translate }}
			</button>
		}
		<button mat-raised-button color="warn" matDialogClose>
			{{ 'CLOSE' | translate }}
		</button>
	</div>
</div>
