<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-printer-eye"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'PRINTING_HISTORY' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>
<div class="fx-padding-16">
	@if (docsPrintingCounts?.length > 1) {
		<div class="printing-summary fx-layout-column-nowrap">
			<div class="header fx-grow-1">
				{{ 'PRINTED_COUNT' | translate }}
			</div>
			@for (item of docsPrintingCounts; track item) {
				<div
					class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4">
					<div style="width: 70%">{{ item.doc | translate }}:</div>
					<div style="width: 20%">{{ item.count || 0 }}</div>
				</div>
			}
		</div>
	}

	@if (printingHistory?.length > 0) {
		<div class="details">{{ 'DETAILS' | translate }}</div>

		<div
			[ngStyle]="{ maxHeight: '240px', overflow: 'auto' }"
			class="mat-elevation-z3">
			<div class="ft-row ft-header">
				<div class="ft-cell">{{ 'DATE' | translate }}</div>
				<div class="ft-cell">{{ 'DOCUMENT' | translate }}</div>
				<div class="ft-cell">{{ 'USER' | translate }}</div>
			</div>
			@for (item of printingHistory; track item) {
				<div class="ft-row">
					<div class="ft-cell">
						{{ item.dateTime | date: 'dd/MM/yyyy HH:mm' }}
					</div>
					<div class="ft-cell">
						{{ item.targetDocument | translate }}
					</div>
					<div class="ft-cell">{{ item.userFullName }}</div>
				</div>
			}
		</div>
	}
</div>
