<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-barcode-scan"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'ARTICLE_SERIALS' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-0 fx-overflow-auto" style="max-height: 60vh">
	<div
		class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8">
		<mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
			<mat-icon
				matIconPrefix
				fontIcon="mdi-magnify"
				fontSet="mdi"></mat-icon>
			<input
				matInput
				#search
				placeholder="{{ 'SEARCHING' | translate }}" />
		</mat-form-field>
	</div>

	<div class="limiter">
		<div class="table">
			<div class="row header">
				<div class="cell">
					{{ 'CODE' | translate }}
				</div>
				<div
					[style.color]="
						selection.selected.length === neededQuantity
							? 'green'
							: 'red'
					"
					[style.width.px]="24"
					class="cell"
					style="
						text-align: right;
						font-weight: bold;
						padding-right: 4px;
						font-size: 14px;
					">
					{{ selection.selected.length }}/{{ neededQuantity }}
				</div>
			</div>

			@for (serial of serials; track serial) {
				<div
					(click)="selectSerial(serial)"
					[class.row-selected]="selection.isSelected(serial)"
					[style.cursor]="'pointer'"
					class="row">
					<div class="cell">{{ serial }}</div>

					<div
						[style.textAlign]="'right'"
						[style.width.px]="24"
						class="cell">
						<mat-icon
							[fontIcon]="
								selection.isSelected(serial) ? 'mdi-check' : ''
							"
							[style.color]="'green'"
							[style.marginRight.px]="8"
							[style.top.px]="0"
							fontSet="mdi"></mat-icon>
					</div>
				</div>
			}

			@if (!serials.length) {
				<div class="row">
					<div class="no-cell">No serial number found</div>
				</div>
			}
		</div>
	</div>
</div>
<div matDialogActions align="end">
	<button color="warn" mat-raised-button matDialogClose="">
		{{ 'EXIT' | translate }}
	</button>
	<button
		(click)="validateSelection()"
		[disabled]="!selection.hasValue()"
		color="primary"
		mat-raised-button>
		{{ 'SAVE' | translate }}
	</button>
</div>
