import { Pipe, PipeTransform } from '@angular/core';
import numeral from 'numeral';

@Pipe({
	name: 'numeric',
})
export class NumericPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		const [{ numberFormat, comma }] = args;
		return numeral(value).format(comma ? numberFormat : '0,0');
	}
}
