import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingService } from '../../setting/setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'ft-new-template',
	templateUrl: './new-template.component.html',
	styleUrls: ['./new-template.component.scss'],
})
export class NewTemplateComponent implements OnInit {
	categories: any[] = [];

	form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fb: FormBuilder,
		private _service: SettingService,
		private _snack: MatSnackBar,
		private _dialogRef: MatDialogRef<NewTemplateComponent>
	) {
		this.form = this._fb.group({
			name: ['', Validators.required],
			reportModel: '',
			category: ['', Validators.required],
			listType: 'STD',
			isPrivate: false,
			owner: '',
		});
	}

	ngOnInit(): void {
		this._service
			.getTemplateModelCategories()
			.subscribe(data => (this.categories = data));
	}

	saveReportTemplate(value: any) {
		this._service
			.createTemplateModelFromReport(value, this.data)
			.subscribe(res => {
				if (res) {
					this._dialogRef.close();
					this._snack.open('Report template saved!', '', {
						duration: 2000,
					});
				}
			});
	}
}
