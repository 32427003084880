<div mat-dialog-title></div>
<div mat-dialog-content>
	<div class="fx-layout-row fx-content-start fx-items-start fx-gap-4">
		@for (icon of icons; track icon) {
			<mat-icon
				(click)="selectedIcon = icon"
				[class.selected]="icon === selectedIcon"
				[fontIcon]="icon"
				[matTooltip]="icon"
				fontSet="mdi"></mat-icon>
		}
	</div>
</div>
<div align="end" mat-dialog-actions>
	<button [matDialogClose]="null" color="warn" mat-stroked-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button [matDialogClose]="selectedIcon" color="primary" mat-stroked-button>
		<b>{{ 'APPLY' | translate }}</b>
	</button>
</div>
