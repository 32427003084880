import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { ReportingService } from '../../reporting/reporting.service';
import {
	ClosePlugin,
	ContentControl,
	InitAutocompleteDictionary,
	InsertVariable,
	PLUGINS_GUIDS,
	SearchAndReplace,
} from './utils';
import { SharedService } from '../shared.service';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ReportParagraph } from '../../model';
import { NewTemplateComponent } from '../new-template/new-template.component';
import { MatDialog } from '@angular/material/dialog';
import { WsService } from '../../ws.service';
import { getAppType } from '../shared-functions';
import { AppConfigService } from '../../app-config.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FireSharedModule } from '../fire-shared.module';

declare let DocsAPI: any;

@Component({
	selector: 'ft-reporter',
	standalone: true,
	templateUrl: 'reporter.component.html',
	imports: [MatButtonModule, MatIconModule, FireSharedModule],
	styleUrl: 'reporter.component.scss',
})
export class ReporterComponent
	implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
	private static isCompile: boolean;

	private editor: any;
	@Output() editorInitialized: EventEmitter<string> =
		new EventEmitter<string>();
	@Input() emptyReport?: string;
	@Input() fileId?: string;
	@Input() templateModel?: number;
	@Input() documentTitle?: string;
	@Input() editable?: boolean;
	@Input() studyInstanceUID: string;
	@Input() procedureType: string;
	@Input() procedureCode: string;
	@Input() approved: boolean;
	@Input() patientData: any;
	@Input() examData: any;
	@Input() templateMode?: number;
	@Input() ccData?: any;

	private documentKey: string;
	private users = {};
	private subs: Subscription[] = [];
	private reportId: any;
	searchingParagraphs: boolean;

	editorStopped: boolean = false;
	waitingSeconds: number = 60;
	private waitingInterval: any;
	readonly cvis: boolean;
	readonly paragraphAutocompleteEnabled: boolean;

	private static get uiTheme(): string {
		return JSON.parse(localStorage.getItem('theme')) || '';
	}

	constructor(
		private service: ReportingService,
		private shared: SharedService,
		private _config: AppConfigService,
		private _translate: TranslateService,
		private _ws: WsService,
		private _dialog: MatDialog
	) {
		this.cvis = getAppType(this._config.logo) === 'cvis';
		this.paragraphAutocompleteEnabled =
			this._config.paragraphAutocompleteEnabled;
		this.subs.push(
			this.service.variableInsert
				.asObservable()
				.pipe(debounceTime(250))
				.subscribe(this.insertVariables.bind(this))
		);

		this.subs.push(
			this._ws.observeTopic('editor').subscribe(res => {
				if (res.response === '1') {
					this.editorStopped = true;
					this.waitingInterval = setInterval(() => {
						if (this.waitingSeconds > 0) this.waitingSeconds -= 1;
					}, 1000);
				}
			})
		);
	}

	public onRefresh() {
		this.editorStopped = false;
		clearInterval(this.waitingInterval);
		this.waitingSeconds = 60;
	}

	private insertVariables(variable: string) {
		document['frameEditor'].postMessage(InsertVariable(variable), '*');
		if (variable.includes('QR_CODE')) {
			this.insertQrCode(variable);
		}
	}

	ngOnInit() {
		ReporterComponent.isCompile = this.templateMode === 0;
	}

	ngAfterViewInit() {
		this.subs.push(
			this.shared
				.getEditorUsers()
				.subscribe(data => (this.users['users'] = data))
		);
	}

	searchParagraphs() {
		document.getElementById('p-sidenav').classList.toggle('visible');
		this.searchingParagraphs = !this.searchingParagraphs;
	}

	ngOnChanges(changes: SimpleChanges) {
		const { fileId, templateModel, emptyReport, ccData } = changes;

		const user = JSON.parse(localStorage.getItem('user'));

		if (fileId && user) {
			const split = fileId.currentValue.split('_');

			const [reportId, templateId] = split;
			this.reportId = reportId;

			if (reportId === 'P') {
				const filename =
					split.length > 2
						? fileId.currentValue.replace('P_', '')
						: templateId;
				this.subs.push(
					this.service
						.getFileModel(
							'0',
							filename,
							'P',
							user.id,
							user.fullName,
							'{}'
						)
						.subscribe(value => this.openFile(value))
				);
			} else if (reportId != 'T') {
				this.subs.push(
					this.service
						.getFileModel(
							reportId,
							templateId,
							templateModel &&
								templateModel.firstChange &&
								emptyReport &&
								emptyReport.currentValue !== 'EMPTY'
								? 'R'
								: 'T',
							user.id,
							user.fullName,
							'{}'
						)
						.subscribe(value => this.openFile(value))
				);
			} else
				this.subs.push(
					this.service
						.getFileModel(
							'0',
							templateId,
							reportId,
							user.id,
							user.fullName,
							'{}'
						)
						.subscribe(value => this.openFile(value))
				);
		}
	}

	public openFile(config: any) {
		if (this.editor) this.editor.destroyEditor();

		const onOutdatedVersion = (event: any) => {
			console.log('Outdated version', event);
			location.reload();
		};

		const onError = (event: any) => {
			if (event) console.log(event.data);
		};

		const onRequestEditRights = () => {
			console.log('Request edit rights ..');
		};

		const onAppReady = () => {
			this.editorInitialized.emit(this.documentKey);
			setTimeout(() => {
				if (ReporterComponent.isCompile) this.compileVariables();
			}, 3000);
		};

		const onDocumentStateChange = (_: any) => {
			if (ReporterComponent.isCompile) this.compileVariables();
		};
		const onInfo = (data: any) => {
			if (data && data.data && data.data.getConfig) {
				console.log('Getting config...');
				this.editor.serviceCommand('getConfig', config.document);
			}
		};
		const onRequestUsers = () => {
			this.editor.setUsers(this.users);
		};
		const onRequestHistory = () =>
			this.subs.push(
				this.service
					.getReportHistory(this.reportId)
					.subscribe(history => {
						console.log('history ', history);
						this.editor.refreshHistory(history);
					})
			);
		const onRequestRestore = (event: any) => {
			const version = event.data.version;
			this.subs.push(
				this.service
					.restoreVersion(this.reportId, version)
					.subscribe(onRequestHistoryClose)
			);
		};
		const onRequestHistoryData = (event: any) => {
			const ver = event.data;
			this.subs.push(
				this.service.getReportHistoryData(ver).subscribe(data => {
					console.log('history data ', data);
					this.editor.setHistoryData(data);
				})
			);
		};
		const onRequestHistoryClose = () => document.location.reload();
		const onRequestCreateNew = (ev: any) => {
			console.log(ev);
		};

		config.events = {
			onAppReady: onAppReady,
			onDocumentStateChange: onDocumentStateChange,
			onRequestEditRights: onRequestEditRights,
			onError: onError,
			onOutdatedVersion: onOutdatedVersion,
			onInfo: onInfo,
			onRequestUsers: onRequestUsers,
			// onRequestHistory: onRequestHistory,
			// onRequestHistoryData: onRequestHistoryData,
			// onRequestHistoryClose: onRequestHistoryClose,
			onRequestRestore: onRequestRestore,
			onRequestCreateNew: onRequestCreateNew,
			onPluginsReady: () => {
				if (this.cvis)
					setTimeout(() => this.compileContentControls(), 2000);
				if (this.paragraphAutocompleteEnabled)
					setTimeout(
						() =>
							this.subs.push(
								this.service
									.getTemplatesParagraphs()
									.subscribe(
										this.initEditorParagraphsDictionary
									)
							),
						2000
					);
			},
		};

		this.documentKey = config.document.key;

		if (this.documentTitle) config.document.title = this.documentTitle;

		config.mode = this.editable ? 'edit' : 'view';
		config.editorConfig.mode = config.mode;

		const uiTheme = ReporterComponent.uiTheme;

		config.editorConfig.customization.uiTheme = `theme-${uiTheme}`;

		this.editor = new DocsAPI.DocEditor('reporter', config);
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
		this.closePlugins();
		this.editor.destroyEditor();
	}

	private compileVariables() {
		if (ReporterComponent.isCompile) {
			const patientData = this.patientData;
			const examData = this.examData;

			if (patientData && examData) {
				const variablesData = [...patientData, ...examData];
				variablesData.forEach(it =>
					document['frameEditor'].postMessage(SearchAndReplace(it.key, it.value), '*')
				);
			}
		}
	}

	private initEditorParagraphsDictionary(data: any) {
		document['frameEditor'].postMessage(
			InitAutocompleteDictionary(data),
			'*'
		);
	}

	public compileContentControls(): void {
		if (ReporterComponent.isCompile && this.ccData)
			document['frameEditor'].postMessage(
				ContentControl('replaceAllContentControlsTag', {
					values: this.ccData,
				}),
				'*'
			);
	}

	public replaceContentControlWithTag(fieldTag: any) {
		document['frameEditor'].postMessage('replaceContentControlTag', {
			tag: fieldTag.tag,
			value: fieldTag.value,
		});
	}

	public addContentControlWithTag(ccTag: string) {
		document['frameEditor'].postMessage('addContentControlWithTag', {
			tag: ccTag,
		});
	}

	private closePlugins() {
		Object.keys(PLUGINS_GUIDS).forEach(key =>
			document['frameEditor'].postMessage(ClosePlugin(key), '*')
		);
	}

	private insertQrCode(variable: any) {
		this.subs.push(
			this.service.getQrCode(this.studyInstanceUID).subscribe(value => {
				this.editor.insertImage({
					c: 'add',
					fileType: 'png',
					url: value.qr_code,
				});

				setTimeout(() =>
					document['frameEditor'].postMessage(
						SearchAndReplace(
							variable,
							`${this._translate.instant('LINK')}: ${value.external_url} ▬ ${this._translate.instant('PASSWORD')}: ${value.password}`
						),
						'*'
					)
				);
			})
		);
	}

	insertParagraph(paragraph: ReportParagraph) {
		const p = paragraph.text.toString();
		document['frameEditor'].postMessage(
			InsertVariable(String(p).split('\n').join(' ')),
			'*'
		);
	}

	saveReportAsTemplate() {
		this.subs.push(
			this._dialog
				.open(NewTemplateComponent, { data: this.reportId })
				.afterClosed()
				.subscribe(value => console.log(value))
		);
	}
}
