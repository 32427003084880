import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'initials',
})
export class InitialsPipe implements PipeTransform {
	transform(value: string, ...args: any[]): string {
		if (!value) return '00';
		const sp = value.split(' ');
		if (sp.length > 1) {
			return `${sp[0].charAt(0)}${sp[1].charAt(0)}`.toUpperCase();
		}
		return value.substr(0, 2);
	}
}
