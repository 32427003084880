import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import {
	CommandService,
	DictationDTO,
	EmailMessage,
	LabelDTO,
	PatientStudy,
	ReportingTask,
	ReportParagraph,
} from '../model';
import { SortDirection } from '@angular/material/sort';
import {
	EXAM_FORMS_URL,
	FORMS_URL,
	MAIL_URL,
	OFFICE_COAUTHORING_URL,
	OFFICE_URL,
	PACS_URL,
	PORTAL_URL,
	PRINTER_URL,
	REPORTING_URL,
	SHARED_URL,
	WORKFLOW_URL,
} from '../urls';
import { map, tap } from 'rxjs/operators';
import { downloadFile, printBlob } from '../utils';
import { ReportingTaskDetailsDTO } from '../model';

@Injectable()
export class ReportingService {
	public keyboardEventSubject = new Subject<KeyboardEvent>();
	variableInsert = new Subject<string>();

	private _http = inject(HttpClient);

	getReportHistory = (id: string): Observable<any> =>
		this._http.get(`${OFFICE_URL}/history`, { params: { id } });

	getPatientInfo(patientID: string): Observable<any> {
		const params = { patientID };
		return this._http.get(`${REPORTING_URL}/patientReportInfo`, { params });
	}

	getReportingTask = (id: number): Observable<ReportingTask> =>
		this._http.get<ReportingTask>(`${REPORTING_URL}/reportingTask/${id}`);

	getReportingTaskDetails = (
		id: number
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.get<ReportingTaskDetailsDTO>(`${REPORTING_URL}/${id}`);

	saveDictation = (dictation: DictationDTO): Observable<DictationDTO> =>
		this._http.post<DictationDTO>(
			REPORTING_URL + '/saveDictation',
			dictation
		);

	toTranscribeReportingTaskDTO = (
		reportingTask: ReportingTaskDetailsDTO
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.post<ReportingTaskDetailsDTO>(
			REPORTING_URL + '/toTranscribe',
			reportingTask
		);

	toValidateReportingTaskDTO = (
		reportingTask: ReportingTaskDetailsDTO
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.post<ReportingTaskDetailsDTO>(
			REPORTING_URL + '/toValidate',
			reportingTask
		);

	toReviewReportingTaskDTO = (
		reportingTask: ReportingTaskDetailsDTO
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.post<ReportingTaskDetailsDTO>(
			REPORTING_URL + '/toReview',
			reportingTask
		);

	toSignReportingTaskDTO = (
		reportingTask: ReportingTaskDetailsDTO
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.post<ReportingTaskDetailsDTO>(
			REPORTING_URL + '/toSign',
			reportingTask
		);

	printSimpleReport(
		reportingTaskId: number,
		printer: string,
		count: any,
		withLink: boolean = false
	): Observable<any> {
		const params = {
			reportingTaskId: String(reportingTaskId),
			printer,
			count,
			withLink,
		};
		return this._http
			.get(`${PRINTER_URL}/printSimpleReportChrome`, {
				params,
				responseType: 'blob',
			})
			.pipe(tap(blob => printBlob(blob)));
	}

	printCupsSimpleReport(
		reportingTaskId: number,
		printer: string,
		count: any = 1
	): Observable<any> {
		const params = {
			reportingTaskId: String(reportingTaskId),
			printer,
			count,
			printingMode: 'CUPS',
		};
		return this._http.get<any>(`${PRINTER_URL}/printSimpleReport`, {
			params,
		});
	}

	printReport(id: number): Observable<any> {
		return this._http
			.get(`${PRINTER_URL}/printReportChrome/${id}`, {
				responseType: 'blob',
			})
			.pipe(tap(blob => printBlob(blob)));
	}

	downloadReport(id: number, filename: string): Observable<any> {
		return this._http
			.get(`${PRINTER_URL}/printReportChrome/${id}`, {
				responseType: 'blob',
			})
			.pipe(tap(blob => downloadFile(blob, 'pdf', filename)));
	}

	printCupsReport(
		id: number,
		printer: string,
		count: any,
		printingMode: 'CUPS' | 'CHROME' = 'CUPS'
	): Observable<any> {
		const params = { printer, count, printingMode };
		return this._http.get(`${PRINTER_URL}/printReport/${id}`, { params });
	}

	getSPSByInstances(siuid: string): Observable<any> {
		const params = { siuid };
		return this._http.get(`${REPORTING_URL}/sps-by-siuid`, { params });
	}

	deleteReportingTask = (id): Observable<any> =>
		this._http.get(`${REPORTING_URL}/deleteReportingTask`, {
			params: { id: String(id) },
		});

	saveReportingTask = (
		reportingTask: ReportingTask
	): Observable<ReportingTask> =>
		this._http.post<ReportingTask>(
			`${REPORTING_URL}/saveReportingTask`,
			reportingTask
		);

	saveReportingTaskDTO = (
		reportingTask: ReportingTaskDetailsDTO
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.post<ReportingTaskDetailsDTO>(
			`${REPORTING_URL}/saveReportingTask`,
			reportingTask
		);

	sendMail = (mail: EmailMessage, id: number): Observable<any> =>
		this._http.post(`${MAIL_URL}/send/${id}`, mail);

	validateReportingTaskDTO = (
		reportingTask: ReportingTaskDetailsDTO
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.post<ReportingTaskDetailsDTO>(
			`${REPORTING_URL}/validateReport`,
			reportingTask
		);

	getReportTaskDTOs(
		pageSize: number,
		pageIndex: number,
		sort: string,
		direction: SortDirection,
		taskType: string,
		query: string
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${sort},${direction}`,
			taskType,
			query,
		};

		return this._http.get(`${REPORTING_URL}/`, { params });
	}

	signReportingTaskDTO = (
		reportingTask: ReportingTaskDetailsDTO
	): Observable<ReportingTaskDetailsDTO> =>
		this._http.post<ReportingTaskDetailsDTO>(
			`${REPORTING_URL}/signReport`,
			reportingTask
		);

	openWeasis = (studyInstanceUID: string): Observable<boolean> =>
		this._http.get<boolean>(`${PACS_URL}/study/viewer/${studyInstanceUID}`);

	printReportingTask = (id: number): Observable<Blob> =>
		this._http.get(`${REPORTING_URL}/print-report/${id}`, {
			responseType: 'blob',
		});

	openOsirix = (studyInstanceUID: string, viewer: string): Observable<any> =>
		this._http.get(
			`${PACS_URL}/study/osirix-viewer/${viewer}/${studyInstanceUID}`
		);

	getAllPatientReportingTasks(
		patientID: string,
		excludeTaskId: number
	): Observable<ReportingTaskDetailsDTO[]> {
		const params = { patientID, excludeTaskId };
		return this._http.get<ReportingTaskDetailsDTO[]>(
			`${REPORTING_URL}/patient-reporting-tasks`,
			{ params }
		);
	}

	launchOsirix = (
		aeTitle: string,
		patientID: string,
		studyInstanceUID: string
	) =>
		this._http.get(`${SHARED_URL}/launchOsirix`, {
			params: { aeTitle, patientID, studyInstanceUID },
		});

	retrieveStudy(studyInstanceUID: string) {
		const params = { studyInstanceUID };
		return this._http.get(`${PACS_URL}/retrieve`, { params });
	}

	deliverCompletedReport(id: any): Observable<boolean> {
		return this._http.get<boolean>(`${REPORTING_URL}/deliverReport`, {
			params: { id },
		});
	}

	saveNoteAlert(reportingTaskId: number, notes: string): Observable<any> {
		return this._http.post(
			`${REPORTING_URL}/${reportingTaskId}/note-alert`,
			{},
			{ params: { notes } }
		);
	}

	openStudy(
		studyInstanceUID: string,
		username: any,
		replace: any
	): Observable<any> {
		return this._http.get(`${PACS_URL}/openStudy`, {
			params: { studyInstanceUID, username, replace },
		});
	}

	closeViewerStudy(studyInstanceUID: string): Observable<any> {
		return this._http.get(`${PACS_URL}/closeStudy`, {
			params: { studyInstanceUID },
		});
	}

	getFileModel(
		reportId: string,
		templateModelId?: string,
		defaultSource?: string,
		uid?: string,
		uname?: string,
		actionLink?: string,
		docId?: string
	): Observable<any> {
		return this._http.get(`${OFFICE_URL}/files`, {
			params: {
				reportId,
				templateModelId: templateModelId || '',
				defaultSource: defaultSource || '',
				uid: uid || '',
				uname: uname || '',
				actionLink: actionLink || '',
				docId,
			},
		});
	}

	sendCmd(cmd: CommandService): Observable<any> {
		cmd.userdata = JSON.parse(localStorage.getItem('user')).id;
		return this._http.post(OFFICE_COAUTHORING_URL, cmd);
	}

	getReportHistoryData(version: any): Observable<any> {
		return this._http.get(`${OFFICE_URL}/history-data`, {
			params: { version },
		});
	}

	restoreVersion(reportId: any, version: any): Observable<any> {
		return this._http.get(`${OFFICE_URL}/restore-version`, {
			params: { reportId, version },
		});
	}

	getQrCode(studyInstanceUID: string): Observable<any> {
		return this._http
			.get(`${PACS_URL}/qr-code`, { params: { studyInstanceUID } })
			.pipe(map(res => (res ? res[0] : null)));
	}

	cleanAndRetrieveStudy(
		studyInstanceUID: string,
		patientID: string
	): Observable<any> {
		const params = { studyInstanceUID, patientID };
		return this._http.get(`${PACS_URL}/cleanAndRetrieve`, { params });
	}

	saveReportParagraph(paragraph: ReportParagraph): Observable<any> {
		return this._http.post(`${REPORTING_URL}/create-paragraph`, paragraph);
	}

	searchParagraphs(
		key: string,
		procedureCode: string,
		procedureType: string,
		pageSize: number,
		pageIndex: number,
		sort: string,
		direction: SortDirection
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${sort},${direction}`,
			key,
			procedureCode,
			procedureType,
		};

		return this._http.get(`${REPORTING_URL}/report-paragraphs`, { params });
	}

	deleteParagraph(id: number): Observable<any> {
		return this._http.delete(`${REPORTING_URL}/delete-paragraph/${id}`);
	}

	getReportText(reportingTaskId: number): Observable<any> {
		const params = { reportingTaskId };
		return this._http.get(`${REPORTING_URL}/getReportText`, { params });
	}

	getPatientStudies(patientID: string): Observable<PatientStudy[]> {
		return this._http.get<PatientStudy[]>(
			`${WORKFLOW_URL}/getPatientStudies`,
			{ params: { patientID } }
		);
	}

	sendHL7Report(row): Observable<any> {
		return this._http.post(`${REPORTING_URL}/sendHL7Report`, undefined, {
			params: { reportingTaskId: row.reportingTaskId },
		});
	}

	getAllFormTemplates(): Observable<any> {
		return this._http.get(`${FORMS_URL}/`);
	}

	saveExamFormTemplate(exam_form_template: any): Observable<any> {
		return this._http.post(
			`${EXAM_FORMS_URL}/${exam_form_template.id !== undefined ? exam_form_template.id + '/' : ''}`,
			exam_form_template
		);
	}

	getExamFormTemplates(patient_id: any, exam_id: any): Observable<any> {
		return this._http.get(`${EXAM_FORMS_URL}/`, {
			params: { patient_id, exam_id },
		});
	}

	shareViaPortal(shareRequest: any): Observable<any> {
		return this._http.post(`${PORTAL_URL}/share-study`, shareRequest);
	}

	getTemplatesParagraphs(): Observable<any> {
		return this._http.get(`${REPORTING_URL}/templates-paragraphs`);
	}

	addLabel(reportingTaskId: number, label: LabelDTO): Observable<LabelDTO> {
		return this._http.post<LabelDTO>(
			`${REPORTING_URL}/${reportingTaskId}/labels`,
			label
		);
	}

	deleteLabel(reportingTaskId: number, labelId: number): Observable<any> {
		return this._http.delete(
			`${REPORTING_URL}/${reportingTaskId}/labels/${labelId}`
		);
	}

	delegateTask(
		reportingTaskId: number,
		secondPerformerId: any,
		comment: string
	): Observable<any> {
		const params = { reportingTaskId, secondPerformerId, comment };
		return this._http.put(`${REPORTING_URL}/delegate`, null, { params });
	}

	reassignTask(reportingTaskId: number, performerId: any) {
		const params = { reportingTaskId, performerId };
		return this._http.put(`${REPORTING_URL}/re-assign`, null, { params });
	}

	getReferringPhysicianEmail(accessionNumber: string): Observable<any> {
		return this._http.get(
			`${REPORTING_URL}/referring-physician-email/${accessionNumber}`
		);
	}
}
