<button (click)="close(uploadComplete)" class="close-btn" mat-icon-button>
	<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
</button>
<div class="ft-parent-layout fx-fill fx-layout-column-nowrap">
	@if (uploadComplete) {
		<div
			(click)="close(true)"
			class="upload-section fx-grow-1 fx-layout-row-nowrap fx-items-center">
			<span [@uploadItemTransition]>{{ 'UPLOAD_DONE' | translate }}</span>
		</div>
	} @else {
		@if (!currentFile) {
			<div
				(drop)="handleItems($event, false)"
				(filesChange)="handleItems($event, true)"
				(validDragChange)="hasBaseDropZoneOver = $event"
				[accept]="fileTypesAuthorized"
				[files]="files"
				[ngClass]="{ 'file-over': hasBaseDropZoneOver }"
				class="upload-section fx-grow-1 fx-layout-column"
				multiple="1"
				ngfDrop
				ngfSelect>
				<span [@uploadItemTransition]>{{
					'DRAG_N_DROP_UPLOAD' | translate
				}}</span>
				<mat-icon
					[@uploadItemTransition]
					fontIcon="mdi-cloud-upload"
					fontSet="mdi"></mat-icon>
			</div>
		}
	}

	@if (currentFile) {
		<div class="progress-section fx-grow-1 fx-layout-column-nowrap">
			<mat-progress-bar
				color="accent"
				[@uploadItemTransition]
				[value]="currentFile.progress"
				mode="buffer"></mat-progress-bar>
			<span [@uploadItemTransition]
				>{{
					'CURRENT_FILE_UPLOAD'
						| translate: { index: currentFile.index, total: total }
				}}
				({{ fileSize(currentFile.size) }})</span
			>
		</div>
	}
</div>
