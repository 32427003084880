import { FileElement } from '../model/file-element';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import {
	HttpClient,
	HttpEventType,
	HttpParams,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { DOCUMENT_URL } from '../../../urls';
import moment from 'moment';
import { WsMessage } from '../../../model';

export interface IFileService {
	add(fileElement: FileElement);

	delete(id: string);

	update(id: string, update: Partial<FileElement>);

	queryInFolder(folderId: string): Observable<FileElement[]>;

	get(id: string): FileElement;
}

@Injectable()
export class FileService implements IFileService {
	private map = new Map<string, FileElement>();
	private querySubject: BehaviorSubject<FileElement[]>;
	private http = inject(HttpClient);

	getPatientDocuments(
		patientID: string,
		parent: string
	): Observable<FileElement[]> {
		const params = new HttpParams()
			.set('patientID', patientID)
			.set('parent', parent);

		return this.http.get<FileElement[]>(`${DOCUMENT_URL}/patientFiles`, {
			params: params,
		});
	}

	private static uuid(): string {
		return moment().format('YYYYMMDDHHmmssSSS');
	}

	createFile(file: FileElement): Observable<FileElement> {
		file.uuid = FileService.uuid();
		return this.http.post<FileElement>(`${DOCUMENT_URL}/createFile`, file);
	}

	updateFile(file: FileElement): Observable<FileElement> {
		return this.http.put<FileElement>(`${DOCUMENT_URL}/updateFile`, file);
	}

	deleteFile(file: FileElement): Observable<FileElement> {
		return this.http.post<FileElement>(`${DOCUMENT_URL}/deleteFile`, file);
	}

	uploadAudioFiles(
		files: File[],
		fileUUID: string
	): { [key: string]: Observable<number> } {
		// this will be our resulting map
		const status = {};

		files.forEach(file => {
			const params = new HttpParams().set('fileUUID', fileUUID);

			// let params = new HttpParams().set('fileUUID', `${fileUUID}-${files.indexOf(file)}`);

			// create a new multipart-form for every file
			const formData: FormData = new FormData();
			formData.append('file', file, file.name);

			// create a http-post request and pass the form
			// tell it to report the upload progress
			const req = new HttpRequest(
				'POST',
				`${DOCUMENT_URL}/uploadAudioChunk`,
				formData,
				{
					reportProgress: true,
					responseType: 'text',
					params: params,
				}
			);

			// create a new progress-subject for every file
			const progress = new Subject<number>();

			// send the http-request and subscribe for progress-updates
			this.http.request(req).subscribe(event => {
				if (event.type === HttpEventType.UploadProgress) {
					// calculate the progress percentage
					const percentDone = Math.round(
						(100 * event.loaded) / event.total
					);

					// pass the percentage into the progress-stream
					progress.next(percentDone);
				} else if (event instanceof HttpResponse) {
					// Close the progress-stream if we get an answer form the API
					// The upload is complete
					progress.complete();
				}
			});

			// Save every progress-observable in a map of all observables
			status[file.name] = {
				progress: progress.asObservable(),
			};
		});

		return status;
	}

	concatAudioFiles(fileUUID: string, count: any): Observable<boolean> {
		const params = {
			fileUUID,
			count,
			key: moment().format('YYYYMMDDHHmmssSSS'),
		};
		return this.http.get<boolean>(`${DOCUMENT_URL}/concatAudioFiles`, {
			params,
		});
	}

	uploadFiles(
		files: Set<File>,
		patientID: string,
		folderUUID: string,
		uuid?: string,
		patientPhoto: boolean = false
	): { [key: string]: Observable<number> } {
		// this will be our resulting map
		const status = {};

		const params = new HttpParams()
			.set('folderUUID', folderUUID)
			.set('fileUUID', uuid || 'none')
			.set('patientPhoto', String(patientPhoto))
			.set('patientID', patientID);

		files.forEach(file => {
			// create a new multipart-form for every file
			const formData: FormData = new FormData();
			formData.append('file', file, file.name);

			// create a http-post request and pass the form
			// tell it to report the upload progress
			const req = new HttpRequest(
				'POST',
				`${DOCUMENT_URL}/uploadFile`,
				formData,
				{
					reportProgress: true,
					responseType: 'text',
					params: params,
				}
			);

			// create a new progress-subject for every file
			const progress = new Subject<number>();

			// send the http-request and subscribe for progress-updates
			this.http.request(req).subscribe(event => {
				if (event.type === HttpEventType.UploadProgress) {
					// calculate the progress percentage
					const percentDone = Math.round(
						(100 * event.loaded) / event.total
					);

					// pass the percentage into the progress-stream
					progress.next(percentDone);
				} else if (event instanceof HttpResponse) {
					// Close the progress-stream if we get an answer form the API
					// The upload is complete
					progress.complete();
				}
			});

			// Save every progress-observable in a map of all observables
			status[file.name] = {
				progress: progress.asObservable(),
			};
		});

		return status;
	}

	add(fileElement: FileElement) {
		this.map.set(fileElement.uuid, this.clone(fileElement));
		return fileElement;
	}

	delete(id: string) {
		this.map.delete(id);
	}

	update(id: string, update: Partial<FileElement>) {
		let element = this.map.get(id);
		element = Object.assign(element, update);
		this.map.set(element.uuid, element);
	}

	queryInFolder(uuid: string) {
		const result: FileElement[] = [];
		this.map.forEach(element => {
			if (element.parent === uuid) {
				result.push(this.clone(element));
			}
		});
		if (!this.querySubject) {
			this.querySubject = new BehaviorSubject(result);
		} else {
			this.querySubject.next(result);
		}
		return this.querySubject.asObservable();
	}

	get(uuid: string) {
		return this.map.get(uuid);
	}

	clone(element: FileElement) {
		return JSON.parse(JSON.stringify(element));
	}

	concatAudioChunks(uuid: string): Observable<WsMessage> {
		const params = { uuid, key: moment().format('YYYYMMDDHHmmssSSS') };
		return this.http.get<WsMessage>(
			`${DOCUMENT_URL}/concatAudioChunks/${uuid}`,
			{ params }
		);
	}

	getAudioFile(filename: string): Observable<Blob> {
		return this.http.get(`${DOCUMENT_URL}/audio/${filename}`, {
			responseType: 'blob',
		});
	}

	uploadImageFiles(
		files: File[],
		keyImage: boolean = false
	): { [key: string]: Observable<number> } {
		const status = {};

		files.forEach(file => {
			const formData: FormData = new FormData();
			formData.append('file', file, file.name);
			const params = new HttpParams().set('keyImage', keyImage);

			const req = new HttpRequest(
				'POST',
				`${DOCUMENT_URL}/uploadImageFile`,
				formData,
				{
					params,
					reportProgress: true,
					responseType: 'text',
				}
			);

			const progress = new Subject<number>();

			this.http.request(req).subscribe(event => {
				if (event.type === HttpEventType.UploadProgress) {
					const percentDone = Math.round(
						(100 * event.loaded) / event.total
					);
					progress.next(percentDone);
				} else if (event instanceof HttpResponse) {
					progress.complete();
				}
			});

			status[file.name] = {
				progress: progress.asObservable(),
			};
		});

		return status;
	}

	deleteImage(imageUrl: string): Observable<any> {
		return this.http.delete(`${DOCUMENT_URL}/deleteImage`, {
			params: { imageUrl },
		});
	}
}
