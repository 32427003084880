import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FILE_SYSTEM } from '../urls';
import { tap } from 'rxjs/operators';
import { downloadFile, openBlobInBrowser, printBlob } from '../utils';
import { FileElement } from '../shared';

@Injectable({
	providedIn: 'root',
})
export class FileSystemService {
	private _http = inject(HttpClient);

	getAllFiles(
		pageSize: number,
		pageIndex: number,
		sort: string,
		direction: string,
		key: string
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${sort},${direction}`,
			key,
		};
		return this._http.get(`${FILE_SYSTEM}/`, { params });
	}

	download(filepath: string): Observable<any> {
		return this._http
			.get(`${FILE_SYSTEM}/download`, {
				params: { filepath },
				responseType: 'blob',
			})
			.pipe(tap(downloadFile));
	}

	print(filepath: string): Observable<any> {
		return this._http
			.get(`${FILE_SYSTEM}/print`, {
				params: { filepath },
				responseType: 'blob',
			})
			.pipe(tap(printBlob));
	}

	open(filepath: string): Observable<any> {
		return this._http
			.get(`${FILE_SYSTEM}/download`, {
				params: { filepath },
				responseType: 'blob',
			})
			.pipe(tap(openBlobInBrowser));
	}

	openImage(fileElement: FileElement): Observable<any> {
		const { uuid, fileType } = fileElement;
		return this._http
			.get(`${FILE_SYSTEM}/image`, {
				params: { uuid, fileType },
				responseType: 'blob',
			})
			.pipe(tap(openBlobInBrowser));
	}
}
