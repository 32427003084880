import { Injectable } from '@angular/core';
import { ToolConf } from '../model';
import { Subject } from 'rxjs';

declare let cornerstoneTools: any;

@Injectable({
	providedIn: 'root',
})
export class ToolService {
	public synchronizer = new cornerstoneTools.Synchronizer(
		'cornerstonenewimage',
		cornerstoneTools.updateImageSynchronizer
	);
	public wwwcSynchronizer = new cornerstoneTools.Synchronizer(
		'cornerstoneimagerendered',
		cornerstoneTools.wwwcSynchronizer
	);
	public panZoomSynchronizer = new cornerstoneTools.Synchronizer(
		'cornerstoneimagerendered',
		cornerstoneTools.panZoomSynchronizer
	);

	public availableTools: ToolConf[] = [
		{
			name: 'Wwwc',
			mode: 'active',
			class: cornerstoneTools.WwwcTool,
			synchronizer: this.wwwcSynchronizer,
			options: { mouseButtonMask: 1 },
		},
		{
			name: 'Zoom',
			mode: 'active',
			class: cornerstoneTools.ZoomTool,
			options: { mouseButtonMask: 2 },
			configuration: {
				invert: false,
				preventZoomOutsideImage: false,
				minScale: 0.1,
				maxScale: 20.0,
			},
		},
		{
			name: 'StackScrollMouseWheel',
			mode: 'active',
			class: cornerstoneTools.StackScrollMouseWheelTool,
			options: { mouseButtonMask: 3 },
		},
		{
			name: 'StackScroll',
			mode: 'active',
			class: cornerstoneTools.StackScrollTool,
			options: { mouseButtonMask: 0 },
		},
		{
			name: 'ArrowAnnotate',
			mode: 'active',
			class: cornerstoneTools.ArrowAnnotateTool,
			options: { mouseButtonMask: 0 },
		},
		{
			name: 'Pan',
			mode: 'active',
			class: cornerstoneTools.PanTool,
			options: { mouseButtonMask: 4 },
		},
		{
			name: 'Rotate',
			mode: 'active',
			class: cornerstoneTools.RotateTool,
			synchronizer: this.wwwcSynchronizer,
			options: { mouseButtonMask: 0 },
		},
	];
	toolChanged: Subject<any> = new Subject<any>();

	constructor() {}

	activateTool(name: string) {
		this.toolChanged.next(name);
	}
}
