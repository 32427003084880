import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileService } from '../file-explorer';
import { ToolService } from '../tool.service';
import { dataURItoBlob, StringUtils } from '../../utils';
import { forkJoin, Observable } from 'rxjs';
import { deleteItemFromArray } from '../shared-functions';

type ImageData = { currentIndex: number; imageUrl: any };

@Component({
	selector: 'ft-study-viewer',
	templateUrl: './study-viewer.component.html',
	styleUrls: ['./study-viewer.component.scss'],
})
export class StudyViewerComponent implements OnInit {
	images: string[] = [];
	selected: any = {};
	kos: any[] = [];
	current: ImageData;
	isKeyImage: boolean = false;
	cannotSetKeyImage: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fileService: FileService,
		private _toolService: ToolService,
		private _dialogRef: MatDialogRef<StudyViewerComponent>
	) {
		this.images = this.data.images.map(it => it.url.replace('thumb/', ''));
		this.selected = this.data.selected;
		this.selected['url'] = this.selected['url']?.replace('/thumb', '');
		this.cannotSetKeyImage = this.data.already_selected;
	}

	ngOnInit(): void {
		document.addEventListener('keydown', ev => {
			if (ev.key === 'Escape') this._dialogRef.close(this.kos);
		});
	}

	activateTool(name: string) {
		this._toolService.activateTool(name);
	}

	setCurrentAsKO() {
		const currentImageUrl = this.current.imageUrl;

		if (this.isKeyImage) deleteItemFromArray(this.kos, currentImageUrl);
		else this.kos.push(currentImageUrl);

		this.isKeyImage = !this.isKeyImage;

		if (this.isKeyImage) {
			const canvas: any = document.querySelectorAll(
				'.cornerstone-canvas canvas'
			)[0];

			const file = new File(
				[dataURItoBlob(canvas?.toDataURL())],
				currentImageUrl.substring(currentImageUrl.indexOf('images/')),
				{ type: 'image/jpg' }
			);

			this.uploadImageFiles([file]).subscribe(value => {
				if (value[value.length - 1] === 100) {
					console.log('upload done!');
				}
			});
		} else {
			this._fileService
				.deleteImage(
					currentImageUrl.substring(
						currentImageUrl.indexOf('images/')
					)
				)
				.subscribe();
		}
	}

	uploadImageFiles(files: File[]): Observable<any> {
		const progress = this._fileService.uploadImageFiles(files, true);

		const allProgressObservables = [];
		for (const key in progress)
			allProgressObservables.push(progress[key]['progress']);

		return forkJoin(allProgressObservables);
	}

	currentViewPortImage(ev: ImageData) {
		this.current = ev;
		this.isKeyImage = this.kos.includes(ev.imageUrl);
	}
}
