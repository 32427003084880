<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'EDIT_USER' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-16">
	<div [formGroup]="form">
		<div class="fx-layout-row-nowrap fx-gap-4">
			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'CLINICALID' | translate }}</mat-label>
				<input
					formControlName="clinicalID"
					matInput
					placeholder="{{ 'CLINICALID' | translate }}"
					tabindex="-1" />
			</mat-form-field>

			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'LASTNAME' | translate }}</mat-label>
				<input
					autocomplete="off"
					formControlName="lastName"
					matInput
					placeholder="{{ 'LASTNAME' | translate }}"
					required />
			</mat-form-field>

			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'FIRSTNAME' | translate }}</mat-label>
				<input
					autocomplete="off"
					formControlName="firstName"
					matInput
					placeholder="{{ 'FIRSTNAME' | translate }}"
					required />
			</mat-form-field>
		</div>

		<div class="fx-layout-row-nowrap fx-gap-4">
			<div class="fx-grow-1">
				<mat-form-field style="width: 100%">
					<mat-label>{{ 'USERNAME' | translate }}</mat-label>
					<input
						autocomplete="off"
						formControlName="username"
						matInput
						placeholder="{{ 'USERNAME' | translate }}"
						required />
				</mat-form-field>
				@if (exists | async) {
					<span class="ft-error">{{
						'USERNAME_EXIST' | translate
					}}</span>
				}
			</div>

			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'PASSWORD' | translate }}</mat-label>
				<input
					autocomplete="off"
					formControlName="password"
					matInput
					placeholder="{{ 'PASSWORD' | translate }}"
					type="password" />
			</mat-form-field>

			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'CONFIRMPASSWORD' | translate }}</mat-label>
				<input
					formControlName="confirmPassword"
					matInput
					placeholder="{{ 'CONFIRMPASSWORD' | translate }}"
					type="password" />
			</mat-form-field>
		</div>

		<div class="ft-color">
			<div>{{ 'COLOR' | translate }}:</div>
			<div
				class="color-area"
				[(colorPicker)]="color"
				[style.background]="color"></div>
		</div>
	</div>
</div>

<mat-dialog-actions align="end">
	<button color="warn" mat-dialog-close mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="onSave(form.value)"
		[disabled]="!form.valid"
		color="primary"
		mat-raised-button>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
