<div class="formatting-card">
	<div [style.padding.px]="12">
		<div class="section-label">{{ 'APPLY_TO' | translate }}</div>

		<mat-radio-group
			[(ngModel)]="rule.appliedTo"
			aria-label="format option">
			<mat-radio-button value="COLUMN">{{
				'COLUMN' | translate
			}}</mat-radio-button>
			<mat-radio-button value="ROW">{{
				'ROW' | translate
			}}</mat-radio-button>
		</mat-radio-group>

		<div [style.marginTop.px]="12" class="section-label">
			{{ 'FORMAT_RULES' | translate }}
		</div>

		<div class="sub-section-label" style="margin-bottom: 0 !important">
			{{ 'FORMATTING_BASED_COLUMN' | translate }}
		</div>
		<mat-form-field [style.width.%]="100" appearance="outline">
			<mat-select
				(valueChange)="filterRules($event)"
				[(ngModel)]="rule.targetColumn"
				[placeholder]="'FORMATTING_BASED_COLUMN' | translate">
				@for (column of filteredColumns(rule.appliedTo); track column) {
					<mat-option [value]="column.header">{{
						column.header | translate
					}}</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<div class="sub-section-label" style="margin-bottom: 0 !important">
			{{ 'FORMAT_IF' | translate }}
		</div>
		<mat-form-field [style.width.%]="100" appearance="outline">
			<mat-select
				[(ngModel)]="rule.formatRule"
				[placeholder]="'FORMATTING_RULE' | translate">
				@for (option of filteredRules; track option) {
					<mat-option [value]="option">{{
						option | translate
					}}</mat-option>
				}
			</mat-select>
		</mat-form-field>

		@if (!rule.formatRule.toString().includes('EMPTY')) {
			@switch (filteredType) {
				@case ('STATUS') {
					<mat-form-field [style.width.%]="100" appearance="outline">
						<mat-select
							[(ngModel)]="rule.primaryFormatValue"
							[placeholder]="'STATUS' | translate">
							@for (
								option of getColumnStatuses(rule.targetColumn);
								track option
							) {
								<mat-option [value]="option">{{
									option | translate
								}}</mat-option>
							}
						</mat-select>
					</mat-form-field>
				}

				@case ('DATE') {
					<mat-form-field [style.width.%]="100" appearance="outline">
						<mat-select
							[(ngModel)]="rule.primaryFormatValue"
							[placeholder]="'DATE' | translate">
							@for (option of dateOptions; track option) {
								<mat-option [value]="option">{{
									option | translate
								}}</mat-option>
							}
						</mat-select>
					</mat-form-field>
				}

				@case ('BOOLEAN') {
					<mat-radio-group
						[(ngModel)]="rule.primaryFormatValue"
						aria-label="boolean option">
						<mat-radio-button [value]="'true'">{{
							'YES' | translate
						}}</mat-radio-button>
						<mat-radio-button [value]="'false'">{{
							'NO' | translate
						}}</mat-radio-button>
					</mat-radio-group>
				}

				@default {
					<div
						class="value-input fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
						<mat-form-field
							[style.width]="
								rule.formatRule.toString().includes('BETWEEN')
									? '139px'
									: ''
							"
							[style.width.%]="100"
							appearance="outline">
							<input
								[(ngModel)]="rule.primaryFormatValue"
								[placeholder]="
									(rule.formatRule
										.toString()
										.includes('BETWEEN')
										? 'MIN'
										: 'VALUE'
									) | translate
								"
								[type]="
									rule.formatRule
										.toString()
										.includes('BETWEEN')
										? 'number'
										: 'text'
								"
								matInput />
						</mat-form-field>
						@if (rule.formatRule.toString().includes('BETWEEN')) {
							<span>&</span>
							<mat-form-field
								[style.width.%]="100"
								appearance="outline"
								style="width: 139px">
								<input
									[(ngModel)]="rule.secondaryFormatValue"
									[placeholder]="'MAX' | translate"
									matInput
									type="number" />
							</mat-form-field>
						}
					</div>
				}
			}
		}

		<div class="sub-section-label">
			{{ 'FORMATTING_STYLE' | translate }}
		</div>

		<div class="formatting-style-card">
			<div [ngStyle]="previewStyle(rule)" class="preview">
				{{ 'PREVIEW' | translate }}
			</div>
			<div
				class="style-icons fx-layout-row-nowrap fx-content-start fx-items-end fx-gap-4">
				<mat-icon
					(colorPickerClose)="
						rule.formattingStyle.backgroundColor = $event
					"
					[(colorPicker)]="rule.formattingStyle.backgroundColor"
					[cpCancelButtonClass]="'cp-cancel-btn'"
					[cpCancelButton]="true"
					[cpOKButtonClass]="'cp-ok-btn'"
					[cpOKButton]="true"
					[cpOutputFormat]="'hex'"
					[style.border-bottom-color]="
						rule.formattingStyle.backgroundColor
					"
					class="color-indicator"
					fontIcon="mdi-format-color-fill"
					fontSet="mdi"></mat-icon>
				<mat-icon
					(colorPickerClose)="rule.formattingStyle.color = $event"
					[(colorPicker)]="rule.formattingStyle.color"
					[cpCancelButtonClass]="'cp-cancel-btn'"
					[cpCancelButton]="true"
					[cpOKButtonClass]="'cp-ok-btn'"
					[cpOKButton]="true"
					[cpOutputFormat]="'hex'"
					[style.border-bottom-color]="rule.formattingStyle.color"
					class="color-indicator"
					fontIcon="mdi-format-color-text"
					fontSet="mdi"></mat-icon>

				<span
					style="
						height: 24px;
						border-left: 1px solid #e5e5e5;
						margin: 0 2px;
					"></span>

				<mat-icon
					(click)="
						rule.formattingStyle.bold = !rule.formattingStyle.bold
					"
					[class.checked]="rule.formattingStyle.bold"
					fontIcon="mdi-format-bold"
					fontSet="mdi"></mat-icon>
				<mat-icon
					(click)="
						rule.formattingStyle.italic =
							!rule.formattingStyle.italic
					"
					[class.checked]="rule.formattingStyle.italic"
					fontIcon="mdi-format-italic"
					fontSet="mdi"></mat-icon>
				<mat-icon
					(click)="
						rule.formattingStyle.underlined =
							!rule.formattingStyle.underlined
					"
					[class.checked]="rule.formattingStyle.underlined"
					fontIcon="mdi-format-underline"
					fontSet="mdi"></mat-icon>
				<mat-icon
					(click)="
						rule.formattingStyle.strikethrough =
							!rule.formattingStyle.strikethrough
					"
					[class.checked]="rule.formattingStyle.strikethrough"
					fontIcon="mdi-format-strikethrough"
					fontSet="mdi"></mat-icon>
			</div>
		</div>

		@if (
			rule.appliedTo === 'COLUMN' &&
			['STATUS', 'BOOLEAN'].includes(filteredType)
		) {
			<div class="sub-section-label" style="margin-bottom: 0 !important">
				{{ 'DISPLAY_MODE' | translate }}
			</div>
			<div class="display-mode fx-layout-column-nowrap">
				<mat-button-toggle-group
					[(ngModel)]="rule.formattingStyle.displayMode"
					[style.width]="'fit-content'"
					aria-label="Display Mode"
					name="displayMode">
					@for (mode of displayModes; track mode) {
						<mat-button-toggle [value]="mode">{{
							mode | translate
						}}</mat-button-toggle>
					}
				</mat-button-toggle-group>

				<div class="display-mode-container">
					@switch (rule.formattingStyle.displayMode) {
						@case ('ICON') {
							<button
								(click)="selectIcon()"
								mat-button
								style="padding: 0 8px; line-height: 20px">
								<mat-icon
									[fontIcon]="selectedIcon"
									[ngStyle]="previewStyle(rule)"
									fontSet="mdi"
									style="
										padding: 4px;
										border-radius: 50%;
									"></mat-icon>
								{{ rule.primaryFormatValue | translate }}
							</button>
						}
						@case ('BADGE') {
							<span
								[ngStyle]="previewStyle(rule)"
								class="ft-badge"
								>{{ rule.primaryFormatValue | translate }}</span
							>
						}
						@default {
							<div
								[ngStyle]="previewStyle(rule)"
								style="padding: 4px">
								{{ rule.primaryFormatValue | translate }}
							</div>
						}
					}
				</div>
			</div>
		}
	</div>
	<div
		[style.padding.px]="12"
		class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4">
		<button (click)="cancel()" color="warn" mat-raised-button>
			{{ 'CANCEL' | translate }}
		</button>
		<button (click)="applyRule()" color="primary" mat-raised-button>
			{{ 'APPLY' | translate }}
		</button>
	</div>
</div>
