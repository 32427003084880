<mat-toolbar class="dialog-toolbar">
	<mat-icon fontSet="mdi" fontIcon="mdi-eye"></mat-icon>
	<h3 style="padding-left: 6px">{{ 'VIEWER_SELECT' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button mat-dialog-close tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>
<mat-dialog-content style="padding: 12px">
	<div [formGroup]="viewerSelect">
		<mat-form-field class="full-width">
			<mat-label>{{ 'VIEWER' | translate }}</mat-label>
			<mat-select
				[placeholder]="'VIEWER' | translate"
				formControlName="aETitle"
				required>
				@for (viewer of viewers; track viewer) {
					<mat-option [value]="viewer.aETitle">{{
						viewer.aETitle
					}}</mat-option>
				}
			</mat-select>
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button [mat-dialog-close]="false" color="warn" mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		[disabled]="viewerSelect.invalid"
		[mat-dialog-close]="viewerSelect.value"
		color="primary"
		mat-raised-button>
		{{ 'OK' | translate }}
	</button>
</mat-dialog-actions>
