import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';

@Component({
	selector: 'ft-form-radio',
	templateUrl: './form-radio.component.html',
	styleUrls: ['./form-radio.component.scss'],
})
export class FormRadioComponent implements Field {
	config: any;
	group: FormGroup;
}
