import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import {
	defaultFormattingRule,
	FormattingRule,
	TableConfig,
} from '../../model';
import { deleteItemFromArray } from '../shared-functions';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { sortBy } from 'lodash';

@Component({
	selector: 'ft-table-formatter',
	templateUrl: './table-formatter.component.html',
	styleUrls: ['./table-formatter.component.scss'],
})
export class TableFormatterComponent implements OnChanges {
	@Output() closeEvent = new EventEmitter<boolean>();
	@Output() saveEvent = new EventEmitter<TableConfig>();
	@Input() tableConfig: TableConfig;
	selectedRule: FormattingRule;

	drop(event: CdkDragDrop<any[]>) {
		moveItemInArray(
			this.tableConfig.formattingRules,
			event.previousIndex,
			event.currentIndex
		);
	}

	deleteRule(rule: FormattingRule) {
		deleteItemFromArray(this.tableConfig.formattingRules, rule);

		this.saveEvent.emit(this.tableConfig);
	}

	editRule(rule: FormattingRule) {
		this.selectedRule = rule;
	}

	saveChanges(rule: FormattingRule) {
		this.tableConfig.formattingRules.push(rule);
		this.saveEvent.emit(this.tableConfig);

		this.selectedRule = null;
	}

	addNewRule() {
		this.selectedRule = defaultFormattingRule;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.tableConfig = changes['tableConfig'].currentValue;
	}

	getFormattingRules(): FormattingRule[] {
		return sortBy(this.tableConfig.formattingRules, 'targetColumn');
	}
}
