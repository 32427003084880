<mat-toolbar class="fx-height-42" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
	<h3 style="padding-left: 4px">{{ 'SEARCH' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<mat-dialog-content
	style="
		padding: 0 !important;
		overflow: hidden;
		min-height: 426px !important;
	">
	<div class="fx-layout-column-nowrap">
		<div
			class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8">
			<mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
				<mat-icon
					matIconPrefix
					fontIcon="mdi-magnify"
					fontSet="mdi"></mat-icon>
				<input
					matInput
					#filter
					placeholder="{{ 'SEARCHING' | translate }}" />
			</mat-form-field>
		</div>

		<div
			style="padding-inline: 8px"
			class="fx-layout-column fx-overflow-auto">
			<mat-table
				[dataSource]="dataSource"
				class="fx-overflow-auto fx-fill-width"
				style="height: 320px"
				[trackBy]="trackById"
				matSort
				matSortActive="code"
				matSortDirection="asc"
				[matSortDisableClear]="true">
				<!--Table-->
				@for (col of displayedColumns; track col) {
					<ng-container
						cdkColumnDef="{{ col.label }}"
						[class.hidden]="col.hidden">
						<mat-header-cell
							*cdkHeaderCellDef
							mat-sort-header="{{ col.value }}"
							[disabled]="!col.sortable">
							{{ col.header | translate }}
						</mat-header-cell>

						@if (col.label === 'reasonForExamValue') {
							<mat-cell *cdkCellDef="let row">
								<span
									class="ft-badge"
									[style.background-color]="
										row['reasonForExamColor']
									"
									>{{ row[col.label] }}</span
								>
							</mat-cell>
						} @else {
							<mat-cell *cdkCellDef="let row">
								{{ row[col.label] }}</mat-cell
							>
						}
					</ng-container>
				}

				<!--header-->
				<mat-header-row
					*cdkHeaderRowDef="cols; sticky: true"></mat-header-row>
				<mat-row
					[@rowsAnimation]=""
					*cdkRowDef="let row; columns: cols"
					(click)="selectCode(row)">
				</mat-row>
			</mat-table>
		</div>

		<mat-paginator
			[length]="resultsLength"
			[pageIndex]="0"
			[pageSize]="10"
			[pageSizeOptions]="[10, 25, 50]"
			[showFirstLastButtons]="true">
		</mat-paginator>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button [mat-dialog-close]="null" color="warn" mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
</mat-dialog-actions>
