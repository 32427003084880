<div class="viewer fx-layout-column fx-content-space-between fx-fill">
	<!--header-->
	<div
		class="fx-layout-row-nowrap fx-content-space-between fx-items-center"
		style="z-index: 1000">
		<span class="fx-grow-1"></span>
		<!--zoom-->
		<button mat-icon-button (click)="zoomin()">
			<mat-icon
				fontSet="mdi"
				fontIcon="mdi-magnify-plus-outline"
				class="w-icon"></mat-icon>
		</button>
		<!--set kos-->
		<button mat-icon-button (click)="setKOS(current)">
			<mat-icon
				fontSet="mdi"
				[fontIcon]="current.fav ? 'mdi-star' : 'mdi-star-outline'"
				class="w-icon"></mat-icon>
		</button>

		<button mat-icon-button (click)="zoomout()">
			<mat-icon
				fontSet="mdi"
				fontIcon="mdi-magnify-minus-outline"
				class="w-icon"></mat-icon>
		</button>
		<span class="fx-grow-1"></span>
		<!--download-->
		@if (!data.fromPacs) {
			<button mat-icon-button (click)="download()">
				<mat-icon
					fontSet="mdi"
					fontIcon="mdi-cloud-download"
					class="w-icon"></mat-icon>
			</button>
		}
		<button mat-icon-button mat-dialog-close>
			<mat-icon
				fontSet="mdi"
				fontIcon="mdi-close"
				class="w-icon"></mat-icon>
		</button>
	</div>

	<!--container-->
	<div
		class="fx-grow-1 fx-layout-row-nowrap fx-content-space-between fx-items-center">
		<button class="btn-left" mat-icon-button (click)="previous()">
			<mat-icon
				fontIcon="mdi-arrow-left"
				fontSet="mdi"
				class="w-icon"></mat-icon>
		</button>

		<div
			class="fx-grow-1 fx-layout-column fx-content-start fx-items-center">
			<img
				[src]="getOriginalImage(current.url)"
				[alt]="current.name"
				class="main-image"
				#image />
		</div>

		<button class="btn-right" mat-icon-button (click)="next()">
			<mat-icon
				fontIcon="mdi-arrow-right"
				fontSet="mdi"
				class="w-icon"></mat-icon>
		</button>
	</div>

	<!--images grid -->
	<div
		class="fx-layout-row fx-content-center fx-items-center fx-gap-4"
		style="overflow: hidden">
		@for (image of images; track image) {
			<div class="image-item" (click)="current = image">
				<img
					[src]="image.url"
					[ngClass]="{ selected: image === current }"
					alt="" />
			</div>
		}
	</div>
</div>
