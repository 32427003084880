<mat-toolbar class="dialog-toolbar">
	<mat-icon fontSet="mdi" fontIcon="mdi-book-variant"></mat-icon>
	<h3 style="padding-left: 6px">{{ 'AFTER_EXAM' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button mat-dialog-close tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>
<mat-dialog-content style="padding: 12px">
	<div [formGroup]="form" class="fx-layout-column-nowrap">
		<div class="fx-layout-row-nowrap fx-gap-4">
			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'PATIENT_ID' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'PATIENT_ID' | translate"
					formControlName="patientID" />
			</mat-form-field>

			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'PATIENT_NAME' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'PATIENT_NAME' | translate"
					formControlName="patientName" />
			</mat-form-field>
		</div>

		<mat-form-field class="full-width">
			<mat-label>{{ 'REASON_FOR_EXAM' | translate }}</mat-label>
			<input
				matInput
				[placeholder]="'REASON_FOR_EXAM' | translate"
				formControlName="reasonForExam" />
		</mat-form-field>

		<mat-form-field class="full-width">
			<mat-label>{{ 'PROCEDURE_CODE' | translate }}</mat-label>
			<input
				matInput
				[placeholder]="'PROCEDURE_CODE' | translate"
				formControlName="procedureCode" />
		</mat-form-field>

		<mat-form-field class="full-width" formGroupName="technician">
			<mat-label>{{ 'TECHNICIAN' | translate }}</mat-label>
			<mat-select
				[placeholder]="'TECHNICIAN' | translate"
				formControlName="id"
				[required]="true">
				@for (tec of technicians; track tec) {
					<mat-option [value]="tec.id">{{ tec.fullName }}</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<mat-form-field class="full-width" formGroupName="performingPhysician">
			<mat-label>{{ 'PERFORMING_PHYSICIAN' | translate }}</mat-label>
			<mat-select
				[placeholder]="'PERFORMING_PHYSICIAN' | translate"
				formControlName="id"
				[required]="true">
				@for (physician of performingPhysicians; track physician) {
					<mat-option [value]="physician.id">
						{{ physician.fullName }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<div>
			<div class="label-group">{{ 'CONTRAST' | translate }}</div>
			<mat-radio-group
				class="fx-layout-row fx-content-start"
				formControlName="contrast"
				[required]="true">
				@for (option of contrasts; track option) {
					<mat-radio-button class="fx-grow-1" [value]="option">
						{{ option }}
					</mat-radio-button>
				}
			</mat-radio-group>
		</div>

		<div>
			<div class="label-group">{{ 'NUMBER_OF_FILMS' | translate }}</div>
			<mat-radio-group
				class="fx-layout-row fx-content-start"
				formControlName="numberOfFilms">
				@for (option of films; track option) {
					<mat-radio-button class="fx-grow-1" [value]="option">
						{{ option }}
					</mat-radio-button>
				}
			</mat-radio-group>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button [mat-dialog-close]="false" color="warn" mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="onSave(form.value)"
		[disabled]="form.invalid"
		color="primary"
		mat-raised-button>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
