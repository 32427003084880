import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SharedService } from '../shared.service';
import { WorkflowService } from '../../workflow/workflow.service';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'ft-tasks-list',
	templateUrl: './tasks-list.component.html',
	styleUrls: ['./tasks-list.component.scss'],
})
export class TasksListComponent implements OnInit {
	form: FormGroup;
	resources = {};
	constructor(
		private _fb: FormBuilder,
		private _service: SharedService,
		private _workflow: WorkflowService,
		private snackBar: MatSnackBar
	) {
		this.form = this._fb.group({
			resource: 'ROOM',
			id: ['', Validators.required],
			startDate: [moment(), Validators.required],
			endDate: [moment(), Validators.required],
		});
	}

	ngOnInit(): void {
		forkJoin([
			this._service.getAetList(),
			this._service.getTechnicians(),
			this._service.getRadiologists(),
			this._service.getRooms(),
		]).subscribe(value => {
			const [aets, technicians, radiologists, rooms] = value;
			this.resources['AET'] = aets;
			this.resources['TECHNICIAN'] = technicians;
			this.resources['RADIOLOGIST'] = radiologists;
			this.resources['ROOM'] = rooms;
		});
	}

	generateList() {
		const query = this.buildQuery();
		const snackBarRef = this.snackBar.open('Printing tasks ...', 'Ok', {
			duration: 10000,
		});
		this._workflow
			.generateTasksList(query)
			.subscribe(_ => snackBarRef.dismiss());
	}

	downloadList() {
		const query = this.buildQuery();
		const snackBarRef = this.snackBar.open('Downloading tasks ...', 'Ok', {
			duration: 10000,
		});
		this._workflow
			.generateTasksList(query, 'PDF')
			.subscribe(_ => snackBarRef.dismiss());
	}

	private buildQuery(): string {
		const { resource, id, startDate, endDate } = this.form.getRawValue();
		return [
			resource,
			id,
			moment(startDate).format('YYYYMMDD'),
			moment(endDate).format('YYYYMMDD'),
		].join('_');
	}
}
