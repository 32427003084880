<div class="pharmacy">
	@if (workflowItem) {
		<form [formGroup]="stockForm">
			<div
				[style.marginTop.px]="16"
				class="fx-layout-row-nowrap fx-content-start fx-items-end fx-gap-12">
				<mat-form-field>
					<mat-label>{{ 'STORE' | translate }}</mat-label>
					<mat-select
						[placeholder]="'STORE' | translate"
						formControlName="store">
						@for (store of stores; track store) {
							<mat-option [value]="store.id">{{
								store.name
							}}</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<div class="radio fx-layout-column-nowrap">
					<div class="label-option">
						{{ 'PRICE_MODE' | translate }}
					</div>

					<mat-radio-group
						color="primary"
						formControlName="priceMode"
						class="fx-layout-row fx-content-start fx-items-end">
						@for (option of ['HT', 'TTC']; track option) {
							<mat-radio-button
								[value]="option"
								class="fx-grow-1">
								{{ option }}
							</mat-radio-button>
						}
					</mat-radio-group>
				</div>

				<span class="fx-grow-1"></span>
				<button
					(click)="addNewArticle()"
					[disabled]="formDisabled"
					color="primary"
					mat-button>
					<mat-icon
						fontIcon="mdi-text-box-plus"
						fontSet="mdi"></mat-icon>
					{{ 'ARTICLE' | translate }}
				</button>
			</div>

			<!--        Table-->
			<div [style.marginTop.px]="4" class="limiter">
				<div class="container-table">
					<div class="table">
						<div class="row header">
							<div class="cell">
								{{ 'CODE' | translate }}
							</div>
							<div class="cell">
								{{ 'DESIGNATION' | translate }}
							</div>
							<div class="cell">
								{{ 'PRICE' | translate }}
							</div>
							<div class="cell">
								{{ 'QUANTITY' | translate }}
							</div>
							<div class="cell">
								{{ 'VAT' | translate }}
							</div>
							<div class="cell">
								{{ 'STORE' | translate }}
							</div>
							<div class="cell">
								{{ 'SUB_TOTAL' | translate }}
							</div>
							<div class="cell"></div>
						</div>

						@for (article of articles; track article) {
							<div class="row">
								<div class="cell">{{ article.code }}</div>
								<div class="cell">
									{{ article.description }}
								</div>
								<div class="cell">
									{{ formatNumeral(article.price) }}
								</div>
								<div class="cell">
									<mat-form-field>
										<input
											(keyup)="updateArticle(article)"
											[(ngModel)]="article.quantity"
											[disabled]="formDisabled"
											[ngModelOptions]="{
												standalone: true
											}"
											matInput
											type="number" />
									</mat-form-field>
								</div>
								<div class="cell">
									<mat-form-field style="width: 50px">
										<mat-select
											(selectionChange)="
												updateArticle(article)
											"
											[(ngModel)]="article.vatAmount"
											[disabled]="formDisabled"
											[ngModelOptions]="{
												standalone: true
											}"
											[placeholder]="'TVA' | translate">
											@for (
												vatAmount of vatAmounts;
												track vatAmount
											) {
												<mat-option [value]="vatAmount"
													>{{ vatAmount }}%
												</mat-option>
											}
										</mat-select>
									</mat-form-field>
								</div>
								<div class="cell">
									<mat-form-field style="width: 120px">
										<mat-select
											(selectionChange)="
												updateArticle(article)
											"
											[(ngModel)]="article.store"
											[disabled]="formDisabled"
											[ngModelOptions]="{
												standalone: true
											}"
											[placeholder]="'STORE' | translate">
											@for (
												store of stores;
												track store
											) {
												<mat-option [value]="store.id"
													>{{ store.name }}
												</mat-option>
											}
										</mat-select>
									</mat-form-field>
								</div>
								<div class="cell">
									{{ formatNumeral(article.totalPrice) }}
								</div>
								<div [style.textAlign]="'right'" class="cell">
									@if (article.hasLots) {
										<button
											(click)="selectLotNumbers(article)"
											[disabled]="formDisabled"
											[style.lineHeight.px]="18"
											mat-icon-button>
											<mat-icon
												[style.color]="
													article.lots === null ||
													lotsTotalQuantity(
														article.lots
													) !== article.quantity
														? '#ff1111'
														: formDisabled
															? '#e0e0e0'
															: '#238748'
												"
												fontIcon="mdi-alpha-l-box"
												fontSet="mdi"></mat-icon>
										</button>
									}

									@if (article.hasSerials) {
										<button
											(click)="
												selectSerialNumbers(article)
											"
											[disabled]="formDisabled"
											[style.lineHeight.px]="18"
											mat-icon-button>
											<mat-icon
												[style.color]="
													article.serials === null ||
													article.serials.split(',')
														.length !==
														article.quantity
														? '#ff1111'
														: formDisabled
															? '#e0e0e0'
															: '#238748'
												"
												fontIcon="mdi-barcode"
												fontSet="mdi"></mat-icon>
										</button>
									}

									<button
										(click)="removeArticle(article)"
										[disabled]="formDisabled"
										[style.lineHeight.px]="18"
										mat-icon-button>
										<mat-icon
											[style.color]="
												formDisabled
													? '#e0e0e0'
													: '#fa4d77'
											"
											fontIcon="mdi-close-circle"
											fontSet="mdi"></mat-icon>
									</button>
								</div>
							</div>
						}

						@if (!articles.length) {
							<div class="row">
								<div class="no-cell">
									<button
										(click)="addNewArticle()"
										[disabled]="formDisabled"
										color="primary"
										mat-button>
										<mat-icon
											fontIcon="mdi-text-box-plus"
											fontSet="mdi"></mat-icon>
										Article
									</button>
								</div>
							</div>
						}
					</div>
				</div>
			</div>

			<div
				class="fx-layout-row-nowrap fx-content-end fx-items-start fx-gap-6"
				[style.marginTop.em]="2">
				<mat-form-field class="fx-grow-1">
					<mat-label>{{ 'COMMENT' | translate }}</mat-label>
					<input
						[placeholder]="'COMMENT' | translate"
						formControlName="comment"
						matInput
						maxLength="200" />
					<mat-hint
						[style.fontWeight]="'bold'"
						[style.paddingTop.px]="8"
						align="end"
						>{{ stockForm.value['comment']?.length || 0 }} / 200
					</mat-hint>
				</mat-form-field>

				<mat-form-field style="width: 120px">
					<mat-label>{{
						'DISCOUNT_PERCENTAGE' | translate
					}}</mat-label>
					<input
						(keyup)="changeDiscountPrice($event)"
						[max]="100"
						[placeholder]="'DISCOUNT_PERCENTAGE' | translate"
						formControlName="globalDiscountPercentage"
						matInput
						type="number" />
				</mat-form-field>
				<mat-form-field style="width: 96px">
					<mat-label>{{
						'DISCOUNT_PRICE'
							| translate: { currency: currencyFormat }
					}}</mat-label>
					<input
						(keyup)="changeDiscountPercentage($event)"
						[placeholder]="'DISCOUNT_PRICE' | translate"
						formControlName="globalDiscount"
						matInput
						type="number" />
				</mat-form-field>
			</div>

			<div
				class="fx-layout-row-nowrap fx-content-space-between fx-gap-12">
				<span class="fx-grow-1"></span>
				<div style="width: 230px">
					<div class="row2 right-align">
						<div style="width: 120px">
							{{ 'TOTAL_HT' | translate }}
						</div>
						<div style="width: 100px">
							{{ formatNumeral(totalHT) }}
						</div>
					</div>
					<div class="row2 right-align">
						<div style="width: 120px">
							{{ 'TOTAL_VAT' | translate }}
						</div>
						<div style="width: 100px">
							{{ formatNumeral(totalVAT) }}
						</div>
					</div>
					<div class="row2 right-align">
						<div style="width: 120px">
							{{ 'TOTAL_TTC' | translate }}
						</div>
						<div style="width: 100px">
							{{ formatNumeral(totalTTC) }}
						</div>
					</div>
				</div>
			</div>
		</form>
	}
	@if (stockForm.value.valid) {
		<div [class.approved]="true">
			<img
				alt="Valid"
				height="120px"
				src="../../../assets/images/valid.png" />
		</div>
	}

	<div class="fx-layout-row-nowrap fx-content-end fx-items-end fx-gap-4">
		<button
			class="save-button"
			mat-raised-button
			color="primary"
			(click)="goToPrevious()">
			<mat-icon fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
			{{ 'PREVIOUS' | translate }}
		</button>
		<span class="fx-grow-1"></span>
		@if (formDisabled) {
			<button
				class="unvalidate-button"
				(click)="unValidate(stockForm.value)"
				color="warn"
				mat-raised-button>
				{{ 'UN_VALIDATE' | translate }}
			</button>
		}
		@if (!formDisabled) {
			<button
				class="validate-button"
				(click)="validate(stockForm.value)"
				[disabled]="!isFormValid"
				color="warn"
				mat-raised-button>
				{{ 'VALIDATE' | translate }}
			</button>
		}
		<button
			class="next-button"
			mat-raised-button
			color="primary"
			(click)="goToNext()">
			<mat-icon fontSet="mdi" fontIcon="mdi-arrow-right"></mat-icon>
			{{ 'NEXT' | translate }}
		</button>
	</div>
</div>
