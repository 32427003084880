<div
	class="fx-layout-row-nowrap fx-content-space-between fx-items-center main-container">
	<div class="viewer-container fx-grow-1">
		<div
			[viewport]="selected"
			(onStackScroll)="currentViewPortImage($event)"
			[synchronized]="false"
			[images]="images"
			class="cornerstone-canvas fx-layout-column fx-content-center fx-items-center"></div>
	</div>
	<div
		class="fx-layout-column fx-content-center fx-items-center fx-fill-height viewer-actions">
		@if (!cannotSetKeyImage) {
			<button mat-button (click)="setCurrentAsKO()">
				<mat-icon
					fontSet="mdi"
					[fontIcon]="
						isKeyImage ? 'mdi-star' : 'mdi-star-outline'
					"></mat-icon>
				KO
			</button>
		}
		<button mat-button (click)="activateTool('Rotate')">
			<mat-icon fontSet="mdi" fontIcon="mdi-rotate-right"></mat-icon>
			Rotate
		</button>
		<button mat-button (click)="activateTool('Zoom')">
			<mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
			Zoom
		</button>
		<button mat-button (click)="activateTool('ArrowAnnotate')">
			<mat-icon
				fontSet="mdi"
				fontIcon="mdi-arrow-top-left-thin"></mat-icon>
			Annotate
		</button>
		<button mat-button (click)="activateTool('Wwwc')">
			<mat-icon fontSet="mdi" fontIcon="mdi-contrast-circle"></mat-icon>
			Wwwc
		</button>
		<button mat-button (click)="activateTool('StackScroll')">
			<mat-icon fontSet="mdi" fontIcon="mdi-layers"></mat-icon>
			Scroll
		</button>
		<button mat-button [matDialogClose]="kos">
			<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
			Close
		</button>
	</div>
</div>
