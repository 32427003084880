import {
	AfterViewInit,
	ApplicationRef,
	Component,
	ComponentFactoryResolver,
	Injector,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { CALENDAR_TYPES, RESOURCE_TYPES } from './event-utils';
import { assign, get as _get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SchedulerService } from '../../scheduler/scheduler.service';

import $ from 'jquery';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { CalendarEventComponent } from './calendar-event/calendar-event.component';
import { DeleteConfirmComponent } from '../delete-confirm/delete-confirm.component';
import {
	CalendarEvent,
	CalendarSettingDTO,
	GeneralSettingDTO,
	RoomDTO,
} from '../../model';
import { calculateSlotDuration, targetView } from '../shared-functions';
import { FtMenuComponent } from './ft-menu/ft-menu.component';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { SharedService } from '../shared.service';
import { toMoment } from '@fullcalendar/moment';
import { TranslateService } from '@ngx-translate/core';
import { calendarPresets } from './calendar-config';
import { AppConfigService } from '../../app-config.service';
import { AppointmentEditComponent } from '../../scheduler/appointment-edit/appointment-edit.component';
import { PatientService } from '../../patient/patient.service';
import moment from 'moment';
import { AptStatus } from '../../scheduler/shared/util';
import { CalendarOptions, EventApi } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ExamSchedulerComponent } from '../../scheduler/exam-scheduler/exam-scheduler.component';

@Component({
	selector: 'ft-calendar',
	templateUrl: './ft-calendar.component.html',
	styleUrls: ['./ft-calendar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FtCalendarComponent
	implements OnChanges, AfterViewInit, OnDestroy
{
	calendarOptions: CalendarOptions;
	currentEvents: EventApi[] = [];
	@ViewChild('calendar') calendar: FullCalendarComponent;
	@ViewChild('picker') picker: any;
	@ViewChild('targetPicker') dateMovePicker: any;

	private res: string = 'aet';
	private calendarType: 'RDV' | 'EXAM' = 'RDV';
	private calendarApi: any;
	private readonly generalSetting: GeneralSettingDTO;

	private businessHours = [
		{
			daysOfWeek: [],
			startTime: '01:00',
			endTime: '23:00',
		},
	];
	private eventSelectionActivation: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private resourcesMap: { aet: any; physician: any; room: RoomDTO[] };
	private _subs: Subscription[] = [];

	constructor(
		private _resolver: ComponentFactoryResolver,
		private _injector: Injector,
		private _appRef: ApplicationRef,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		private router: Router,
		private _config: AppConfigService,
		private translate: TranslateService,
		private sharedService: SharedService,
		private patientService: PatientService,
		private service: SchedulerService
	) {
		this.generalSetting = this._config.generalSetting;
		const datasets = 'rooms,performingPhysicians,aets';
		this._subs.push(
			this.sharedService.getDatasets(datasets).subscribe(data => {
				const { rooms, performingPhysicians, aets } = data;
				this.resourcesMap = {
					room: rooms,
					physician: performingPhysicians,
					aet: aets,
				};
			})
		);

		this.calendarOptions = assign({}, calendarPresets(this), {
			customButtons: {
				dateSelect: {
					icon: 'date-select',
					text: this.translate.instant('DATE'),
					click: this.changeDate.bind(this),
				},
				moveEvents: {
					icon: 'move',
					text: this.translate.instant('MOVE_EVENTS'),
					click: this.moveEvents.bind(this),
				},
				selectEvents: {
					disabled: this.eventSelectionActivation.getValue(),
					icon: 'event-select',
					text: this.translate.instant('SELECT_EVENTS'),
					click: this.selectEvents.bind(this),
				},
				resourceSelect: {},
			},
			eventsSet: this.handleEvents.bind(this),
			eventDidMount: this.renderEvent.bind(this),
			eventMouseEnter: (info: any) => (info.el.classList += ' hover'),
			eventMouseLeave: (mouseEnterInfo: any) =>
				$(mouseEnterInfo.el).removeClass('hover'),
			eventDrop: (info: any) => {
				if (
					toMoment(
						info.event._instance.range.start,
						this.calendarApi
					).isBefore(toMoment(new Date(), this.calendarApi), 'minute')
				)
					info.revert();
				else
					this.updateEvent(
						info.event._instance.range,
						info.event._def.extendedProps
					);
			},
			dateClick: this.clickDate.bind(this),
			eventClick: this.clickEvent.bind(this),
			select: this.createEvent.bind(this),
			resources: (_fetchInfo: any, successCallback: any) => {
				if (this.resourcesMap) {
					successCallback(
						this.resourcesMap[this.res].map((item: any) => {
							return {
								id: item.id,
								title:
									this.res === 'physician'
										? item.fullName
										: item.name,
								resourceName: this.res,
								groupId: item.id,
							};
						})
					);
				}
			},
			events: (info: any, successCallback: any, failureCallback: any) => {
				if (this.calendarApi)
					this._subs.push(
						this.service
							.getScheduledAppointments(
								this.res,
								this.calendarType,
								toMoment(info.start, this.calendarApi).format(
									'YYYYMMDD'
								),
								toMoment(info.end, this.calendarApi).format(
									'YYYYMMDD'
								)
							)
							.subscribe(successCallback)
					);
				else failureCallback.bind(this);
			},
		});
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	private updateEvent(eventRange: any, event: CalendarEvent) {
		const updatedEvent = assign(
			{},
			{
				id: event.spsId,
				startDate: toMoment(eventRange.start, this.calendarApi).format(
					'YYYY-MM-DD'
				),
				endDate: toMoment(eventRange.end, this.calendarApi).format(
					'YYYY-MM-DD'
				),
				startTime: toMoment(eventRange.start, this.calendarApi).format(
					'HH:mm'
				),
				endTime: toMoment(eventRange.end, this.calendarApi).format(
					'HH:mm'
				),
			}
		);

		if (this.calendarType === 'RDV')
			this._subs.push(
				this.service
					.updateAppointment(updatedEvent)
					.subscribe(result => {
						if (!result)
							console.error('Cannot update calendar event !');
						else
							this.snackBar.open(
								this.translate.instant('RDV_UPDATED'),
								'',
								{ duration: 1000 }
							);
					})
			);
	}

	changeDate() {
		this.picker.open();
	}

	moveEvents() {
		this.dateMovePicker.open();
	}

	moveToDate(event: any) {
		const ids = this.currentEvents
			.filter(event => event['selected'])
			?.map(it => it.id);

		if (ids.length === 0) {
			this.snackBar.open(this.translate.instant('NO_SELECTION'), '', {
				duration: 2000,
			});
			return;
		}
		const eventIds = ids.join('_');
		this._subs.push(
			this.service
				.moveEvents(
					eventIds,
					moment(event.value.toDate()).format('YYYYMMDD')
				)
				.subscribe(moved => {
					if (moved) {
						this.calendar.getApi().gotoDate(event.value.toDate());
						this.snackBar.open(
							this.translate.instant('EVENTS_MOVED'),
							'',
							{ duration: 2000 }
						);
						this.eventSelectionActivation.next(false);
					}
				})
		);
	}

	selectEvents(event: any) {
		const active = event.target.className.includes('fc-button-active');
		if (active) {
			event.target.classList.remove('fc-button-active');
			this.eventSelectionActivation.next(false);
		} else {
			event.target.classList.add('fc-button-active');
			this.eventSelectionActivation.next(true);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes['calendarSetting'] &&
			changes['calendarSetting'].currentValue
		)
			this.updateCalendarSetting(changes['calendarSetting'].currentValue);
	}

	handleEvents(events: EventApi[]) {
		this.currentEvents = events;
	}

	gotoDate(event: any) {
		this.calendar.getApi().gotoDate(event.value.toDate());
	}

	private createEvent(info: any) {
		const { resourceName, groupId: resourceId } = _get(
			info.resource,
			'_resource.extendedProps',
			{}
		);
		this.calendarApi.unselect(); // clear date selection

		if (this.calendarType === 'RDV')
			this._subs.push(
				this.dialog
					.open(AppointmentEditComponent, {
						data: {
							selectedDateRange: {
								start: toMoment(info.start, this.calendarApi),
								end: toMoment(info.end, this.calendarApi),
							},
							resource: { name: resourceName, id: resourceId },
						},
						disableClose: true,
					})
					.afterClosed()
					.subscribe(_ => {
						this.calendarApi.refetchEvents();
						this.calendarApi.render();
					})
			);
	}

	private clickDate(info: any) {
		if (info.view.name === 'month') {
			info.jsEvent.preventDefault();
			info.jsEvent.stopPropagation();

			this.calendar.getApi().changeView('timeGridDay');
		}
	}

	private clickEvent(info: any) {
		const calendarEvent = info.event._def.extendedProps;
		this.editEvent(calendarEvent);
	}

	selectEvent(calendarEvent: CalendarEvent) {
		calendarEvent.selected = !calendarEvent.selected;
		this.currentEvents.forEach(value => {
			if (value.id === calendarEvent.spsId?.toString())
				value['selected'] = calendarEvent.selected;
		});
	}

	private renderEvent(info: any) {
		if (
			[
				'timeGridWeek',
				'timeGridDay',
				'resourceTimeGridDay',
				'resourceTimeGrid2Day',
				'resourceTimelineDay',
			].includes(info.view.type)
		) {
			$(info.el).empty();

			const calendarEventComponentPortal =
				new ComponentPortal<CalendarEventComponent>(
					CalendarEventComponent
				);
			const _eventHost = new DomPortalOutlet(
				info.el,
				this._resolver,
				this._appRef,
				this._injector
			);
			const _cmpRef = _eventHost.attachComponentPortal(
				calendarEventComponentPortal
			);

			info.event.setExtendedProp('title', info.event.title);
			info.event.setExtendedProp('timeText', info.timeText);

			info.event._def.extendedProps['id'] = +info.event._def.publicId;

			_cmpRef.instance.event = info.event._def.extendedProps;
			_cmpRef.instance.viewType = info.view.type;
			_cmpRef.instance.user = JSON.parse(localStorage.getItem('user'));
			_cmpRef.instance.visible = this.calendarType === 'RDV';
			_cmpRef.instance.generalSetting = this.generalSetting;

			this.eventSelectionActivation.subscribe(
				value => (_cmpRef.instance.eventSelection = value)
			);

			_cmpRef.instance.deleteEvent.subscribe(calendarEvent =>
				this.deleteEvent(calendarEvent, _eventHost, info)
			);
			_cmpRef.instance.editEvent.subscribe(this.editEvent.bind(this));
			_cmpRef.instance.scheduleEvent.subscribe(
				this.scheduleExam.bind(this)
			);
			_cmpRef.instance.selectEvent.subscribe(this.selectEvent.bind(this));
			_cmpRef.instance.patientEditEvent.subscribe(calendarEvent =>
				this.router.navigate([
					'/patients/folder',
					calendarEvent.patientId,
				])
			);
		}
	}

	private deleteEvent(
		calendarEvent: CalendarEvent,
		_eventHost: DomPortalOutlet,
		info: any
	) {
		this._subs.push(
			this.dialog
				.open(DeleteConfirmComponent, { disableClose: true })
				.afterClosed()
				.subscribe(ok => {
					if (ok) {
						_eventHost.detach();
						info.event.remove();
						this._subs.push(
							this.service
								.deleteAppointment(calendarEvent.id)
								.subscribe(_ =>
									this.snackBar.open(
										this.translate.instant('RDV_DELETED'),
										'',
										{ duration: 2000 }
									)
								)
						);
					}
				})
		);
	}

	private scheduleExam(event: CalendarEvent) {
		this._subs.push(
			forkJoin([
				this.patientService.getPatientFull(+event.patientId),
				this.service.getAppointmentDTOById(+event.id),
			]).subscribe(data => {
				const [patient, appointmentDetails] = data;
				this.dialog
					.open(ExamSchedulerComponent, {
						data: {
							patient: patient,
							isr: appointmentDetails,
							selectedDateRange: {
								start: moment(),
								end: moment().add(15, 'h'),
							},
							editable: true,
							queryParam: null,
							panelClass: 'exam-dialog',
							is_external: true,
						},
						disableClose: true,
					})
					.afterClosed()
					.subscribe(dialogResponse => {
						if (dialogResponse) {
							if (
								Object.prototype.hasOwnProperty.call(
									dialogResponse,
									'isrId'
								)
							)
								return;
							this.service
								.updateAppointmentStatus(
									appointmentDetails.appointment.id,
									AptStatus.entered
								)
								.subscribe();
						}
					});
			})
		);
	}

	private editEvent(calEvent: CalendarEvent): void {
		if (calEvent.id && this.calendarType === 'RDV')
			this._subs.push(
				this.service
					.getAppointmentDTOById(calEvent.id)
					.subscribe(appointmentDetails => {
						this._subs.push(
							this.dialog
								.open(AppointmentEditComponent, {
									data: appointmentDetails,
									disableClose: true,
								})
								.afterClosed()
								.subscribe(res => {
									if (res) {
										this.snackBar.open(
											this.translate.instant(
												'RDV_UPDATED'
											),
											'',
											{ duration: 2000 }
										);
										this.calendarApi.refetchEvents();
									}
								})
						);
					})
			);
	}

	ngAfterViewInit(): void {
		this.calendarApi = this.calendar.getApi();
		this.calendarApi.refetchEvents();

		this.createResourceFilterMenu();
		this.createCalendarFilterMenu();
	}

	private createResourceFilterMenu() {
		const fc = document.getElementsByClassName(
			'fc-resourceSelect-button'
		)[0];

		$(fc).empty();

		const _mcp = new ComponentPortal(FtMenuComponent);
		const _menuHost = new DomPortalOutlet(
			fc,
			this._resolver,
			this._appRef,
			this._injector
		);
		const _cmpRef = _menuHost.attachComponentPortal(_mcp);

		_cmpRef.instance.listItem = RESOURCE_TYPES;
		_cmpRef.instance.menuItemSelect.subscribe(res => {
			this.res = res;
			this.calendar.getApi().refetchResources();
			this.calendar.getApi().refetchEvents();
			this.calendar.getApi().render();
		});
	}

	private createCalendarFilterMenu() {
		const fc = document.getElementsByClassName(
			'fc-calendarSelect-button'
		)[0];

		$(fc).empty();

		const _mcp = new ComponentPortal(FtMenuComponent);
		const _menuHost = new DomPortalOutlet(
			fc,
			this._resolver,
			this._appRef,
			this._injector
		);
		const _cmpRef = _menuHost.attachComponentPortal(_mcp);

		_cmpRef.instance.menuType = 'calendar';
		_cmpRef.instance.listItem = CALENDAR_TYPES;
		_cmpRef.instance.menuItemSelect.subscribe(calendarType => {
			this.calendarType = calendarType;
			this.calendar.getApi().refetchResources();
			this.calendar.getApi().refetchEvents();
			this.calendar.getApi().render();
		});
	}

	private updateCalendarSetting(calendarSetting: CalendarSettingDTO) {
		if (calendarSetting) {
			const closingDays = calendarSetting.closingDays
				?.split(';')
				.map(cd => Number(cd.charAt(1)));
			this.businessHours.forEach(businessHour =>
				businessHour.daysOfWeek.push(
					...[1, 2, 3, 4, 5, 6].filter(d => !closingDays.includes(d))
				)
			);

			assign(this.calendarOptions, {
				businessHours: this.businessHours,
				slotMinTime: calendarSetting.openingTime,
				slotMaxTime: calendarSetting.closingTime,
				slotDuration: calculateSlotDuration(
					calendarSetting.minTimeSlot
				),
			});

			this.calendarApi.changeView(
				targetView(calendarSetting.defaultView)
			);
		}
	}
}
