@if (dataSource.data) {
	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
		<!-- This is the tree node template for leaf nodes -->
		<mat-tree-node
			*matTreeNodeDef="let node"
			matTreeNodeToggle
			[class.image-node]="!node.folder">
			<button mat-icon-button disabled>
				@if (node.folder) {
					<mat-icon
						fontSet="mdi"
						[fontIcon]="'mdi-folder'"></mat-icon>
				}
			</button>
			@if (!node.folder) {
				<img
					height="72px"
					width="72px"
					[src]="node.url"
					[alt]="node.name"
					(click)="openImage(node)" />
			} @else {
				<div
					class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4 fx-fill-width">
					<div
						class="fx-grow-1 fx-layout-column fx-content-start fx-items-center">
						<div
							class="fx-layout-row-nowrap fx-content-start fx-items-center fx-fill-width fx-gap-12">
							<span>{{ node.name }}</span>
							@if (pacsPatientID === node.pacsPatientID) {
								<span
									style="
										font-style: italic;
										color: #239aff;
										font-size: 12px;
									"
									>~{{ 'CURRENT' | translate }}</span
								>
							}
						</div>
						<span
							style="
								font-size: 11px;
								font-style: italic;
								color: #718a97;
								font-weight: 500;
							"
							>{{ node.studyDateTime | date: 'dd/MM/yyyy' }}</span
						>
					</div>

					@if (node.loading) {
						<mat-progress-bar
							color="accent"
							class="fx-grow-1"
							mode="indeterminate"></mat-progress-bar>
					}
					@if (node.studyAvailability === 'AVAILABLE') {
						<button
							mat-icon-button
							[matTooltip]="'DOWNLOAD' | translate"
							(click)="retrieveImagesIfNotAvailable(node)">
							<mat-icon
								class="download-icon-gradient"
								fontSet="mdi"
								fontIcon="mdi-cloud-download"></mat-icon>
						</button>
					}
				</div>
			}
			<ng-template #name>
				<div
					class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4 fx-fill-width">
					<div
						class="fx-grow-1 fx-layout-column fx-content-start fx-items-center">
						<div
							class="fx-layout-row-nowrap fx-content-start fx-items-center fx-fill-width fx-gap-12">
							<span>{{ node.name }}</span>
							@if (pacsPatientID === node.pacsPatientID) {
								<span
									style="
										font-style: italic;
										color: #239aff;
										font-size: 12px;
									"
									>~{{ 'CURRENT' | translate }}</span
								>
							}
						</div>
						<span
							style="
								font-size: 11px;
								font-style: italic;
								color: #718a97;
								font-weight: 500;
							"
							>{{ node.studyDateTime | date: 'dd/MM/yyyy' }}</span
						>
					</div>

					@if (node.loading) {
						<mat-progress-bar
							color="accent"
							class="fx-grow-1"
							mode="indeterminate"></mat-progress-bar>
					}
					@if (node.studyAvailability === 'AVAILABLE') {
						<button
							mat-icon-button
							[matTooltip]="'DOWNLOAD' | translate"
							(click)="retrieveImagesIfNotAvailable(node)">
							<mat-icon
								class="download-icon-gradient"
								fontSet="mdi"
								fontIcon="mdi-cloud-download"></mat-icon>
						</button>
					}
				</div>
			</ng-template>
		</mat-tree-node>

		<!-- This is the tree node template for expandable nodes -->
		<mat-nested-tree-node
			*matTreeNodeDef="let node; when: hasChild"
			[style.paddingLeft.px]="node.level === 0 ? 0 : 24">
			<div class="mat-tree-node">
				<button
					mat-icon-button
					[attr.aria-label]="'Toggle ' + node.name"
					matTreeNodeToggle>
					<mat-icon
						class="list-icon-gradient"
						fontSet="mdi"
						[fontIcon]="
							treeControl.isExpanded(node)
								? 'mdi-folder-open'
								: 'mdi-folder'
						"></mat-icon>
				</button>
				<div
					class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4 fx-fill-width">
					<div
						class="fx-grow-1 fx-layout-column fx-content-start fx-items-center">
						<div
							class="fx-layout-row-nowrap fx-content-start fx-items-center fx-fill-width fx-gap-12">
							<span style="font-weight: 500; color: #334b56">{{
								node.name
							}}</span>
							@if (
								pacsPatientID === node.pacsPatientID &&
								node.level === 0
							) {
								<span
									style="
										font-style: italic;
										color: #239aff;
										font-size: 12px;
									"
									>~{{ 'CURRENT' | translate }}</span
								>
							}
						</div>
						<span
							style="
								font-size: 11px;
								font-style: italic;
								color: #718a97;
								font-weight: 500;
							"
							>{{ node.studyDateTime | date: 'dd/MM/yyyy' }}</span
						>
					</div>
					@if (node.loading && node.level === 0) {
						<mat-progress-bar
							color="accent"
							class="fx-grow-1"
							mode="indeterminate"></mat-progress-bar>
					}
					@if (node.level === 0) {
						<button
							mat-icon-button
							[matTooltip]="'REFRESH' | translate"
							(click)="cleanAndRetrieve(node)">
							<mat-icon
								[style.color]="'#11a0ac'"
								fontSet="mdi"
								fontIcon="mdi-refresh-circle"></mat-icon>
						</button>
					}
				</div>
			</div>
			<div
				[style.display]="
					!treeControl.isExpanded(node) ? 'none' : 'grid'
				"
				[class.images-grid]="isImageContainer(node)">
				<ng-container matTreeNodeOutlet></ng-container>
			</div>
		</mat-nested-tree-node>
	</mat-tree>
} @else {
	<div
		[style.height.%]="100"
		class="fx-layout-column fx-content-center fx-items-center fx-gap-16">
		<span style="font-size: 14px; font-weight: bold">{{
			'RETRIEVING_IMAGES' | translate
		}}</span>
		<mat-spinner
			[diameter]="100"
			[strokeWidth]="5"
			color="warn"></mat-spinner>
	</div>
}
