import { assign } from 'lodash';

export const PLUGINS_GUIDS = {
	SAR: 'asc.{C820F3CC-1248-4CA2-8D0F-D7EFD44DE09C}',
	INV: 'asc.{C820F3CC-12A8-A5A2-8D0F-D6EFD66DD80C}',
	ACM: 'asc.{936a63ef-6694-4d76-a4e2-529765045627}',
	CCL: 'asc.{1515SA93-5DBE-4B6B-A0A9-455C4F1503AK}',
};

export function ContentControl(ccType: any, data: any): string {
	return JSON.stringify({
		frameEditorId: 'reporter',
		guid: PLUGINS_GUIDS.CCL,
		type: 'onExternalPluginMessage',
		data: assign(
			{
				type: ccType,
			},
			data
		),
	});
}

export function SearchAndReplace(key: string, value: string): string {
	return JSON.stringify({
		frameEditorId: 'reporter',
		guid: PLUGINS_GUIDS.SAR,
		type: 'onExternalPluginMessage',
		data: {
			type: 'searchAndReplace',
			text: [
				{
					searchString: key,
					replaceString: value,
					matchCase: true,
				},
			],
		},
	});
}

export function InsertVariable(key: string): string {
	return JSON.stringify({
		frameEditorId: 'reporter',
		guid: PLUGINS_GUIDS.INV,
		type: 'onExternalPluginMessage',
		data: {
			type: 'insertVariable',
			text: key,
		},
	});
}

export function InitAutocompleteDictionary(data: string[]): string {
	return JSON.stringify({
		frameEditorId: 'reporter',
		guid: PLUGINS_GUIDS.ACM,
		type: 'onExternalPluginMessage',
		data: {
			type: 'uploadDictionary',
			text: data,
		},
	});
}

export function ClosePlugin(guid: string): string {
	return JSON.stringify({
		frameEditorId: 'reporter',
		guid: PLUGINS_GUIDS[guid],
		type: 'onExternalPluginMessage',
		data: { type: 'close' },
	});
}
