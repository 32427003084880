@switch (viewType) {
	@case ('resourceTimelineDay') {
		<div
			class="fc-content-timeline fx-layout-row-nowrap fx-content-space-between fx-items-start fx-fill"
			style="overflow: hidden"
			[style.border-left-color]="event['examColor'] || '#e0e0e0'">
			<div
				style="width: calc(100% - 30px)"
				class="fx-layout-column event-text">
				<div style="text-shadow: 1px 1px 2px black">
					@if (event.urgent) {
						<mat-icon
							style="color: #ff4f01"
							fontSet="mdi"
							fontIcon="mdi-ambulance"></mat-icon>
					}
					{{ event.patientName }}
				</div>
				<div>{{ event.patientID }}</div>
				<div>{{ event.timeText }}</div>
				<div>{{ event.title }}</div>
				<div>{{ event.performingPhysician }}</div>
			</div>
			<button
				style="width: 26px"
				(click)="$event.stopPropagation()"
				mat-icon-button
				[matMenuTriggerFor]="menu"
				aria-label="Event menu">
				<mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
			</button>
		</div>
	}
	@default {
		<div
			class="fc-content fx-layout-row-nowrap fx-content-space-between fx-items-start fx-fill fx-overflow-hidden"
			[style.border-left-color]="event['examColor'] || '#e0e0e0'">
			@if (eventSelection) {
				<div
					(click)="
						$event.stopImmediatePropagation();
						selectEvent.next(event)
					"
					class="event-select fx-layout-row fx-content-center fx-items-center">
					<mat-icon
						[fontIcon]="event.selected ? 'mdi-check-circle' : ''"
						fontSet="mdi"></mat-icon>
				</div>
			}
			<div
				style="width: calc(100% - 30px)"
				class="fx-layout-column event-text">
				<div style="text-shadow: 1px 1px 2px black">
					@if (event.urgent) {
						<mat-icon
							style="color: #ff4f01"
							fontSet="mdi"
							fontIcon="mdi-ambulance"></mat-icon>
					}
					{{ event.patientName }}
				</div>
				<div style="color: lightgrey; font-style: italic">
					{{ event.patientID }}
				</div>
				<div>{{ event.timeText }}</div>
				<div>{{ event.title }}</div>
				<div>{{ event.performingPhysician }}</div>
			</div>
			@if (visible) {
				<button
					style="width: 26px"
					(click)="$event.stopPropagation()"
					mat-icon-button
					[matMenuTriggerFor]="menu"
					aria-label="Event menu">
					<mat-icon
						fontSet="mdi"
						fontIcon="mdi-dots-vertical"></mat-icon>
				</button>
			}
		</div>
	}
}

<!--    Event menu -->
<mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="true">
	<button
		(click)="scheduleEvent.next(event)"
		[disabled]="
			user.profile['scheduleExam'] === 'NONE' ||
			['entered', 'planned'].includes(event.spsStatus)
		"
		mat-menu-item>
		<mat-icon fontIcon="mdi-calendar" fontSet="mdi"></mat-icon>
		<span>{{ 'SCHEDULE_EXAM' | translate }}</span>
	</button>
	<button
		(click)="editEvent.next(event)"
		[disabled]="
			user.profile['editExam'] === 'NONE' ||
			['entered', 'planned'].includes(event.spsStatus)
		"
		mat-menu-item>
		<mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
		<span>{{ 'EDIT' | translate }}</span>
	</button>
	<button
		(click)="patientEditEvent.next(event)"
		[disabled]="user.profile['viewPatientFolderCalendar'] === 'NONE'"
		mat-menu-item>
		<mat-icon fontIcon="mdi-folder-account" fontSet="mdi"></mat-icon>
		<span>{{ 'PATIENT_FOLDER' | translate }}</span>
	</button>
	<mat-divider></mat-divider>
	<button
		mat-menu-item
		[disabled]="user.profile['cancelOrder'] === 'NONE'"
		(click)="deleteEvent.next(event)">
		<mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
		<span>{{ 'DELETE' | translate }}</span>
	</button>
</mat-menu>
