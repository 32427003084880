import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRipple } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { CancelFormComponent } from './cancel-form/cancel-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicFormModule } from './dynamic-form';
import { LocalDatePipe } from './local-date.pipe';
import { ViewerSelectComponent } from './viewer-select/viewer-select.component';
import { RdvPrintComponent } from './rdv-print/rdv-print.component';
import { CompleteExamFormComponent } from './complete-exam-form/complete-exam-form.component';
import { PatientArrivedComponent } from './patient-arrived/patient-arrived.component';
import { ReferringPhysicianAddComponent } from './referring-physician-add/referring-physician-add.component';
import { FileExplorerModule } from './file-explorer';
import { SchedulerService } from '../scheduler/scheduler.service';
import { WsService } from '../ws.service';
import { PrescriptionComponent } from './prescription';
import { MessageComponent } from './message/message.component';
import { PerformerAssignComponent } from './performer-assign/performer-assign.component';
import { TemplateSearchComponent } from './template-search/template-search.component';
import { CommentsComponent } from './comments/comments.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableViewComponent } from './table-view/table-view.component';
// import {PlyrComponent, PlyrModule} from 'ngx-plyr';
import { NormalReportComponent } from './normal-report/normal-report.component';
import { NewCategoryComponent } from '../setting/report-template-setting/report-template-setting.component';
import { PacsSearchComponent } from './pacs-search/pacs-search.component';
import { TableLineComponent } from './table-line/table-line.component';
import { ExcelExamComponent } from './excel-exam/excel-exam.component';
import { BookletPrintComponent } from './booklet-print/booklet-print.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { ValueDescriptionComponent } from './value-description/value-description.component';
import { DeleteConfirmComponent } from './delete-confirm/delete-confirm.component';
import { PrintCountComponent } from './print-count/print-count.component';
import { TechniqueEditComponent } from './technique-edit/technique-edit.component';
import { StockMovementComponent } from './stock-movement/stock-movement.component';
import { StockArticlesComponent } from './stock-articles/stock-articles.component';
import { ArticleLotsComponent } from './article-lots/article-lots.component';
import { ArticleSerialsComponent } from './article-serials/article-serials.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormattingRuleComponent } from './formatting-rule/formatting-rule.component';
import { TableFormatterComponent } from './table-formatter/table-formatter.component';
import { IconSelectComponent } from './icon-select/icon-select.component';
import { FormattingStyleLineComponent } from './formatting-style-line/formatting-style-line.component';
import { CalendarEventComponent, FtMenuComponent } from './ft-calendar';
import { UserUpdateComponent } from './user-update/user-update.component';
import { ngfModule } from 'angular-file';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ViewportDirective } from './viewport.directive';
import { ReferringPhysiciansSearchComponent } from './referring-physicians-search/referring-physicians-search.component';
import {
	FilterByPipe,
	GroupByPipe,
	InitialsPipe,
	NumericPipe,
	SlicePipe,
	SumWorkItemByPipe,
} from './pipes';
import { SmsSenderComponent } from './sms-sender/sms-sender.component';
import { ReportParagraphComponent } from './report-paragraph/report-paragraph.component';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { StudyExplorerComponent } from './study-explorer/study-explorer.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { StudyViewerComponent } from './study-viewer/study-viewer.component';
import { NewTemplateComponent } from './new-template/new-template.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { PrintingHistoryComponent } from './printing-history/printing-history.component';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { BarcodeSearchComponent } from './barcode-search/barcode-search.component';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { DocReaderComponent } from './doc-reader/doc-reader.component';
import { LabelEditComponent } from './label-edit/label-edit.component';
import { LabelsPopupComponent } from './labels-popup/labels-popup.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PatientListComponent } from './patient-list/patient-list.component';
import { FtChartComponent } from './ft-chart/ft-chart.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CdkTableModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatNativeDateModule,
		MatMenuModule,
		MatCheckboxModule,
		MatIconModule,
		MatInputModule,
		MatCardModule,
		MatButtonModule,
		MatProgressBarModule,
		MatRadioModule,
		MatDialogModule,
		MatOptionModule,
		MatSelectModule,
		MatListModule,
		MatTabsModule,
		MatSidenavModule,
		MatDatepickerModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		MatButtonToggleModule,
		TranslateModule,
		MatTooltipModule,
		DynamicFormModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatToolbarModule,
		FileExplorerModule,
		MatTreeModule,
		// PlyrModule,
		DragDropModule,
		MatExpansionModule,
		ColorPickerModule,
		ngfModule,
		MatBottomSheetModule,
		MatIconModule,
		CdkTreeModule,
		FullCalendarModule,
		MatRipple,
	],
	declarations: [
		CancelFormComponent,
		LocalDatePipe,
		ViewerSelectComponent,
		RdvPrintComponent,
		CompleteExamFormComponent,
		PatientArrivedComponent,
		CompleteExamFormComponent,
		ReferringPhysicianAddComponent,
		PrescriptionComponent,
		MessageComponent,
		PerformerAssignComponent,
		TemplateSearchComponent,
		CommentsComponent,
		TableViewComponent,
		NormalReportComponent,
		NewCategoryComponent,
		PacsSearchComponent,
		TableLineComponent,
		ExcelExamComponent,
		BookletPrintComponent,
		PrintLayoutComponent,
		ValueDescriptionComponent,
		DeleteConfirmComponent,
		PrintCountComponent,
		TechniqueEditComponent,
		StockMovementComponent,
		StockArticlesComponent,
		ArticleLotsComponent,
		ArticleSerialsComponent,
		FormattingRuleComponent,
		TableFormatterComponent,
		IconSelectComponent,
		FormattingStyleLineComponent,
		CalendarEventComponent,
		FtMenuComponent,
		UserUpdateComponent,
		ViewportDirective,
		ReferringPhysiciansSearchComponent,
		FilterByPipe,
		InitialsPipe,
		GroupByPipe,
		NumericPipe,
		SumWorkItemByPipe,
		SlicePipe,
		SmsSenderComponent,
		ReportParagraphComponent,
		ReportDialogComponent,
		StudyExplorerComponent,
		PaymentFormComponent,
		MessageDialogComponent,
		StudyViewerComponent,
		NewTemplateComponent,
		TasksListComponent,
		PrintingHistoryComponent,
		SelectDialogComponent,
		BarcodeSearchComponent,
		PharmacyComponent,
		DocReaderComponent,
		LabelEditComponent,
		LabelsPopupComponent,
		PatientListComponent,
		FtChartComponent,
	],
	exports: [
		CdkTableModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatNativeDateModule,
		MatMenuModule,
		MatCheckboxModule,
		MatIconModule,
		MatInputModule,
		MatProgressBarModule,
		MatButtonModule,
		MatRadioModule,
		MatDialogModule,
		MatSelectModule,
		MatCardModule,
		MatChipsModule,
		MatProgressSpinnerModule,
		FileExplorerModule,
		MatListModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatDatepickerModule,
		MatSidenavModule,
		MatTooltipModule,
		MatAutocompleteModule,
		FullCalendarModule,
		TranslateModule,
		DynamicFormModule,
		MatButtonToggleModule,
		MatGridListModule,
		MatToolbarModule,
		CancelFormComponent,
		ViewerSelectComponent,
		CompleteExamFormComponent,
		RdvPrintComponent,
		PatientArrivedComponent,
		ReferringPhysicianAddComponent,
		LocalDatePipe,
		PrescriptionComponent,
		TemplateSearchComponent,
		MessageComponent,
		PerformerAssignComponent,
		CommentsComponent,
		NormalReportComponent,
		NewCategoryComponent,
		DragDropModule,
		// PlyrModule,
		// PlyrComponent,
		MatTreeModule,
		PacsSearchComponent,
		ExcelExamComponent,
		BookletPrintComponent,
		ValueDescriptionComponent,
		DeleteConfirmComponent,
		FormattingRuleComponent,
		TableFormatterComponent,
		CalendarEventComponent,
		UserUpdateComponent,
		FilterByPipe,
		InitialsPipe,
		GroupByPipe,
		NumericPipe,
		SumWorkItemByPipe,
		SlicePipe,
		ReportDialogComponent,
		StudyExplorerComponent,
		PaymentFormComponent,
		StockMovementComponent,
		PharmacyComponent,
		DocReaderComponent,
		LabelsPopupComponent,
		FtChartComponent,
		ReportParagraphComponent,
	],
	providers: [SchedulerService, WsService],
})
export class FireSharedModule {}
