<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-store"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'PHARMACY' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-24 fx-overflow-auto" style="max-height: 70vh">
	@if (workflowItem) {
		<form [formGroup]="stockForm">
			<div
				class="fx-layout-row-nowrap fx-content-start fx-items-center row2 fx-layout-row-nowrap">
				<div style="width: 180px">Patient</div>
				<div class="fx-grow-1">{{ workflowItem.patientName }}</div>
			</div>
			<div
				class="fx-layout-row-nowrap fx-content-start fx-items-center row2 fx-layout-row-nowrap">
				<div style="width: 180px">
					{{ 'PROCEDURE_CODE' | translate }}
				</div>
				<div class="fx-grow-1">{{ workflowItem.procedureCode }}</div>
			</div>

			<div
				[style.marginTop.px]="16"
				[style.marginBottom.px]="16"
				class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-12">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>{{ 'STORE' | translate }}</mat-label>
					<mat-select
						[placeholder]="'STORE' | translate"
						formControlName="store">
						@for (store of stores; track store) {
							<mat-option [value]="store.id">{{
								store.name
							}}</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<div class="fx-layout-column-nowrap">
					<h5 style="margin: 0 8px">
						{{ 'PRICE_MODE' | translate }}
					</h5>

					<mat-radio-group
						color="primary"
						formControlName="priceMode"
						class="fx-layout-row fx-content-start fx-items-end">
						@for (option of ['HT', 'TTC']; track option) {
							<mat-radio-button
								[value]="option"
								class="fx-grow-1">
								{{ option }}
							</mat-radio-button>
						}
					</mat-radio-group>
				</div>

				<span class="fx-grow-1"></span>
				<button
					(click)="addNewArticle()"
					[disabled]="formDisabled"
					color="primary"
					mat-button>
					<mat-icon
						fontIcon="mdi-text-box-plus"
						fontSet="mdi"></mat-icon>
					{{ 'ARTICLE' | translate }}
				</button>
			</div>

			<!--        Table-->
			<div [style.marginTop.px]="4" class="limiter">
				<div class="mat-elevation-z3">
					<div class="table">
						<div class="row header">
							<div class="cell">
								{{ 'CODE' | translate }}
							</div>
							<div class="cell">
								{{ 'DESIGNATION' | translate }}
							</div>
							<div class="cell">
								{{ 'PRICE' | translate }}
							</div>
							<div class="cell">
								{{ 'QUANTITY' | translate }}
							</div>
							<div class="cell">
								{{ 'VAT' | translate }}
							</div>
							<div class="cell">
								{{ 'STORE' | translate }}
							</div>
							<div class="cell">
								{{ 'SUB_TOTAL' | translate }}
							</div>
							<div class="cell"></div>
						</div>

						@for (article of articles; track article) {
							<div class="row">
								<div class="cell">{{ article.code }}</div>
								<div class="cell">
									{{ article.description }}
								</div>
								<div class="cell">
									{{ formatNumeral(article.price) }}
								</div>
								<div class="cell">
									<input
										class="ft-input ft-qty"
										(keyup)="updateArticle(article)"
										[(ngModel)]="article.quantity"
										[disabled]="formDisabled"
										[ngModelOptions]="{ standalone: true }"
										type="number" />
								</div>
								<div class="cell">
									<select
										class="ft-input ft-vat"
										(change)="updateArticle(article)"
										[(ngModel)]="article.vatAmount"
										[disabled]="formDisabled"
										[ngModelOptions]="{ standalone: true }">
										@for (
											vatAmount of vatAmounts;
											track vatAmount
										) {
											<option [value]="vatAmount">
												{{ vatAmount }}%
											</option>
										}
									</select>
								</div>
								<div class="cell">
									<select
										class="ft-input ft-store"
										(change)="updateArticle(article)"
										[(ngModel)]="article.store"
										[disabled]="formDisabled"
										[ngModelOptions]="{ standalone: true }">
										@for (store of stores; track store) {
											<option [value]="store.id">
												{{ store.name }}
											</option>
										}
									</select>
								</div>
								<div class="cell">
									{{ formatNumeral(article.totalPrice) }}
								</div>
								<div
									class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4">
									@if (article.hasLots) {
										<button
											(click)="selectLotNumbers(article)"
											[disabled]="formDisabled"
											class="row-button"
											mat-button>
											<mat-icon
												[style.color]="
													article.lots === null ||
													lotsTotalQuantity(
														article.lots
													) !== article.quantity
														? '#ff1111'
														: formDisabled
															? '#e0e0e0'
															: '#238748'
												"
												fontIcon="mdi-alpha-l-box"
												fontSet="mdi"></mat-icon>
										</button>
									}

									@if (article.hasSerials) {
										<button
											(click)="
												selectSerialNumbers(article)
											"
											[disabled]="formDisabled"
											class="row-button"
											mat-button>
											<mat-icon
												[style.color]="
													article.serials === null ||
													article.serials.split(',')
														.length !==
														article.quantity
														? '#ff1111'
														: formDisabled
															? '#e0e0e0'
															: '#238748'
												"
												fontIcon="mdi-barcode"
												fontSet="mdi"></mat-icon>
										</button>
									}

									<button
										(click)="removeArticle(article)"
										[disabled]="formDisabled"
										class="row-button"
										mat-button>
										<mat-icon
											[style.color]="
												formDisabled
													? '#e0e0e0'
													: '#fa4d77'
											"
											fontIcon="mdi-close-circle"
											fontSet="mdi"></mat-icon>
									</button>
								</div>
							</div>
						}

						@if (!articles.length) {
							<div class="row">
								<div class="no-cell">
									<button
										(click)="addNewArticle()"
										[disabled]="formDisabled"
										color="primary"
										mat-button>
										<mat-icon
											fontIcon="mdi-text-box-plus"
											fontSet="mdi"></mat-icon>
										Article
									</button>
								</div>
							</div>
						}
					</div>
				</div>
			</div>

			<div
				class="fx-layout-row-nowrap fx-content-end fx-items-start fx-gap-4">
				<mat-form-field style="width: 120px">
					<mat-label>{{
						'DISCOUNT_PERCENTAGE' | translate
					}}</mat-label>
					<input
						(keyup)="changeDiscountPrice($event)"
						[max]="100"
						[placeholder]="'DISCOUNT_PERCENTAGE' | translate"
						formControlName="globalDiscountPercentage"
						matInput
						type="number" />
				</mat-form-field>
				<mat-form-field style="width: 96px">
					<mat-label>{{
						'DISCOUNT_PRICE'
							| translate: { currency: currencyFormat }
					}}</mat-label>
					<input
						(keyup)="changeDiscountPercentage($event)"
						[placeholder]="'DISCOUNT_PRICE' | translate"
						formControlName="globalDiscount"
						matInput
						type="number" />
				</mat-form-field>
			</div>

			<div
				class="fx-layout-row-nowrap fx-content-space-between fx-items-start fx-gap-12">
				<mat-form-field class="fx-grow-1">
					<mat-label>{{ 'COMMENT' | translate }}</mat-label>
					<textarea
						[placeholder]="'COMMENT' | translate"
						formControlName="comment"
						matInput
						maxLength="200"></textarea>
					<mat-hint
						[style.fontWeight]="'bold'"
						[style.paddingTop.px]="4"
						align="end"
						>{{ stockForm.value['comment']?.length || 0 }} / 200
					</mat-hint>
				</mat-form-field>

				<div style="width: 220px">
					<div
						class="fx-layout-row-nowrap fx-content-start fx-items-center row2 right-align">
						<div style="width: 120px">
							{{ 'TOTAL_HT' | translate }}
						</div>
						<div style="width: 100px">
							{{ formatNumeral(totalHT) }}
						</div>
					</div>
					<div
						class="fx-layout-row-nowrap fx-content-start fx-items-center row2 right-align">
						<div style="width: 120px">
							{{ 'TOTAL_VAT' | translate }}
						</div>
						<div style="width: 100px">
							{{ formatNumeral(totalVAT) }}
						</div>
					</div>
					<div
						class="fx-layout-row-nowrap fx-content-start fx-items-center row2 right-align">
						<div style="width: 120px">
							{{ 'TOTAL_TTC' | translate }}
						</div>
						<div style="width: 100px">
							{{ formatNumeral(totalTTC) }}
						</div>
					</div>
				</div>
			</div>
		</form>
	}
</div>

@if (stockForm.value.valid) {
	<div [class.approved]="true">
		<img
			alt="Validé"
			height="120px"
			src="../../../assets/images/valid.png" />
	</div>
}

<mat-dialog-actions class="fx-layout-row-nowrap fx-gap-4">
	<button
		(click)="save(stockForm.value, true)"
		[disabled]="formDisabled"
		color="primary"
		mat-raised-button
		style="border: none !important">
		{{ 'SAVE_DRAFT' | translate }}
	</button>
	<span class="fx-grow-1"></span>
	<button color="warn" [matDialogClose]="null" mat-raised-button>
		{{ 'CLOSE' | translate }}
	</button>
	@if (formDisabled) {
		<button
			(click)="unValidate(stockForm.value)"
			color="warn"
			mat-raised-button>
			{{ 'UN_VALIDATE' | translate }}
		</button>
	}
	<button
		(click)="validate(stockForm.value)"
		[disabled]="!isFormValid || formDisabled"
		color="primary"
		mat-raised-button>
		{{ 'VALIDATE' | translate }}
	</button>
</mat-dialog-actions>
