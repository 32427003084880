<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-comment"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">{{ 'COMMENT' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-24">
	<mat-form-field class="fx-fill-width">
		<mat-label>{{ 'COMMENT' | translate }}</mat-label>
		<textarea
			matInput
			[formControl]="commentControl"
			[placeholder]="'COMMENT' | translate"></textarea>
	</mat-form-field>
</div>
<mat-dialog-actions align="end">
	<button mat-raised-button color="warn" matDialogClose="dismiss">
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="saveComments()"
		color="primary"
		mat-raised-button
		tabindex="-1">
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
