import {
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import Hammer from 'hammerjs';
import { ToolService } from './tool.service';
import { TOOL_MODE_FUNCTIONS } from '../model';

declare let cornerstoneTools: any;
declare const cornerstone: any;
declare const cornerstoneWebImageLoader: any;
declare const cornerstoneMath: any;

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[viewport]',
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		unselectable: 'on',
		onmousedown: 'return false;',
		oncontextmenu: 'return false',
		onselectstart: 'return false;',
	},
})
export class ViewportDirective implements OnInit, OnDestroy, OnChanges {
	@Input('viewport') public image: any;
	@Input() public synchronized: boolean;
	@Input() public images: any;

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onStackScroll: EventEmitter<any> = new EventEmitter<any>();

	public readonly host_origin = location.origin;

	private element: any;
	private stack: { currentImageIdIndex: number; imageIds: any };

	constructor(
		private el: ElementRef,
		private _toolService: ToolService
	) {
		cornerstoneWebImageLoader.external.cornerstone = cornerstone;
		cornerstone.registerImageLoader(
			'http',
			cornerstoneWebImageLoader.loadImage
		);
		cornerstone.registerImageLoader(
			'https',
			cornerstoneWebImageLoader.loadImage
		);

		cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
		cornerstoneTools.external.cornerstone = cornerstone;
		cornerstoneTools.external.Hammer = Hammer;

		cornerstoneTools.init({
			showSVGCursors: true,
			globalToolSyncEnabled: true,
		});

		cornerstoneTools.toolColors.setActiveColor('rgb(0,248,103)');

		this._toolService.toolChanged.subscribe(tool => {
			cornerstoneTools.setToolActiveForElement(this.element, tool, {
				mouseButtonMask: 1,
			});
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		const { synchronized } = changes;
		this._synchronize(synchronized?.currentValue);
	}

	ngOnInit() {
		this.element = this.el.nativeElement;
		cornerstone.enable(this.element);

		this.element.addEventListener(cornerstone.EVENTS.NEW_IMAGE, event =>
			this.onStackScroll.emit({
				currentIndex: this.stack.currentImageIdIndex,
				imageUrl: event.detail.image.imageId,
			})
		);

		this.element.tabIndex = 0;
		this.element.focus();

		this.stack = {
			currentImageIdIndex: 0,
			imageIds: this.images?.map(it => `${this.host_origin}${it}`),
		};

		let sep = '';
		if (this.image.url.startsWith('images')) sep = '/';

		const imageUrl = `${this.host_origin}${sep}${this.image.url}`;

		cornerstone.loadImage(imageUrl).then((image: any) => {
			cornerstone.displayImage(this.element, image);
			cornerstone.resize(this.element, true);
			this._initCornerstoneTools();
			if (this.synchronized) this._synchronize();

			if (this.images?.length > 1) {
				cornerstoneTools.addStackStateManager(this.element, ['stack']);
				cornerstoneTools.addToolState(
					this.element,
					'stack',
					this.stack
				);
			}
		});
	}

	ngOnDestroy() {
		cornerstone.disable(this.element);
		this.element.removeEventListener(cornerstone.EVENTS.NEW_IMAGE, _ => {});
	}

	private _synchronize(is_sync: boolean = false) {
		if (is_sync) {
			this._toolService.synchronizer.add(this.element);
			this._toolService.wwwcSynchronizer.add(this.element);
			this._toolService.panZoomSynchronizer.add(this.element);
		} else {
			this._toolService.synchronizer.remove(this.element);
			this._toolService.wwwcSynchronizer.remove(this.element);
			this._toolService.panZoomSynchronizer.remove(this.element);
		}
	}

	private _initCornerstoneTools() {
		this._toolService.availableTools.forEach(tool => {
			cornerstoneTools.addToolForElement(this.element, tool.class, {
				configuration: tool.configuration || {},
			});
			if (tool.mode) {
				const setToolActiveModeFn = TOOL_MODE_FUNCTIONS[tool.mode];
				setToolActiveModeFn(
					this.element,
					tool.name,
					tool.options || {}
				);
			}
		});
	}
}
