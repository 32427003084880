import {
	Component,
	ElementRef,
	inject,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { SettingService } from '../setting.service';
import { CommandService, TemplateModelDTO } from '../../model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { flatMap, groupBy, sortBy } from 'lodash';
import {
	DeleteConfirmComponent,
	ExcelExamComponent,
	getAppType,
	SharedService,
} from '../../shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	MatTreeFlatDataSource,
	MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import moment from 'moment';
import { ReportingService } from '../../reporting/reporting.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { variables } from '../../utils/editor';
import { ContentControl } from '../../shared/reporter/utils';
import { AppConfigService } from '../../app-config.service';

interface TemplateNode {
	name: string;
	children?: TemplateNode[];
}

interface FlatTemplateNode {
	expandable: boolean;
	name: string;
	level: number;
}

@Component({
	selector: 'ft-report-template-setting',
	templateUrl: './report-template-setting.component.html',
	styleUrls: ['./report-template-setting.component.scss'],
})
export class ReportTemplateSettingComponent implements OnInit {
	static count: number = 0;

	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;

	selectedTemplate: TemplateModelDTO;
	searchTemplateControl = new FormControl('');

	normalReports: any[];
	standardReports: any[];

	input = new FormControl('Input value.');
	selected: any;
	categories: string[] = [];
	formTemplates = [];
	logo: string;

	private _transformer = (node: TemplateNode, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			level: level,
		};
	};

	treeControl = new FlatTreeControl<FlatTemplateNode>(
		node => node.level,
		node => node.expandable
	);

	treeControl2 = new FlatTreeControl<FlatTemplateNode>(
		node => node.level,
		node => node.expandable
	);

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		node => node.level,
		node => node.expandable,
		node => node.children
	);

	dataSource = new MatTreeFlatDataSource(
		this.treeControl,
		this.treeFlattener
	);
	dataSource2 = new MatTreeFlatDataSource(
		this.treeControl2,
		this.treeFlattener
	);

	public patientVariables: any[];
	public examVariables: any[];
	public formVariables: any[];

	hasChild = (_: number, node: FlatTemplateNode) => node.expandable;

	group = 'template';
	private documentKey: string;
	private allTemplates: any;
	public cvis: boolean;

	private settingService: SettingService = inject(SettingService);
	private reportingService: ReportingService = inject(ReportingService);
	private _config: AppConfigService = inject(AppConfigService);
	private translate: TranslateService = inject(TranslateService);
	private dialog: MatDialog = inject(MatDialog);
	private _bs: MatBottomSheet = inject(MatBottomSheet);
	private snack: MatSnackBar = inject(MatSnackBar);

	selectedItem: TemplateModelDTO;

	showTemplateModel(node: TemplateNode, listType: string) {
		this.selectedTemplate = this.selectTemplate(node.name, listType);
		this.buildFormVariables(this.selectedTemplate.formTemplate);
	}

	addModel(name: string, listType: string) {
		const model = new TemplateModelDTO(
			null,
			name + '_' + moment().format('HHmmss')
		);
		model.category = name;
		model.listType = listType;

		this.settingService.saveTemplateModel(model).subscribe(template => {
			if (template.listType === 'STD') this.updateStandardData();
			else this.updateNormalData();

			this.selectedTemplate = template;
			this.saveTemplateModel();
		});
	}

	saveTemplateModel() {
		this.reportingService
			.sendCmd(new CommandService(this.documentKey))
			.subscribe(_ => {
				setTimeout(() => {
					this.settingService
						.saveTemplateModel(this.selectedTemplate)
						.subscribe(template => {
							if (template.listType === 'STD')
								this.updateStandardData();
							else this.updateNormalData();
							this.selectedTemplate = template;
							this.snack.open(
								'Modèle de rapport enregistré avec succès !',
								'',
								{ duration: 2000 }
							);
						});
				}, 500);
			});
	}

	deleteTemplateModel(tm: TemplateModelDTO) {
		this.settingService.deleteTemplateModel(tm).subscribe(res => {
			if (tm.listType === 'STD') this.updateStandardData();
			else this.updateNormalData();
			this.snack.open(tm.name + ' a été supprimé avec succès!', 'ok', {
				duration: 3000,
			});
		});
	}

	ngOnInit() {
		this.logo = this._config.logo;
		this.cvis = getAppType(this.logo) === 'cvis';

		this.updateNormalData();
		this.updateStandardData();

		if (this.cvis)
			this.reportingService
				.getAllFormTemplates()
				.subscribe(data => (this.formTemplates = data));

		this.patientVariables = variables('PATIENT_VARIABLES', this);
		this.examVariables = variables('EXAM_VARIABLES', this);

		this.searchTemplateControl.valueChanges.subscribe(key => {
			if (key) {
				const templates = this.allTemplates.filter(it =>
					it.name.toLowerCase().includes(key.toLowerCase())
				);

				const builtTemplates = this.buildTemplateStructure(templates);

				this.dataSource.data = this.adjustTemplates(builtTemplates);

				const reports = groupBy(templates, 'category');
				const categories = Object.keys(reports);
				this.treeControl.dataNodes
					.filter(it => it.expandable)
					.forEach(node => {
						if (categories.includes(node.name)) {
							this.treeControl.expand(node);
						} else this.treeControl.collapse(node);
					});
			} else this.treeControl.collapseAll();
		});
	}

	moveHere(tm: TemplateModelDTO, cat: any) {
		tm.category = cat;
		this.settingService.saveTemplateModel(tm).subscribe(_ => {
			if (tm.listType === 'STD') this.updateStandardData();
			else this.updateNormalData();
		});
	}

	deleteCategory(category: string) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this.settingService
						.deleteTemplateByCategory(category)
						.subscribe(res => {
							if (res) {
								this.snack.open(
									this.translate.instant('DELETE_DONE'),
									'OK',
									{ duration: 2000 }
								);
								this.updateStandardData();
							}
						});
				}
			});
	}

	private updateStandardData() {
		this.settingService.getStandardTemplateModels().subscribe(data => {
			this.allTemplates = data;

			this.selected = this.selectedTemplate || data[0];

			this.standardReports = this.buildTemplateStructure(data);

			this.dataSource.data = this.adjustTemplates(this.standardReports);
		});
	}

	private adjustTemplates(
		templates: { key: string; data: TemplateModelDTO[] }[]
	): TemplateNode[] {
		return sortBy(
			templates.map(value => {
				return {
					name: value.key,
					children: sortBy(
						value.data.map(report => {
							return {
								name: report.name,
								children: [],
							};
						}),
						'name'
					),
				};
			}),
			'name'
		);
	}

	private updateNormalData() {
		this.settingService.getNormalTemplateModels().subscribe(data => {
			const reports = groupBy(data, 'category');
			this.categories = Object.keys(reports);
			this.normalReports = this.categories.map(key => {
				return { key: key, data: reports[key] };
			});

			this.dataSource2.data = this.adjustTemplates(this.normalReports);
		});
	}

	filterCategories(category: any): string[] {
		return this.categories.filter(value => value !== category);
	}

	newCategory(e) {
		const top = e.clientY + 10;
		e.stopImmediatePropagation();
		this.dialog
			.open(NewCategoryComponent, {
				position: { top: top + 'px', left: e.clientX - 180 + 'px' },
			})
			.afterClosed()
			.subscribe(value => {
				if (value) {
					this.categories.push(value);
					this.selectedTemplate.category = value;
				}
			});
	}

	private _templates(listType: string): any[] {
		if (listType === 'STD') return this.standardReports;
		else return this.normalReports;
	}

	selectTemplate(name: string, listType: string): TemplateModelDTO {
		const templates = this._templates(listType);
		return flatMap(templates, 'data').find(value => value.name === name);
	}

	uploadFiles() {
		this._bs
			.open(ExcelExamComponent, {
				hasBackdrop: false,
				closeOnNavigation: false,
				disableClose: true,
			})
			.afterDismissed()
			.subscribe(res => {
				if (res) this.updateStandardData();
			});
	}

	duplicateTemplateModel(node: TemplateNode | any, listType: string) {
		ReportTemplateSettingComponent.count++;

		const tm = this.selectTemplate(node.name, listType);

		const newTemplate = new TemplateModelDTO(
			null,
			tm.name + '_' + moment().format('HHmmss')
		);

		if (tm.listType === 'STD') this.standardReports.push(newTemplate);
		else this.normalReports.push(newTemplate);

		this.showTemplateModel(node, tm.listType);
	}

	selectTemplateItem(node: any) {
		this.selectedItem = this.selectTemplate(node.name, 'STD');
	}

	editCategory(node: any) {
		const category = node.name;
		const tm = this.allTemplates.find(it => it.category === category);

		const data = { category, examCategory: tm.examCategory };

		this.dialog
			.open(NewCategoryComponent, { data })
			.afterClosed()
			.subscribe(updatedCategory => {
				if (updatedCategory) {
					const { cat, eType } = updatedCategory;
					if (cat === tm.category && eType === tm.examCategory)
						return;

					this.settingService
						.updateTemplateCategory(category, updatedCategory)
						.subscribe(res => {
							this.updateStandardData();
						});
				}
			});
	}

	editorReady(docKey: string) {
		this.documentKey = docKey;
	}

	insertVariable(key) {
		this.reportingService.variableInsert.next(key);
	}

	private buildTemplateStructure(data: any): any {
		const reports = groupBy(data, 'category');
		this.categories = Object.keys(reports);
		return this.categories.map(key => {
			return { key: key, data: reports[key] };
		});
	}

	private buildFormVariables(formTemplateName: any) {
		if (formTemplateName) {
			const formTemplate = this.formTemplates.find(
				it => it.name === formTemplateName
			);
			this.formVariables = formTemplate?.blocks?.map(it => {
				return { key: it.name, label: it.label, fields: it.fields };
			});
		}
	}

	// Content control methods
	public postMessage(ccType: any, data: any = null) {
		this.sendCommand(ContentControl(ccType, data), '*');
	}

	public sendCommand(message: any, targetOrigin: string = '*') {
		const iframe = document.getElementsByName(
			'frameEditor'
		)[0] as HTMLIFrameElement;
		if (iframe && iframe.contentWindow)
			iframe.contentWindow.postMessage(message, targetOrigin);
	}

	public handleValue(blockName: string, fieldName: string) {
		return `${blockName}.${fieldName}`;
	}

	public handleData(blockName: string, field: any) {
		return {
			tag: this.handleValue(blockName, field.name),
			value: field.value,
		};
	}

	public addContentControlWithTag(ccTag: string) {
		this.postMessage('addContentControlWithTag', { tag: ccTag });
	}

	downloadAllTemplates() {
		this.settingService.downloadAllTemplates().subscribe();
	}
}

@Component({
	selector: 'ft-new-category',
	template: `
		<mat-toolbar class="fx-height-42 fz-16" color="primary">
			<mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
			<h3 class="fz-16" style="padding-left: 4px;">
				{{ 'FAMILY' | translate }}
			</h3>
			<span class="fx-grow-1"></span>
			<button mat-icon-button [matDialogClose]="null" tabindex="-1">
				<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
			</button>
		</mat-toolbar>
		<div class="fx-layout-column-nowrap fx-padding-24" [formGroup]="form">
			<mat-form-field>
				<mat-label>{{ 'FAMILY' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'FAMILY' | translate"
					formControlName="category" />
			</mat-form-field>
			<mat-form-field>
				<mat-label>{{ 'EXAM_CATEGORY' | translate }}</mat-label>
				<mat-select
					[placeholder]="'EXAM_CATEGORY' | translate"
					formControlName="examCategory">
					@for (ex of examTypes; track ex) {
						<mat-option [value]="ex">{{ ex }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
		</div>
		<div matDialogActions align="end">
			<button color="warn" mat-button [matDialogClose]="null">
				{{ 'CANCEL' | translate }}
			</button>
			<button mat-raised-button color="primary" (click)="save()">
				{{ 'SAVE' | translate }}
			</button>
		</div>
	`,
	styleUrls: ['./template.category.scss'],
})
export class NewCategoryComponent implements OnInit, OnDestroy {
	form: FormGroup;
	examTypes: any;
	private readonly sub$: Subscription;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fb: FormBuilder,
		private _shared: SharedService,
		private dialogRef: MatDialogRef<NewCategoryComponent>
	) {
		this.sub$ = this._shared
			.getExamTypes()
			.subscribe(res => (this.examTypes = res));
		this.createForm();
	}

	ngOnInit() {
		this.form.patchValue(this.data);
	}

	save() {
		this.dialogRef.close(this.form.value);
	}

	ngOnDestroy() {
		if (this.sub$) this.sub$.unsubscribe();
	}

	private createForm() {
		this.form = this._fb.group({
			category: '',
			examCategory: '',
		});
	}
}
