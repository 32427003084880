import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import { AddressDTO, InsuranceDTO, PatientFullDTO } from '../model';
import { DOCUMENT_URL, PATIENT_URL } from '../urls';
import { FileElement } from '../shared';

@Injectable()
export class PatientService {
	private patientCreatedSource = new Subject<any>();
	patientSelected = new BehaviorSubject(null);

	patientCreated = this.patientCreatedSource.asObservable();

	private _http = inject(HttpClient);

	createPatient = (patient: any) => this.patientCreatedSource.next(patient);

	getPatientFull = (id: number): Observable<PatientFullDTO> =>
		this._http.get<PatientFullDTO>(`${PATIENT_URL}/${id}/full`);

	savePatientFull = (patient: PatientFullDTO): Observable<PatientFullDTO> =>
		this._http.post<PatientFullDTO>(`${PATIENT_URL}/create`, patient);

	updatePatientFull = (patient: PatientFullDTO): Observable<PatientFullDTO> =>
		this._http.put<PatientFullDTO>(
			`${PATIENT_URL}/${patient.patient.id}`,
			patient
		);

	deletePatient = (patientId: number): Observable<any> =>
		this._http.delete(`${PATIENT_URL}/${patientId}`);

	countExistingPatients(
		firstName: string,
		lastName: string
	): Observable<number> {
		const params = { firstName, lastName };
		return this._http.get<number>(
			`${PATIENT_URL}/count-duplicate-patients`,
			{ params }
		);
	}

	getPatientByID(patientID: string): Observable<any> {
		return this._http.get(`${PATIENT_URL}/patient-by-ext_id`, {
			params: { patientID },
		});
	}

	getPatientDTOByID(patientID: string): Observable<any> {
		return this._http.get(`${PATIENT_URL}/find`, { params: { patientID } });
	}

	getPatientFiles(patientID: string): Observable<FileElement[]> {
		return this._http.get<FileElement[]>(`${DOCUMENT_URL}/patient-files`, {
			params: { patientID },
		});
	}

	exportPatients(ids: string = 'all'): Observable<any> {
		return this._http.get(`${PATIENT_URL}/exportPatients`, {
			params: { ids },
			responseType: 'blob',
		});
	}

	mergePatients(patientID: string, patientIDList: string): Observable<any> {
		return this._http.get(`${PATIENT_URL}/mergePatients`, {
			params: { patientID, patientIDList },
		});
	}

	getPatients(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		value: any
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${active},${direction}`,
			query: value,
		};
		return this._http.get(`${PATIENT_URL}`, { params });
	}

	getAddressById(addressId: number): Observable<AddressDTO> {
		return this._http.get<AddressDTO>(
			`${PATIENT_URL}/address/${addressId}`
		);
	}

	getInsuranceById(insuranceId: number): Observable<InsuranceDTO> {
		return this._http.get<InsuranceDTO>(
			`${PATIENT_URL}/insurance/${insuranceId}`
		);
	}
}
