import {
	AfterViewInit,
	Component,
	Inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { QueryParam, ViewerDTO } from '../../model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { SharedService } from '../shared.service';
import { SettingService } from '../../setting/setting.service';
import { AppConfigService } from '../../app-config.service';
import { ReportingService } from '../../reporting/reporting.service';
import { AuthService } from '../../auth';
import { SchedulerService } from '../../scheduler/scheduler.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ft-pacs-search',
	templateUrl: './pacs-search.component.html',
	styleUrls: ['./pacs-search.component.scss'],
	animations: [rowsAnimation],
})
export class PacsSearchComponent implements OnInit, AfterViewInit {
	displayedColumns: string[] = [
		'patientName',
		'patientID',
		'patientSex',
		'modalitiesInStudy',
		'studyDescription',
		'accessionNumber',
		'referringPhysicianName',
		'remoteAETitle',
		'studyDate',
		'studyTime',
		'action',
	];

	dataSource = new MatTableDataSource<QueryParam>();
	isLoadingResults = true;
	isDataLoaded = false;

	public queryForm: FormGroup;
	defaultViewer: ViewerDTO;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	private patientPrefix: string;
	private anPrefix: string;

	mergeEnabled = false;
	private currentUser: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: string,
		private fb: FormBuilder,
		private shared: SharedService,
		private scheduler: SchedulerService,
		private _config: AppConfigService,
		private _authService: AuthService,
		private _translate: TranslateService,
		private reportingService: ReportingService,
		private setting: SettingService,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialogRef<PacsSearchComponent>
	) {
		this.mergeEnabled = this.data !== '';

		const generalSetting = this._config.generalSetting;
		this.patientPrefix = generalSetting.patientIdPrefix;
		this.anPrefix = generalSetting.accessionNumberPrefix;

		this.queryForm = this.fb.group({
			studyDescription: '',
			patientName: this.data,
			studyDate: '',
		});
	}

	ngOnInit() {
		this.dataSource.paginator = this.paginator;

		this.setting.getViewers().subscribe(res => {
			this.defaultViewer = res.filter(
				v => v.defaultViewer || v.name.toLowerCase().includes('pro')
			)[0];
		});

		this.currentUser = JSON.parse(localStorage.getItem('user'));
	}

	ngAfterViewInit() {
		this.changeRange({ value: 'TODAY' });
		const filterObservables = [this.queryForm.valueChanges];

		merge(...filterObservables)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					const q = this.queryForm.getRawValue();
					return this.shared.queryPacs(
						new QueryParam(
							q.studyDate,
							q.studyDescription,
							'',
							'',
							q.patientName
						)
					);
				}),
				tap(_ => {
					this.isLoadingResults = false;
					this.isDataLoaded = false;
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isDataLoaded = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	trackById(index: number, item: any): string {
		return item.studyInstanceUID;
	}

	changeRange(event: any) {
		let start, end;
		switch (event.value) {
			case 'TODAY':
				this.queryForm
					.get('studyDate')
					.patchValue(moment().format('YYYYMMDD'));
				break;
			case '3D':
				start = moment().subtract(3, 'd').format('YYYYMMDD');
				end = moment().format('YYYYMMDD');
				this.queryForm.get('studyDate').patchValue(`${start}-${end}`);
				break;
			case '1W':
				start = moment().subtract(1, 'w').format('YYYYMMDD');
				end = moment().format('YYYYMMDD');
				this.queryForm.get('studyDate').patchValue(`${start}-${end}`);
				break;
			case '2W':
				start = moment().subtract(2, 'w').format('YYYYMMDD');
				end = moment().format('YYYYMMDD');
				this.queryForm.get('studyDate').patchValue(`${start}-${end}`);
				break;
			case '1M':
				start = moment().subtract(1, 'month').format('YYYYMMDD');
				end = moment().format('YYYYMMDD');
				this.queryForm.get('studyDate').patchValue(`${start}-${end}`);
				break;
			case '3M':
				start = moment().subtract(3, 'month').format('YYYYMMDD');
				end = moment().format('YYYYMMDD');
				this.queryForm.get('studyDate').patchValue(`${start}-${end}`);
				break;
			case 'OT':
				start = moment().subtract(10, 'year').format('YYYYMMDD');
				end = moment().format('YYYYMMDD');
				this.queryForm.get('studyDate').patchValue(`${start}-${end}`);
				break;
			default:
				this.queryForm
					.get('studyDate')
					.patchValue(moment().format('YYYYMMDD'));
				break;
		}
	}

	createPatientExam(row: any) {
		this.shared.checkIfExamExists(row.studyInstanceUID).subscribe(res => {
			if (res)
				this.snackBar
					.open(this._translate.instant('ALREADY_MERGED'), '', {
						duration: 3000,
					})
					.onAction()
					.subscribe(_ =>
						console.log('opening report: ', row.studyInstanceUID)
					);
			else this.dialogRef.close({ key: 'new', data: row });
		});
	}

	openInViewer(row: any) {
		if (this.defaultViewer.name.toLowerCase().includes('paxera')) {
			window.open(
				this.defaultViewer.host +
					this.defaultViewer.remotePath +
					row.studyInstanceUID,
				'_blank'
			);
		} else {
			const params = `${this.defaultViewer.name}_${row.studyInstanceUID}`;

			if (window['viewerWindow'] && !window['viewerWindow'].closed) {
				window['viewerWindow'].focus();
				this.reportingService
					.openStudy(
						row.studyInstanceUID,
						this.currentUser.username,
						true
					)
					.subscribe();
			} else {
				window['viewerWindow'] = window.open(
					`/external-viewer/study?param=${params}`,
					'viewerWindow',
					'toolbar=0,location=0,menubar=0,left'
				);
				window['viewerWindow'].addEventListener(
					'beforeunload',
					() => (window['viewerWindow'] = null)
				);
			}
		}
	}

	formatDate(dcmDate: string): string {
		if (dcmDate)
			return moment(dcmDate, 'YYYYMMDD').format(
				this._config.momentDateFormat
			);
		return '';
	}

	formatTime(dcmTime: string): string {
		if (dcmTime)
			return moment(dcmTime.substr(0, 6), 'HHmmss').format('HH:mm');
		return '';
	}

	formatString(str: string): string {
		if (str) return str.replace('^', ' ');
		return '';
	}

	isExternalExam(row: QueryParam): boolean {
		return (
			this.patientPrefix &&
			this.anPrefix &&
			!(
				row.patientID.startsWith(this.patientPrefix) &&
				row.accessionNumber.startsWith(this.anPrefix)
			)
		);
	}

	mergeExam(row: any) {
		this.dialogRef.close({ key: 'merge', data: row });
	}
}
