<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">{{ 'SEARCH' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-overflow-hidden fx-padding-0">
	<div
		class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8">
		<mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
			<mat-icon
				matIconPrefix
				fontIcon="mdi-magnify"
				fontSet="mdi"></mat-icon>
			<input matInput #filter [placeholder]="'SEARCHING' | translate" />
		</mat-form-field>
	</div>

	<div style="max-height: 400px" class="fx-layout-column-nowrap">
		<mat-table
			[dataSource]="dataSource"
			class="fx-overflow-auto"
			matSort
			matSortActive="last_name"
			matSortDirection="asc">
			<!--Table-->
			@for (col of displayedColumns; track col) {
				<ng-container
					[class.hidden]="col.hidden"
					matColumnDef="{{ col.label }}">
					<mat-header-cell
						*matHeaderCellDef
						mat-sort-header="{{ col.sortField }}"
						[disabled]="!col.sortable">
						{{ col.header | translate }}</mat-header-cell
					>

					@if (col.type === 'date') {
						<mat-cell *matCellDef="let row">
							{{ row[col.label] | date: 'dd/MM/yyyy' }}</mat-cell
						>
					}

					@if (col.type === 'ID') {
						@switch (col.label) {
							@case ('genderId') {
								<mat-cell *matCellDef="let row">
									{{ genders[row[col.value]] }}</mat-cell
								>
							}
							@case ('titleId') {
								<mat-cell *matCellDef="let row">
									{{ titles[row[col.value]] }}</mat-cell
								>
							}
						}
					}

					@if (col.type !== 'date' && col.type !== 'ID') {
						<mat-cell *matCellDef="let row">
							{{ row[col.label] }}</mat-cell
						>
					}
				</ng-container>
			}

			<!--header-->
			<mat-header-row
				*matHeaderRowDef="cols; sticky: true"></mat-header-row>
			<mat-row
				[@rowsAnimation]=""
				(click)="selectPatient(row)"
				*matRowDef="let row; columns: cols">
			</mat-row>
		</mat-table>

		<div
			[style.display]="dataSource.data.length === 0 ? '' : 'none'"
			class="fx-table-empty"
			style="height: calc(60vh - 218px) !important; align-items: center">
			@if (isLoadingResults) {
				<div>
					<mat-spinner
						[diameter]="50"
						[strokeWidth]="3"
						color="warn"></mat-spinner>
				</div>
			} @else {
				{{ 'NOITEMSFOUND' | translate }}
			}
		</div>

		<mat-paginator
			[length]="resultsLength"
			[pageIndex]="0"
			[pageSizeOptions]="[5, 10, 25, 50, 100]"
			[pageSize]="25"
			[showFirstLastButtons]="true">
		</mat-paginator>
	</div>
</div>
