import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'ft-cancel-form',
	templateUrl: './cancel-form.component.html',
	styleUrls: ['./cancel-form.component.scss'],
})
export class CancelFormComponent {
	form = new FormControl<string>('', [Validators.required]);
	constructor() {}
}
