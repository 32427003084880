<mat-toolbar class="dialog-toolbar">
	<mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
	<h3 style="padding-left: 6px">{{ 'TITLE' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button mat-dialog-close tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<mat-dialog-content>
	<form [formGroup]="form">
		<mat-form-field class="full-width">
			<mat-label>{{ 'VALUE' | translate }}</mat-label>
			<input
				matInput
				[placeholder]="'VALUE' | translate"
				formControlName="value" />
		</mat-form-field>

		<mat-form-field class="full-width">
			<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
			<textarea
				matInput
				[placeholder]="'DESCRIPTION' | translate"
				formControlName="description"></textarea>
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button color="warn" mat-dialog-close mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="onSave(form.value)"
		[disabled]="!form.valid"
		color="primary"
		mat-raised-button>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
