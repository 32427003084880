import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LABEL_MODULES } from '../../setting/label-setting/table-conf';
import { SharedService } from '../shared.service';
import { LabelDTO } from '../../model';

@Component({
	selector: 'ft-label-edit',
	templateUrl: './label-edit.component.html',
	styleUrls: ['./label-edit.component.scss'],
})
export class LabelEditComponent implements OnInit {
	labelForm: FormGroup;
	labelModules = LABEL_MODULES;
	color: string = '#987987';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fb: FormBuilder,
		private _service: SharedService,
		private _dref: MatDialogRef<any>
	) {
		this.labelForm = this._fb.group({
			id: '',
			value: [
				'',
				Validators.compose([
					Validators.required,
					Validators.maxLength(16),
				]),
			],
			description: '',
			color: '#987897',
			module: 'ALL',
		});
	}

	ngOnInit(): void {
		const data = this.data || new LabelDTO();
		this.color = data.color;
		this.labelForm.patchValue(this.data);
	}

	save(label) {
		label.color = this.color;
		this._service.saveLabel(label).subscribe(value => {
			if (value) this._dref.close(value);
		});
	}
}
