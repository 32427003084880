<mat-toolbar class="dialog-toolbar">
	<mat-icon fontIcon="mdi-playlist-plus" fontSet="mdi"></mat-icon>
	<h3 style="padding-left: 6px">{{ 'TECHNIQUE' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button matDialogClose="null" mat-icon-button tabindex="-1">
		<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
	</button>
</mat-toolbar>

<mat-dialog-content>
	<form [formGroup]="form" class="fx-layout-column">
		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'VALUE' | translate }}</mat-label>
			<input
				[placeholder]="'VALUE' | translate"
				formControlName="value"
				matInput />
		</mat-form-field>

		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
			<textarea
				[placeholder]="'DESCRIPTION' | translate"
				formControlName="description"
				matInput></textarea>
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button color="warn" matDialogClose="null" mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button (click)="onSave(form.value)" color="primary" mat-raised-button>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
