export const LABELS_TABLE_COLS = [
	{
		label: 'value',
		header: 'VALUE',
		value: 'value',
	},
	{
		label: 'description',
		header: 'DESCRIPTION',
		value: 'description',
	},
	{
		label: 'color',
		header: 'COLOR',
		value: 'color',
	},
	{
		label: 'module',
		header: 'LABEL_MODULE',
		value: 'module',
	},
];

export const LABEL_MODULES = ['ALL', 'PATIENT', 'APPOINTMENT', 'REPORT'];
