import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor() {}

	getItem(key: string): any {
		return JSON.parse(window.localStorage.getItem(key));
	}

	setItem(key: string, data: any) {
		window.localStorage.setItem(key, JSON.stringify(data));
	}
}
