<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'PRINT_RDV' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-12">
	<div [formGroup]="rdvForm" class="fx-layout-column-nowrap">
		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'PATIENT_ID' | translate }}</mat-label>
			<input
				matInput
				[placeholder]="'PATIENT_ID' | translate"
				formControlName="patientID" />
		</mat-form-field>

		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'PATIENT_NAME' | translate }}</mat-label>
			<input
				matInput
				[placeholder]="'PATIENT_NAME' | translate"
				formControlName="patientName" />
		</mat-form-field>

		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'REASON_FOR_EXAM' | translate }}</mat-label>
			<textarea
				matInput
				[placeholder]="'REASON_FOR_EXAM' | translate"
				formControlName="exam"></textarea>
		</mat-form-field>

		<div class="fx-layout-row-nowrap fx-gap-4">
			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'DATE' | translate }}</mat-label>
				<input
					matInput
					[matDatepicker]="picker"
					[min]="minDate"
					[placeholder]="'DATE' | translate"
					formControlName="date"
					required />
				<mat-datepicker-toggle
					matSuffix
					[for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field style="width: 100px; padding-right: 4px">
				<mat-label>{{ 'TIME' | translate }}</mat-label>
				<input
					[placeholder]="'TIME' | translate"
					matInput
					type="time"
					required
					[(ngModel)]="time"
					[ngModelOptions]="{ standalone: true }" />
			</mat-form-field>
		</div>

		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'OBSERVATION' | translate }}</mat-label>
			<textarea
				matInput
				[placeholder]="'OBSERVATION' | translate"
				formControlName="observation"></textarea>
		</mat-form-field>
	</div>
</div>

<div matDialogActions align="end">
	<button [mat-dialog-close]="false" color="warn" mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="printRdv(rdvForm.value)"
		[disabled]="rdvForm.invalid"
		color="primary"
		mat-raised-button>
		{{ 'PRINT_RDV' | translate }}
	</button>
</div>
