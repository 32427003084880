import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormattingRule } from '../../model';

@Component({
	selector: 'ft-formatting-style-line',
	templateUrl: './formatting-style-line.component.html',
	styleUrls: ['./formatting-style-line.component.scss'],
})
export class FormattingStyleLineComponent implements OnChanges {
	@Input() formattingRule: FormattingRule;
	previewStyle: any;
	@Output() deleteEvent = new EventEmitter<boolean>();

	ngOnChanges(changes: SimpleChanges): void {
		this.formattingRule = changes['formattingRule'].currentValue;
		const style = this.formattingRule.formattingStyle;
		this.previewStyle = {
			color: style.color,
			backgroundColor: style.backgroundColor,
			fontWeight: style.bold ? 'bold' : 400,
			textDecoration:
				(style.underlined ? 'underline ' : '') +
				(style.strikethrough ? 'line-through ' : ''),
			fontStyle: style.italic ? 'italic' : 'normal',
		};
	}
}
